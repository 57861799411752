import * as React from "react";
import { Dropdown, Modal, Button, Form } from "react-bootstrap";
import Joi from "joi-browser";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { toast, ToastContainer } from "react-toastify";
import { Utils } from "../../../Common/Utilis";
import _ from "lodash";
import { User } from "../../../Common/Services/User";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { BusinessAccountSelectorUserModal } from "../common-components/BusinessAccountSelectorUserModal";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";

export class AddUserModal extends React.Component<any, any> {
  private selectedEntites: any;
  userSchema = Joi.object({
    user_UniqueId: Joi.number(),
    tenantID: Joi.number(),
    createdBy: Joi.number(),
    userName: Joi.string(),
    firstName: Joi.string()
      .required()
      .regex(/^[A-Za-z\' ][A-Za-z \-\_\'']*$/,"firstName")
      .max(48)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error?.type) {
            case "any.empty":
              return { message: "First Name is required" };
            case "string.regex.name":
                return { message: "First Name is not valid" };
            case "string.max":
              return {
                message: "First Name should not be more than 48 characters",
              };
          }
        });
        return [_errors[0]];
      }),
    lastName: Joi.string()
      .required()
      .regex(/^[A-Za-z\' ][A-Za-z \-\_\'']*$/,"lastName")
      .max(48)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error?.type) {
            case "any.empty":
              return { message: "Last Name is required" };
            case "string.regex.name":
                return { message: "Last Name is not valid" };
            case "string.max":
              return {
                message: "Last Name should not be more than 48 characters",
              };
          }
        });
        return [_errors[0]];
      }),
    status: Joi.string(),
    sendWelcomeEmail: Joi.boolean().default(false),
    role: Joi.number()
      .required()
      .min(1)
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select Role" };
        });
      }),
    hids: Joi.array()
      .items(Joi.object().keys().min(1))
      .error((errors) => {
        return errors.map((error) => {
          return { message: "Please select atleast 1 Entity" };
        });
      }),
    email: Joi.string()
      .regex(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email"
      )
      .required()
      .max(200)
      .error((errors) => {
        const _errors = errors.map((error) => {
          if (error?.message) {
            return;
          }
          switch (error.type) {
            case "any.empty":
              return { message: "Email Address is required" };
            case "string.regex.name":
              return { message: "Email Address is not valid" };
            case "string.max":
              return { message: "Email Address is not valid" };
          }
        });
        return [_errors[0]];
      }),

    phone1: Joi.string()
      .optional()
      .allow("")
      .regex(/^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, "phone1")
      .error((errors) => {
        const _errors = errors.map((error) => {
          return { message: "Mobile Phone Number is not valid" };
        });
        return [_errors[0]];
      })
  });

  constructor(props: any) {
    super(props);
    this.selectedEntites = React.createRef();
    this.state = {
      isValidForm: false,
      error: {},
      timeOutFunc: null,
      inputPosition: 1,
      userName: "",
      user: {
        user_UniqueId: 0,
        tenantID: 0,
        createdBy: 0,
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        phone1: "",
        status: "Incomplete",
        sendWelcomeEmail: true,
        role: -1,
        hids: []
      },
      userRoleList: this.props.userRoles,
      hotelWideTableData: [],
      setAccessControlInfo: []
    };
  }

  validateForm = (isEmailChange = false, isPhoneChange = false) => {


    const valid = Joi.validate(this.state.user, this.userSchema, {
      abortEarly: false,
    });

    const newErrorObject: any = {};
    if (valid.error) {
      valid.error.details.forEach((err) => {
        newErrorObject[err.path.join(".")] = err.message;
      });
    }

    if(
      newErrorObject["phone1"] == "Mobile Phone Number is not valid"&&
      this.state.user?.phone1?.toString()?.length >= 9 &&
      (this.state.user?.phone1?.toString()?.startsWith("+1242") || 
      this.state.user?.phone1?.toString()?.startsWith("1242") || 
      this.state.user?.phone1?.toString()?.startsWith("242"))
    ){
       delete newErrorObject["phone1"];
    }

    if (this.state.error?.phone1 && this.state.user?.phone1) {
      newErrorObject["phone1"] = this.state.error?.phone1;
    }
    if (this.state.error?.email === "Email Address already exists for another user" && !isEmailChange) {
      newErrorObject["email"] = "Email Address already exists for another user";
    }

    if (this.state.error?.phone1 === "Mobile Phone already exists for another user" && !isPhoneChange) {
      newErrorObject["phone1"] = "Mobile Phone already exists for another user";
    }


    if (newErrorObject?.phone1 && this.state.user?.phone1?.toString()?.length > 8
      &&
      this.state.user?.phone1?.toString()?.includes("+") &&
      this.state.user?.phone1?.toString()?.substr(1, 1) !== "1"

      && (newErrorObject?.phone1 !== "Mobile Phone already exists for another user" || (newErrorObject?.phone1 === "Mobile Phone already exists for another user" && isPhoneChange))

    ) {

      delete newErrorObject["phone1"];
    }

    if (
      this.state.user?.hids?.length === 0
    ) {
      newErrorObject["hids"] = "Please select atleast 1 Entity";

    } else {
      if (newErrorObject?.hids) {
        delete newErrorObject.hids;
      }
    }

    if (Object.keys(newErrorObject).length === 0) {
      this.setState({ isValidForm: true });
    } else {
      if (Object.keys(newErrorObject).length === 2 &&
        this.state.error?.email === "Email Address already exists for another user"
        && this.state.error?.phone1 === "Mobile Phone already exists for another user") {
        this.setState({ isValidForm: true });
      }
      else if (Object.keys(newErrorObject).length === 1 &&
        (this.state.error?.email === "Email Address already exists for another user"
          || this.state.error?.phone1 === "Mobile Phone already exists for another user")) {
        this.setState({ isValidForm: true });
      }
      else {
        this.setState({ isValidForm: false });
      }

    }


    //Low Security User
    if (this?.state?.user?.role) {
      const userRole = this?.state?.userRoleList.find(item => item.id == this?.state?.user?.role)
      let newsendWelcomeEmail = true
      let newIsValidForm = false
      if (userRole && userRole?.isLowSecurity === 1) {


        if (newErrorObject.email == "Email Address is required") {
          delete newErrorObject.email
          //newsendWelcomeEmail = false
        }



      }
      if (Object.keys(newErrorObject).length === 0) {
        newIsValidForm = true
      }

      this.setState({ user: { ...this.state.user }, isValidForm: newIsValidForm })


    }


    this.setState({ error: newErrorObject });
    return newErrorObject;
  };

  componentDidMount() {


  }

  CapitalizeFirstLetter =(string) =>{
    if(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
    } else {
     return string; 
    }
  }

  validatePhoneNo = (phone, inputPosition, isPhoneChange = false): any => {
    const user: any = { ...this.state.user };
    user.phone = phone;
    if (!phone) {
      this.validateForm();
      return;
    }
    const error: any = { ...this.state?.error };
    if (
      phone?.toString()?.includes("+") &&
      phone?.toString()?.substr(1, 1) !== "1"


    ) {




      if (phone?.toString()?.length < 9) {
        error["phone1"] = "Mobile Phone Number is not valid";
        this.setState({ error }, () => this.validateForm());
      }
      else if (error.phone1 !== "Mobile Phone already exists for another user") {
        delete error.phone1;
        if (inputPosition > this.state.inputPosition) {
          this.setState({ inputPosition });
        }
        this.setState({ error }, () => this.validateForm(false, isPhoneChange));
      } else {
        this.validateForm(false, isPhoneChange);
      }

      return;
    }
    this.setState({ isValidForm: false });

    User.ValidatePhoneNumber(phone).then((response: any) => {
      if (!this.state.user.phone1) {
        this.validateForm(false, isPhoneChange);
        return;
      }

      if (
        response?.fakeNumber === "YES"
        //&&
        // response?.lineType === "CELL PHONE"
      ) {
        error.phone1 = "Mobile Phone Number is not valid";
        this.setState({ inputPosition, error, isValidForm: false });
      } else if (response?.lineType !== "CELL PHONE") {
        error.phone1 = response?.lineType && response?.lineType !== "FAKE" ? `${this.CapitalizeFirstLetter(response?.lineType)} Phone Numbers are not supported` : `Mobile Phone Number is not valid`;
        this.setState({ inputPosition, error, isValidForm: false });
      } else {

        if (error?.phone1 !== "Mobile Phone already exists for another user") {
          delete error.phone1;
          this.setState({ error, isValidForm: true }, () => this.validateForm(false, isPhoneChange));
        }
        this.validateForm(false, isPhoneChange);
      }
    });
  };


  validationOnContactClick = (inputPosition, fieldName = "") => {
    if (inputPosition > this.state?.inputPosition) {
      this.setState({ inputPosition });
    }
    if (
      (fieldName === "phone1" && !this.state?.contactInfo?.phone1) ||
      fieldName !== "phone1"
    ) {
      this.validateForm();
    } else {
      this.validatePhoneNo(this.state?.contactInfo?.phone1, inputPosition);
    }
  };

  getUsername = () => {
    const { user } = this.state;
    if (!user.firstName || !user.lastName) {
      this.setState({ userName: "" });
      return;
    }
    UserManagement.GetUsername(user.firstName, user.lastName)
      .then((userName) => {
        const { user } = _.cloneDeep(this.state);
        user["userName"] = userName;
        this.setState({ userName, user });
      })
      .catch(() => this.setState({ userName: "" }));
  };

  saveUser = (e) => {
    const error = this.validateForm();

    if (this.state.isValidForm) {
      const { user } = this.state;
      this.setState({ isValidForm: false });
      UserManagement.SaveUserDetails(user)
        .then((response) => {
          if (response?.result?.success) {
            toast.success(response?.result?.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "addNewUser",
            });
            this.setState({ error, inputPosition: 0 });
            this.hideAddUserModal(true);
            if (response?.result?.result?.length > 0) {
              this.props.showSlideoutOnAdd(response?.result?.result[0]);
            }
          } else {
            const error = _.cloneDeep(this.state.error);
            if (error.email) {
              delete error.email;
              this.setState({ error, inputPosition: 7 });
            }
            if (error.phone1) {
              delete error.phone1;
              this.setState({ error, inputPosition: 7 });
            }
            const messages = response?.result?.message?.toString()?.split(",");
            if (response?.result?.message?.toString()?.includes("Email") || response?.result?.message?.toString()?.includes("Mobile Phone")) {
              if (messages[0]?.toString()?.includes("Email")) {
                error.email = messages[0]?.toString();
                this.setState({ error, inputPosition: 7 });
              }
              if (messages[0]?.toString()?.includes("Mobile Phone")) {
                error.phone1 = messages[0]?.toString();
                this.setState({ error, inputPosition: 7 });
              }
              if (messages[1]?.toString()?.includes("Email")) {
                error.email = messages[1]?.toString();
                this.setState({ error, inputPosition: 7 });
              }

              if (messages[1]?.toString()?.includes("Mobile Phone")) {
                error.phone1 = messages[1]?.toString();
                this.setState({ error, inputPosition: 7 });
              }

            }
            else {
              Utils.toastError(response?.result?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "addNewUser",
              });
            }
          }
        })
        .catch((error) => {
          this.setState({ inputPosition: 0 });
          Utils.toastError(`Server Error, ${error}`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            containerId: "addNewUser",
          });
        })
        .finally(() => this.setState({ isValidForm: true }));
    }
  };



  onFieldChange = (fieldName, value, inputPosition, shouldUpdatePostion = true) => {
    const { user } = this.state;
    debugger
    let validValue = "";
    let isEmailChange = false;
    let isPhoneChange = false;
    if (fieldName === "firstName" || fieldName === "lastName") {
      // validValue = value?.toString()?.replace(/[^A-Z \-\_\']+/gi, "")?.trimLeft();
      validValue = value?.toString()?.trimLeft();
      if (validValue) {
        if (validValue.indexOf("_") === 0 || validValue.indexOf("-") === 0 || validValue.indexOf("'") === 0) {
          // validValue = validValue?.slice(0, 0);
          validValue = validValue?.substring(1);
        }
      }
      user[fieldName] = validValue;
      value = validValue;
    } else if (fieldName === "phone1") {
      let validValue = value
        ?.toString()
        ?.replace(/[^0-9\+]+/gi, "")
      if (validValue) {
        if (validValue.indexOf("-") >= 0) {
          validValue = validValue?.replaceAll("-", "");
        }

        if (validValue?.toString()?.length > 1) {
          validValue = validValue?.toString().substr(0, 1) + validValue?.toString()?.substr(1)?.replaceAll("+", "");
        }
      }


      if (user.phone1 !== validValue) {
        isPhoneChange = true;
      }
      user[fieldName] = validValue;


      if (value) {
        this.setState({ isValidForm: false });
        const timeOutFunc = this.state?.timeOutFunc;
        if (timeOutFunc) {
          clearTimeout(timeOutFunc);
        }
        this.setState({ isValidForm: false });
        const timeoutId = setTimeout(
          () => this.validatePhoneNo(validValue, inputPosition, isPhoneChange),
          500
        );
        this.setState({ timeOutFunc: timeoutId });
      }
    }
    else if (fieldName == "email") {
      validValue = value?.toString()?.trim();
      if (user.email !== validValue) {
        isEmailChange = true;
      }
      user[fieldName] = validValue;

    }
    else {

      user[fieldName] = value;
    }


    if (shouldUpdatePostion && inputPosition > this.state.inputPosition) {
      this.setState({ inputPosition });
    }

    if (fieldName === "role") {
      const userRole = this?.state?.userRoleList.find(item => item.id == value)
      if (userRole && userRole?.isLowSecurity === 1) {
        this.showLowSecurityConfirmation();

      }
    }

    this.setState({ user }, () => {
      if ((fieldName === "phone1" && !value) || fieldName !== "phone1") {
        this.validateForm(isEmailChange, isPhoneChange);
      }
      if (fieldName === "firstName" || fieldName === "lastName") {
        var debounce = _.debounce(this.getUsername, 1000);
        debounce();
      }
    });
  };

  hideAddUserModal = (isReloadRequired = false) => {
    this.props.hideAddUserModal(isReloadRequired);
    this.setState({
      isValidForm: false,
      error: {},
      userName: "",
      user: {
        user_UniqueId: 0,
        tenantID: 0,
        createdBy: 0,
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        phone1: "",
        status: "Incomplete",
        sendWelcomeEmail: true,
        role: -1,
        hids: []
      },
    });
  };
  componentWillUnmount = () => {
    // setTimeout(() => {
    //   toast.dismiss();
    // }, 1000);
  };
  getFieldError = (fieldName: string, fieldPosition: number) => {
    const { error, inputPosition } = this.state;
    return (
      <>
        {Object.keys(error).length > 0 && fieldPosition <= inputPosition && (
          <span className="validation-message">{error[fieldName]}</span>
        )}
      </>
    );
  };

  handlerPaste(fieldName, e, inputPosition) {
    e.preventDefault();
    let value=e.clipboardData.getData("text").trim()
    const { user } = this.state;
    value=Utils.CapitalizeFirstLetter(value)
    user[fieldName] = value;
    this.setState({ user },()=>{
      this.validateForm()
      var debounce = _.debounce(this.getUsername, 1000);
      debounce();
    })
  }


  showLowSecurityConfirmation = () => {
    confirmAlert({
      title: "Note",
      message:
        "This role is only intended for users who need password reset assistance and maintain low levels of access to the application.",
      buttons: [
        {
          label: "Confirm",
          onClick: () => { },
        },
        {
          label: "Cancel",
          onClick: () =>{
            const { user } = this.state;
            user["role"] = -1;
            this.setState({user});
            reject()
          },
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  render() {
    const { showAddUserModal } = this.props;
    const {
      user: { firstName, lastName, email, phone1, sendWelcomeEmail, role },
      error,
      isValidForm,
      userName,
      inputPosition,
      userRoleList,
      hotelWideTableData
    } = this.state;

    return (
      <form autoComplete="false">

        <Modal
          className="add-user-modal"
          show={showAddUserModal}
          onHide={this.hideAddUserModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add New User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-section d-flex flex-column">
              <div className="body-section d-flex flex-row justify-content-between">
                <Form.Group
                  onBlur={() => this.validationOnContactClick(1, "firstName")}
                  controlId="date-selection"
                  className={
                    error?.firstName && inputPosition >= 1
                      ? "validation-error"
                      : ""
                  }
                >
                  <Form.Label>First Name</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtFirstName"
                    name="txtFirstName"
                    autoComplete="off"
                    // onCopy={this.handlerCopy}
                    onKeyDown={Utils.allowOnlyAplhabetAndHypenUnderscoreApostrophe}
                    onPaste={(e)=>this.handlerPaste("firstName", e, 1)}
                    value={Utils.CapitalizeFirstLetter(firstName)}
                    onChange={(e: any) =>
                      this.onFieldChange("firstName", e.target.value, 1)
                    }
                  />

                  {this.getFieldError("firstName", 1)}
                </Form.Group>

                <Form.Group
                  controlId="date-selection"
                  onBlur={() => this.validationOnContactClick(2, "lastName")}
                  className={
                    error?.lastName && inputPosition >= 2
                      ? "validation-error"
                      : ""
                  }
                >
                  <Form.Label>Last Name</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtLastName"
                    name="txtLastName"
                    value={Utils.CapitalizeFirstLetter(lastName)}
                    autoComplete="off"
                    // onCopy={this.handlerCopy}
                    onKeyDown={Utils.allowOnlyAplhabetAndHypenUnderscoreApostrophe}
                    onPaste={(e)=>this.handlerPaste("lastName", e, 2)}
                    onChange={(e: any) =>
                      this.onFieldChange("lastName", e.target.value, 2)
                    }
                  />
                  {this.getFieldError("lastName", 2)}
                </Form.Group>
              </div>
              <div className="body-section read-only d-flex flex-row">
                <Form.Group controlId="date-selection" onBlur={() => this.validationOnContactClick(3, "email")}>
                  <Form.Label>Generated Username</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtUsername"
                    name="txtUsername"
                    disabled={true}
                    value={userName}
                    autoComplete="off"
                  />
                </Form.Group>
              </div>
              <div className="body-section d-flex flex-row justify-content-between">
                <Form.Group
                  onBlur={() => this.validationOnContactClick(3, "role")}
                  controlId="date-selection"
                  className={
                    error?.role && inputPosition >= 3
                      ? "validation-error"
                      : ""
                  }
                >
                  <Form.Label>
                    Role Permission
                  </Form.Label>
                  <div onClick={() => this.validationOnContactClick(3, "role")} className="validate-input d-flex flex-column">
                    {userRoleList.length > 0 && (
                      <DropDownList
                      placeHolder={"Search Role"}
                      data={userRoleList}
                      isSearchRequired={true}
                      label={"name"}
                      value={"id"}
                      defaultValue={role}
                      placeHolderItem={{ id: -1, name: "Select Role" }}
                      onDropDownChange={(item) => {
                        if (!item) {
                          return;
                        }
                        this.onFieldChange("role", item?.id, 3)
                      }}

                      selectedItem={[
                        ...[{ id: -1, name: "Select Role" }],
                        ...userRoleList,
                      ].find((r) => +r.id === role)}
                    />
                    )}
                    {this.getFieldError("role", 3)}
                  </div>
                </Form.Group>
                <Form.Group
                  onClick={() => role === 0 ? {} : this.validationOnContactClick(4, "hids")}
                  controlId="date-selection"
                  className={
                    error?.hids && inputPosition >= 4
                      ? "validation-error"
                      : ""
                  }
                >
                  <Form.Label>
                    Select Entities
                  </Form.Label>
                  <div className="validate-input-ehid-picker">
                    <div className="ehid-picker-wrapper">
                      <div>
                        <div
                          // className={`ehid-picker`}

                          onBlur={() => role === 0 ? {} : this.validationOnContactClick(4)}


                        >
                          <BusinessAccountSelectorUserModal
                            ref={this.selectedEntites}
                            role={role}
                            disabled={role === 0}
                            userGeneralInfoHids={""}
                            notIncludeEnterpriseAccounts={false}
                            shouldGetAllActiveHotelsOfTenant={false}
                            shouldGetAllActiveHotels={true}
                            payrollIntegrationHids={this.props.payrollIntegrationHids}
                            handleBusinessAccountChange={(item, t, shouldUpdatePostion) =>
                              this.onFieldChange("hids", item, 4, shouldUpdatePostion)
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {this.getFieldError("hids", 4)}
                  </div>
                </Form.Group>
              </div>
              <div className="body-section d-flex flex-row justify-content-between">
                <Form.Group
                  onBlur={() => this.validationOnContactClick(5, "email")}
                  controlId="date-selection"
                  className={
                    error?.email && inputPosition >= 5 ? "validation-error" : ""
                  }
                >
                  <Form.Label>Email Address</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtEmail"
                    name="txtEmail"
                    value={email}
                    autoComplete="off"
                    onChange={(e: any) =>
                      this.onFieldChange("email", e.target.value, 5)
                    }
                  />
                  {this.getFieldError("email", 5)}
                </Form.Group>

                <Form.Group
                  onBlur={() => this.validationOnContactClick(6, "phone1")}
                  controlId="date-selection"
                  className={
                    error?.phone1 && inputPosition >= 6
                      ? "validation-error"
                      : ""
                  }
                >
                  <Form.Label>Mobile Phone</Form.Label>
                  <input
                    type="text"
                    className="form-control"
                    id="txtPhoneNo"
                    name="txtPhoneNo"
                    maxLength={16}
                    value={phone1}
                    autoComplete="off"
                    onChange={(e: any) =>
                      this.onFieldChange("phone1", e.target.value, 6)
                    }
                  />
                  {this.getFieldError("phone1", 6)}
                </Form.Group>
              </div>
              

            </div>

          </Modal.Body>
          <Modal.Footer>
            <Form.Check
              type="checkbox"
              checked={sendWelcomeEmail}
              label="Send Welcome Email"
              className="custom"
              onChange={(e: any) => this.onFieldChange("sendWelcomeEmail", e.target.checked, (role === 0 ? 5 : 7))}
            />
            <Button
              disabled={!isValidForm}
              type="submit"
              className="import-forecast-button ml-auto"
              onClick={(e: any) => this.saveUser(e)}
            >
              Add User
            </Button>
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}
