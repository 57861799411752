import React, { Component } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import filterFactory from "react-bootstrap-table2-filter";
import TableSearch from "../../../Common/Components/TableSearch";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Button, Dropdown, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import chevronRight from "../../../Common/Assets/Images/icons/bx-chevron-right.svg";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";
import caught from "../../../Common/Assets/Images/icons/all-caught.png";
import invoice_inv from "../../../Common/Assets/Images/invoice_inv.png";
import { Utils } from "../../../Common/Utilis";
import { InvoiceEntrySlideout } from "../../Register/InvoiceEntrySlideout";
import { InvoiceApprovalSubmit } from './InvoiceApprovalSubmit';
import { CSVLink, CSVDownload } from "react-csv";
import { LaborPerformance as laborPerformance } from "../../../Common/Services/LaborPerfomance";
import _ from "lodash";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import OutsideClickHandler from 'react-outside-click-handler';
import { PartialPayment } from '../../../Common/Components/PartialPaymentModal';

let exportDataSet: any = [];
let openRow: any = {}
let isAlert: any;
export class WaitingonMe extends React.Component<any, any> {
    private child: any;
    private wom_vendorWidth: any;
    private scrollArea: any;

    iconFormatter = (column, colIndex) => {
        return (
            <div className="wom_hasGearIcon">
                <Dropdown className="more-action bg-blue rolePermissiondropdown dropdown wom_dropdown" onToggle={this.handleToggle}>
                    <Dropdown.Toggle id="dropdown-ptoType" className="pl-1 pr-1" disabled={this.state.tableData.length === 0}>
                        <div className="d-flex align-items-center wom_svg">
                            <div className="d-flex align-items-center wom_svg">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.3914 2H3.60861C2.72146 2 2 2.79733 2 3.77778V16.2222C2 17.2027 2.72146 18 3.60861 18H16.3914C17.2785 18 18 17.2027 18 16.2222V6.44356V5.55467V4.66578V3.77778V3.77689C17.9992 2.79644 17.2769 2 16.3914 2ZM6.82582 16.2222H3.60861V6.44356H6.82582V16.2222ZM11.6516 16.2222H8.43442V6.44356H11.6516V16.2222ZM13.2602 16.2222V6.44356H16.3914L16.3922 16.2222H13.2602Z" />
                                </svg>
                                <div className="drop-arrow d-flex">
                                    <FiChevronDown />
                                </div>
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="wom_tableColumnListSelector" id="colList3">
                        <div className="header">Columns</div>
                        <div className="dropdownItems">
                            {this.state.tableColumnList.map((items, index) => (
                                <>
                                    {!items.isHide && (
                                        <div className="dropdown-item" key={index}>
                                            <EllipsisWithTooltip placement="bottom">
                                                <div className="wom_formCheck wom_formCheckHasPadd">
                                                    <Form.Check
                                                        type="checkbox"
                                                        id={`list_${items.id}`}
                                                        name={items.name.toLowerCase()}
                                                        label={items.name === "AP" ? "Accounting Period" : items.name}
                                                        value={items.id}
                                                        onChange={(event) =>
                                                            this.onChangeStatus(event, items, index)
                                                        }
                                                        checked={items.isChecked}
                                                        disabled={items.disabled}
                                                    />
                                                </div>
                                            </EllipsisWithTooltip>
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                        <div className="m-0 dropdown-divider"></div>
                        <Dropdown.Item className="wom_saveDefaultBtn" onClick={this.saveDefaultColumn}>Save as Default Columns</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    handleToggle = (event) => {
        if (event === false) {
            let defaultWidth = this.scrollArea.current.getBoundingClientRect().width;
            let currentWidth = this.scrollArea.current.childNodes[0].childNodes[0].childNodes[0].getBoundingClientRect().width;
            if (defaultWidth < currentWidth) {
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='viom_CustomConfirmAlert'>
                                <h1 className="viom_alertTitle">Note:</h1>
                                <p className="viom_alertMessage">Your column selection may cause horizontal scrolling due to the number selected.  To eliminate scrolling, limit the number of columns you select.</p>
                                <div className="viom_alertButtonGroup">
                                    <button className="viom_alertButton viom_success" onClick={onClose}>Ok</button>
                                </div>
                            </div>
                        );
                    }
                })
            }
        }
    }

    renderTooltipNotes = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.internalNotes}
        </Tooltip>
    );

    notesIcon() {
        return (
            <svg stroke="currentColor" fill="#1d1e20" strokeWidth="0" viewBox="0 0 24 24" height="1.2em" width="1.2em" xmlns="http://www.w3.org/2000/svg">
                <path d="M19,4h-3V2h-2v2h-4V2H8v2H5C3.897,4,3,4.897,3,6v14c0,1.103,0.897,2,2,2h14c1.103,0,2-0.897,2-2V6C21,4.897,20.103,4,19,4z M5,20V7h3h2h4h2h3V6l0.002,14H5z"></path>
                <path d="M7 9H17V11H7zM7 13H12V15H7z"></path>
            </svg>
        )
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            <div className="rowAiApprovals">
                <div className="rowAiHeader">Approvals:</div>
                <ul>{props.isHoverd.map((items, idx) => (<li key={idx}>{items.role}</li>))}</ul>
            </div>
        </Tooltip>
    );

    hoverOutEllipsis = () => {
        $(".tooltip").removeClass("show");
    };

    columnsHeader = [{
        dataField: "company",
        text: "Vendor",
        headerFormatter: (column, colIndex) => {
            return (
                <div ref={this.wom_vendorWidth}>{column.text}</div>
            );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
            if (row.wom_CheckCountlen && row.company.length > 20) {
                return (<div onClick={() => { this.rowClickEvents(row) }} onWheel={() => this.hoverOutEllipsis()}>
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{row.company}</Tooltip>}>
                        <span>{row.company.toString().substring(0, 17)}...</span>
                    </OverlayTrigger>
                </div>)
            } else {
                return (<div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="bottom">{row.company}</EllipsisWithTooltip>
                </div>)
            }
        },
        headerClasses: "wom_colVendor",
        classes: "stickey-vendor",
    }, {
        dataField: "invoiceNo",
        text: "Number",
        headerStyle: (column, colIndex) => {
            return {
                left: this.state.wom_vendorWidthCol
            };
        },
        style: (cell, row, rowIndex, colIndex) => {
            return {
                left: row.wom_vendorWidthCol
            };
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
            if (row.wom_CheckCountlen && row.invoiceNo.length > 15) {
                return (<div onClick={() => { this.rowClickEvents(row) }} onWheel={() => this.hoverOutEllipsis()}>
                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">{row.invoiceNo}</Tooltip>}>
                        <span>{row.invoiceNo.toString().substring(0, 12)}...</span>
                    </OverlayTrigger>
                </div>)
            } else {
                return (<div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="bottom">{row.invoiceNo}</EllipsisWithTooltip>
                </div>)
            }
        },
        headerClasses: "wom_colNumber",
        classes: "stickey-number",
    }, {
        dataField: "letterCode",
        text: "EHID",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="bottom">{row.letterCode}</EllipsisWithTooltip>
                </div>)
        },
        headerClasses: "wom_colEhid hasSortCol",
    }, {
        dataField: "accper",
        text: "AP",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="bottom">{row.accper}</EllipsisWithTooltip>
                </div>
            )
        },
        headerClasses: "wom_colAccPeriod hasSortCol",
    }, {
        dataField: "billDate",
        text: "Invoice Date",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (<div onClick={() => { this.rowClickEvents(row) }}>
                <EllipsisWithTooltip placement="bottom">{row.billDate}</EllipsisWithTooltip>
            </div>)
        },
        headerClasses: "wom_colInvoiceDate hasSortCol",
    }, {
        dataField: "dueDate",
        text: "Due Date",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (<div onClick={() => { this.rowClickEvents(row) }}>
                <EllipsisWithTooltip placement="bottom">{row.dueDate}</EllipsisWithTooltip>
            </div>)
        },
        headerClasses: "wom_colDueDate hasSortCol",
        hidden: true,
    }, {
        dataField: "transTotal",
        text: "Total Amount",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.transTotal)}</EllipsisWithTooltip>
                </div>
            )
        },
        headerClasses: "wom_colAmount hasSortCol",
        classes: "text-right",
    }, {
        dataField: "etype",
        text: "Method",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }}>
                    <EllipsisWithTooltip placement="bottom">{row.etype}</EllipsisWithTooltip>
                </div>
            )
        },
        headerClasses: "wom_colMethod hasSortCol",
    }, {
        dataField: "internalNotes",
        text: "Notes",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (<div onClick={() => { this.rowClickEvents(row) }}>
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltipNotes(row)}>
                    <span>{row.internalNotes !== "" && this.notesIcon()}</span>
                </OverlayTrigger>
            </div>)
        },
        headerClasses: "wom_colDueDate hasSortCol",
        hidden: true,
    }, {
        dataField: "tabStatus",
        text: "Status",
        hidden: true,
        headerClasses: "wom_colStatus hasSortCol statusSticky",
        classes: "statusSticky",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <div onClick={() => { this.rowClickEvents(row) }} onWheel={() => this.hoverOutEllipsis()}>
                    <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={this.renderTooltip(row)}>
                        <span className={row.tabStatus.toLowerCase() === "Waiting on Others".toLowerCase() ? "ml-0 badge blue-bg" : "ml-0 badge badgeOrange"}>{row.tabStatus}</span>
                    </OverlayTrigger>
                </div>
            )
        },
    }, {
        dataField: "reviewstatus",
        text: "Flagged",
        hidden: true,
        headerClasses: "wom_colStatus hasSortCol statusSticky",
        classes: "statusSticky",
        formatter: (cell: any, row: any, rowIndex: any) => {
            return (
                <span className={row.reviewstatus.toLowerCase() === "Needs Review".toLowerCase() ? "ml-0 badge badgeOrange" : row.reviewstatus.toLowerCase() === "Review Complete".toLowerCase() ? "ml-0 badge green-bg" : "ml-0 badge blue-bg"}>{row.reviewstatus}</span>
            )
        },
    }, {
        dataField: "",
        text: "",
        classes: (cell: any, row: any, rowIndex: any) => `wom_colBtn text-right ${(row.isSpinnerLoader || row.isDropShow) ? "setZindex" : ""}`,
        headerClasses: "wom_colBtn text-right",
        headerFormatter: this.iconFormatter,
        formatter: (cell: any, row: any, rowIndex: any) => {
            let partialPermission = false;
            if (row.etype.toLowerCase() === "check" || row.etype.toLowerCase() === "m-ach" || row.etype.toLowerCase() === "credit card" || row.etype.toLowerCase() === "epay") {
                partialPermission = true;
            }
            return (
              <div className="d-flex">
                <Dropdown className="more-action wom_hasDropShadow" alignRight>
                  <Dropdown.Toggle
                    className="isNoBtn stickyBand"
                    variant="success"
                    id="dropdown-split-basic"
                  >
                    <Button
                      disabled={row.isSpinnerLoader}
                      onClick={(e: any) =>
                        this.showCheckApprovalModal(row, "row")
                      }
                    >
                      {" "}
                      Approve
                    </Button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className={
                      row.isSpinnerLoader
                        ? "wom_setToRight"
                        : "d-none wom_setToRight"
                    }
                  >
                    {row.isSpinnerLoader && (
                      <InvoiceApprovalSubmit
                        data={this.state.iasModalData}
                        reloadTableData={this.GetInvoiceWaitingOnMe}
                        hideCheckApprovalModal={this.hideCheckApprovalModal}
                        handleEmitMessage={this.handleEmitMessage}
                      />
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                {partialPermission && (
                  <Dropdown
                    id="more-action"
                    onToggle={(e) => {
                      this.setZindex(e, row);
                    }}
                    className="more-action ml-1"
                    alignRight
                  >
                    <Dropdown.Toggle
                      disabled={Number(row.transTotal) < 0 || row.isCreditCard?.toLowerCase() === "yes"}
                      className="btn-outline-primary btn btn-primary btn-ghost more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {!row.isSpinnerPP && (
                        <>
                          {!row.transTotal?.toString()?.includes("-") &&
                            row.transTotal !== 0 && (
                              <span
                                className="dropdown-item"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.handleBtnEvent(e, row, "single");
                                }}
                                key={rowIndex}
                              >
                                Partial Payment
                              </span>
                            )}
                        </>
                      )}

                      {row.isSpinnerPP && (
                        <OutsideClickHandler
                          onOutsideClick={() => this.hidePPModal(false)}
                        >
                          <div>
                            <PartialPayment
                              uniqueNo={row.uniqueno}
                              invoiceNo={row.invoiceNo}
                              amount={row.transTotal}
                              modalClose={this.hidePPModal}
                              HotelID={this.props.hidValue}
                              savePartialPayment={this.savePartialPayment}
                            />
                          </div>
                        </OutsideClickHandler>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            );
        },
    }, {
        dataField: "coa",
        text: "",
        classes: 'd-none',
        headerClasses: "d-none",
        hidden: true,
    }, {
        dataField: "coaName",
        text: "",
        classes: 'd-none',
        headerClasses: "d-none",
        hidden: true,
    }, {
        dataField: "description",
        text: "",
        classes: 'd-none',
        headerClasses: "d-none",
        hidden: true,
    }, {
        dataField: "amount",
        text: "",
        classes: 'd-none',
        headerClasses: "d-none",
        hidden: true,
    }];

    constructor(props: any) {
        super(props);
        this.child = React.createRef();
        this.wom_vendorWidth = React.createRef();
        this.scrollArea = React.createRef();
        this.state = {
            wom_vendorWidthCol: 0,
            hidValue: "",
            hotelName: "",
            bulkRowCount: [],
            bulkSelect: false,
            bulkSelectRow: false,
            expandedList: [],
            expList: [],
            tableData: [],
            searchResult: [],
            resetTableData: [],
            isSpinner: false,
            allCaughtUP: false,
            iasModalData: [],
            modalTypeBulk: false,
            isBulkSelected: false,
            isSlideOut: false,
            uniqueNo: 0,
            sildeoutCode: '',
            sildeoutValue: '',

            disableExport: false,
            selectedRows: [],
            densityView: [
                { id: 1, densityType: "densityView_Wide", name: "WIDE" },
                { id: 2, densityType: "densityView_Narrow", name: "NARROW" }
            ],
            defaultDensityView: "",
            tableColumnList: [
                { id: "company", name: "Vendor", isChecked: true, disabled: true, isHide: false },
                { id: "invoiceNo", name: "Number", isChecked: true, disabled: true, isHide: false },
                { id: "letterCode", name: "EHID", isChecked: true, disabled: false, isHide: false },
                { id: "accper", name: "AP", isChecked: true, disabled: false, isHide: false },
                { id: "billDate", name: "Invoice Date", isChecked: true, disabled: false, isHide: false },
                { id: "dueDate", name: "Due Date", isChecked: false, disabled: false, isHide: false },
                { id: "transTotal", name: "Total Amount", isChecked: true, disabled: false, isHide: false },
                { id: "etype", name: "Method", isChecked: true, disabled: false, isHide: false },
                { id: "internalNotes", name: "Notes", isChecked: false, disabled: false, isHide: false },
                { id: "tabStatus", name: "Status", isChecked: false, disabled: false, isHide: false },
                { id: "reviewstatus", name: "Flagged", isChecked: false, disabled: false, isHide: true }
            ],
            columnsHeader: this.columnsHeader,
            columnSortingList: [
                { id: "company", name: "Vendor", isActive: true, isHidden: false },
                { id: "invoiceNo", name: "Number", isActive: false, isHidden: false },
                { id: "letterCode", name: "EHID", isActive: false, isHidden: false },
                { id: "accper", name: "Accounting Period", isActive: false, isHidden: false },
                { id: "billDate", name: "Invoice Date", isActive: false, isHidden: false },
                { id: "dueDate", name: "Due Date", isActive: false, isHidden: true },
                { id: "transTotal", name: "Total Amount", isActive: false, isHidden: false },
                { id: "etype", name: "Method", isActive: false, isHidden: false },
                { id: "internalNotes", name: "Notes", isActive: false, isHidden: true },
                { id: "tabStatus", name: "Status", isActive: false, isHidden: true },
                { id: "reviewstatus", name: "Flagged", isActive: false, isHidden: true }
            ],
            SortBy: { id: "company", name: "Vendor" },
            defSortBy: { id: "company", name: "Vendor" },
            batchTotalArray: [],
            batchTotalCount: "0.00",
            isbookingkeeping: false
        };
    }
    componentDidMount() {
        this.GetInvoiceWaitingOnMe();
    }

    resetInvoiceWaitingOnMe = () => {
        let isAll = this.props.hidValue.toString().toLocaleLowerCase() === "select" || this.props.hidValue.toString().toLocaleLowerCase() === "all";
        let checked = { checked: isAll }
        let event = { target: checked }
        let items = {};
        let { SortBy } = this.state;
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let columnList = Object.assign([], JSON.parse(JSON.stringify(this.state.columnSortingList)));
        let colindex = columnList.findIndex(item => item.id === "letterCode");
        let index = data.findIndex(item => item.id === "letterCode");
        data[index].isChecked = isAll;
        data[index].disabled = isAll ? false : true;
        columnList[colindex].isHidden = isAll ? false : true;
        columnList.map(item => item.isActive === true && (item.isActive = false))
        columnList[0].isActive = true;
        SortBy.name = "Vendor";
        SortBy.id = "company";
        this.setState({ tableColumnList: data, columnSortingList: columnList, SortBy }, () => {
            this.onChangeStatus(event, items, index);
        })
    }

    GetInvoiceWaitingOnMe = () => {
        let { tableData, expList, expandedList, resetTableData } = this.state;
        tableData = []; expList = []; expandedList = []; resetTableData = [];
        let request: any = {};
        this.setState({ isSpinner: true, allCaughtUP: false, tableData, expandedList, resetTableData });
        request.EHID = this.props.hidValue === "Select" ? "All" : this.props.hidValue;
        InvoiceApprovalS.GetInvoiceWaitingOnMe(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    result.map((items) => (items.viewType === 1 && (
                        items["isSpinnerLoader"] = false,
                        items["isSpinnerLoader"] = false,
                        items["isHoverd"] = [],
                        items["wom_vendorWidthCol"] = "",
                        items["wom_CheckCountlen"] = "",
                        items["isDropShow"] = false,
                        items["isSpinnerPP"] = false
                    )));
                    result.map((items) => (
                        items["tabStatus"] = "Waiting on Me"
                    ))
                    let { tableColumnList, isbookingkeeping } = this.state;
                    isbookingkeeping = result[0]?.isbookingkeeping?.toString()?.toLowerCase() === "yes";
                    tableColumnList.forEach((o: any) => {
                        if (isbookingkeeping) {
                            if (o.id === 'reviewstatus') {
                                o.isHide = false;
                            }
                        } else {
                            if (o.id === 'reviewstatus') {
                                o.isHide = true;
                            }
                        }
                    })
                    result = _.sortBy(result, "company");
                    result.map((items) => {
                        if (items.viewType === 1) {
                            let pList1: any = [];
                            let pList2: any = [];
                            pList1.push(items.ruleName1);
                            pList2.push(items.ruleName2);
                            pList1.forEach(item => {
                                if (item !== "") {
                                    let splitComma = item.split("INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW");
                                    splitComma.forEach(item => {
                                        let row: any = {};
                                        row.key = "primary";
                                        row.id = item.split("^")[0];
                                        row.isCheck = true;
                                        row.role = item.split("^")[1] !== undefined ? item.split("^")[1] : "";
                                        items.isHoverd.push(row);
                                    })
                                }
                            });
                            pList2.forEach(item => {
                                if (item !== "") {
                                    let splitComma = item.split("INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW-INN-FLOW");
                                    splitComma.forEach(item => {
                                        let row: any = {};
                                        row.key = "secondary";
                                        row.id = item.split("^")[0];
                                        row.isCheck = false;
                                        row.role = item.split("^")[1] !== undefined ? item.split("^")[1] : "";
                                        items.isHoverd.push(row);
                                    })
                                }
                            });
                        }
                    })
                    result.forEach(item => {
                        if (item.viewType === 1) {
                            expList.push(item.rowNo)
                        }
                    })
                    this.setState({
                        tableData: result,
                        resetTableData: result,
                        expList,
                        disableExport: false,
                        batchTotalArray: [],
                        batchTotalCount: "0.00",
                        isbookingkeeping,
                    }, () => {
                        this.readyForExport(result);
                        this.getDefaultView();
                        this.handleInvoiceTotalCount();
                        this.resetInvoiceWaitingOnMe();
                    })
                } else {
                    this.setState({ isSpinner: false, allCaughtUP: true, disableExport: true })
                }
                resolve();
            })
            .catch((err: any) => {
                Utils.toastError(`Server Error ${err}`);
                reject();
            });
    }

    handleInvoiceTotalCount = () => {
        this.props?.handleInvoiceTotalCount(this.state.tableData.filter(x => x.viewType === 1).length);
    }

    getTotalUnApproveInvoice = () => {
        return this.state.tableData.length;
    }

    CleanSearch() {
        this.child.current.CleanSearch();
    };

    readyForExport = (resultAfterSearch: any) => {
        exportDataSet = [];
        let dateFormat = "MM/DD/YY";
        let { tableData, expandedList } = this.state;
        let hasViewType2 = resultAfterSearch.filter(item => item.viewType === 2).length > 0;
        let { tableColumnList } = this.state;
        let isLetterCode = tableColumnList.filter(item => item.id === "letterCode" && item.isChecked === true).length > 0;
        let isAccper = tableColumnList.filter(item => item.id === "accper" && item.isChecked === true).length > 0;
        let isBillDate = tableColumnList.filter(item => item.id === "billDate" && item.isChecked === true).length > 0;
        let isDueDate = tableColumnList.filter(item => item.id === "dueDate" && item.isChecked === true).length > 0;
        let isTransTotal = tableColumnList.filter(item => item.id === "transTotal" && item.isChecked === true).length > 0;
        let isEtype = tableColumnList.filter(item => item.id === "etype" && item.isChecked === true).length > 0;
        let isNotes = tableColumnList.filter(item => item.id === "internalNotes" && item.isChecked === true).length > 0;
        let isStatus = tableColumnList.filter(item => item.id === "tabStatus" && item.isChecked === true).length > 0;
        let isReviewStatus = tableColumnList.filter(item => item.id === "reviewstatus" && item.isChecked === true).length > 0;
        let isSplitView = tableData.filter(item => item.viewType === 1).length === expandedList.length;

        if (isSplitView) {
            if (hasViewType2) {
                resultAfterSearch.forEach(item => {
                    if (item.viewType === 2) {
                        let row: any = {};
                        let accpermonth = item.accper.split('-')[0];
                        let accperyear = item.accper.split('-')[1];
                        let fullaccper = accpermonth + '/01/' + accperyear;

                        row["Vendor"] = item.company;
                        row["Number"] = item.invoiceNo;
                        isLetterCode && (row["EHID"] = item.letterCode);
                        isAccper && (row["Accounting Period"] = moment(fullaccper).format(dateFormat));
                        isBillDate && (row["Invoice Date"] = moment(item.billDate).format(dateFormat));
                        isDueDate && (row["Due Date"] = moment(item.dueDate).format(dateFormat));
                        isTransTotal && (row["Total Amount"] = Utils.currencyFormatWithOutdoller(item.transTotal));
                        isEtype && (row["Method"] = item.etype);
                        isNotes && (row["Notes"] = item.internalNotes);
                        isStatus && (row["Status"] = item.tabStatus);
                        isReviewStatus && (row["Flagged"] = item.reviewstatus);
                        row["Split Ehid"] = item.splitEHIDLetter;
                        row["COA"] = item.coa;
                        row["COA Name"] = item.coaName;
                        row["Description"] = item.description;
                        row["Amount"] = Utils.currencyFormatWithOutdoller(item.amount);
                        exportDataSet.push(row)
                    }
                });
            } else {
                resultAfterSearch.length > 0 && tableData.filter(item => item.uniqueno === resultAfterSearch[0].uniqueno).forEach(item => {
                    if (item.viewType === 2) {
                        let row: any = {};
                        let accpermonth = item.accper.split('-')[0];
                        let accperyear = item.accper.split('-')[1];
                        let fullaccper = accpermonth + '/01/' + accperyear;

                        row["Vendor"] = item.company;
                        row["Number"] = item.invoiceNo;
                        isLetterCode && (row["EHID"] = item.letterCode);
                        isAccper && (row["Accounting Period"] = moment(fullaccper).format(dateFormat));
                        isBillDate && (row["Invoice Date"] = moment(item.billDate).format(dateFormat));
                        isDueDate && (row["Due Date"] = moment(item.dueDate).format(dateFormat));
                        isTransTotal && (row["Total Amount"] = Utils.currencyFormatWithOutdoller(item.transTotal));
                        isEtype && (row["Method"] = item.etype);
                        isNotes && (row["Notes"] = item.internalNotes);
                        isStatus && (row["Status"] = item.tabStatus);
                        isReviewStatus && (row["Flagged"] = item.reviewstatus);
                        row["Split Ehid"] = item.splitEHIDLetter;
                        row["COA"] = item.coa;
                        row["COA Name"] = item.coaName;
                        row["Description"] = item.description;
                        row["Amount"] = Utils.currencyFormatWithOutdoller(item.amount);
                        exportDataSet.push(row)
                    }
                })
            }
        } else {
            resultAfterSearch.forEach(item => {
                if (item.viewType === 1) {
                    let row: any = {};
                    let accpermonth = item.accper.split('-')[0];
                    let accperyear = item.accper.split('-')[1];
                    let fullaccper = accpermonth + '/01/' + accperyear;

                    row["Vendor"] = item.company;
                    row["Number"] = item.invoiceNo;
                    isLetterCode && (row["EHID"] = item.letterCode);
                    isAccper && (row["Accounting Period"] = moment(fullaccper).format(dateFormat));
                    isBillDate && (row["Invoice Date"] = moment(item.billDate).format(dateFormat));
                    isDueDate && (row["Due Date"] = moment(item.dueDate).format(dateFormat));
                    isTransTotal && (row["Total Amount"] = Utils.currencyFormatWithOutdoller(item.transTotal));
                    isEtype && (row["Method"] = item.etype);
                    isNotes && (row["Notes"] = item.internalNotes);
                    isStatus && (row["Status"] = item.tabStatus);
                    isReviewStatus && (row["Flagged"] = item.reviewstatus);
                    exportDataSet.push(row)
                }
            });
        }
    }

    exportWaitingonMe = () => {
        let { disableExport } = this.state;
        let fileName = "Export Waiting on Me.csv";
        if (disableExport) {
            return (
                <Dropdown.Item className="disabled-area">Export</Dropdown.Item>
            )
        } else {
            return (
                <div className="ExportCSVLink"><CSVLink data={exportDataSet} filename={fileName}>Export</CSVLink></div>
            )
        }
    }

    rowEvents = {
        onClick: (e: any, row: any, rowIndex: any) => {
            this.setState({ uniqueNo: row.uniqueno, sildeoutCode: row.letterCode, sildeoutValue: row.hid }, () => {
                this.setState({ isSlideOut: true })
            });
        },
    };

    rowClickEvents = (row: any) => {
        console.log(row);
        this.setState({ uniqueNo: row.uniqueno, sildeoutCode: row.letterCode, sildeoutValue: row.hid }, () => {
            this.setState({ isSlideOut: true })
        });
    }

    ////New Code---------------

    handleDensityView = (viewName) => {
        this.setState({ defaultDensityView: viewName })
    }

    updateColumns = () => {
        let { tableData, tableColumnList } = this.state;
        let womCClen = tableColumnList.filter(item => item.isChecked === true).length > 6;
        tableData = tableData.map((items) => {
            if (items.viewType === 1) {
                return {
                    ...items,
                    wom_vendorWidthCol: (Number(this.wom_vendorWidth?.current?.getBoundingClientRect().width + 80 + 14).toFixed(2)).toString() + "px",
                    wom_CheckCountlen: womCClen
                };
            } else {
                return items;
            }
        });
        this.setState((curr: any) => ({
            ...curr,
            tableData: tableData,
            wom_vendorWidthCol: (Number(this.wom_vendorWidth?.current?.getBoundingClientRect().width + 80 + 14).toFixed(2)).toString() + "px"
        }));
    }

    getDefaultView() {
        laborPerformance.getDefaultViewProperties("waitingonme")
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    this.setState({
                        defaultDensityView: response[0].fieldValue
                    }, () => {
                        this.getDefaultColumn();
                    });
                } else {
                    this.setState({
                        defaultDensityView: this.state.densityView[1].densityType,
                    }, () => {
                        this.getDefaultColumn();
                    })
                }
            })
            .catch((error) => {
                this.setState({ defaultDensityView: this.state.densityView[1].densityType }, () => {
                    this.getDefaultColumn();
                })
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    saveDefaultView = (): void => {
        const laborSaveAsDefaultdtos: any[] = [];
        laborSaveAsDefaultdtos.push({
            fieldName: "sort",
            fieldValue: this.state.defaultDensityView,
            pageName: "waitingonme",
        });
        laborPerformance.saveDefaultViewProperties(laborSaveAsDefaultdtos)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.message === "Success") {
                        toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ showLoader: false });
            });
    };


    getDefaultFilter = () => {
        let { SortBy, columnSortingList } = this.state;
        laborPerformance.getDefaultViewProperties("womFilter")
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    let currentRow = columnSortingList.filter(item => item.id === response[0].fieldValue && item.isHidden === false);
                    if (currentRow.length > 0) {
                        SortBy.name = currentRow[0].name;
                        SortBy.id = currentRow[0].id;
                    } else {
                        SortBy = this.state.defSortBy
                    }
                    this.setState({ SortBy, isSpinner: false }, () => {
                        this.updateColumns();
                        setTimeout(() => {
                            this.handleSortTable(this.state.SortBy.id);
                        }, 100)
                    });
                } else {
                    this.setState({ SortBy: this.state.defSortBy, isSpinner: false }, () => {
                        this.handleSortTable(this.state.SortBy.id);
                        this.updateColumns();
                    })
                }
            })
            .catch((error) => {
                this.setState({ SortBy: this.state.defSortBy, isSpinner: false }, () => {
                    this.handleSortTable(this.state.SortBy.id);
                    this.updateColumns();
                })
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    saveDefaultFilter = (): void => {
        const laborSaveAsDefaultdtos: any[] = [];
        laborSaveAsDefaultdtos.push({
            fieldName: "sort",
            fieldValue: this.state.SortBy.id,
            pageName: "womFilter",
        });
        laborPerformance
            .saveDefaultViewProperties(laborSaveAsDefaultdtos)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.message === "Success") {
                        toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ showLoader: false });
            });
    };

    getDefaultColumn = () => {
        let { tableColumnList, columnsHeader, columnSortingList } = this.state;
        let isAll = this.props.hidValue.toString().toLocaleLowerCase() === "select" || this.props.hidValue.toString().toLocaleLowerCase() === "all";
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        laborPerformance.getDefaultViewProperties("womColumn")
            .then(async (response: any | null) => {
                if (response?.length > 0) {
                    let resData = response[0].fieldValue.split(",");
                    tableColumnList.map(item => { item.isChecked = false })
                    columnSortingList.map(item => { item.isHidden = true })
                    columnsHeader.map(item => {
                        if (
                            item.dataField === 'company' ||
                            item.dataField === 'invoiceNo' ||
                            item.dataField === 'letterCode' ||
                            item.dataField === 'accper' ||
                            item.dataField === 'billDate' ||
                            item.dataField === 'dueDate' ||
                            item.dataField === 'transTotal' ||
                            item.dataField === 'etype' ||
                            item.dataField === 'tabStatus' ||
                            item.dataField === 'reviewstatus'
                        ) {
                            item.hidden = true
                        }
                    })

                    resData.forEach(ritm => {
                        columnsHeader.map(item => {
                            if (item.dataField === ritm) {
                                if (item.dataField === "letterCode") {
                                    item.hidden = isAll ? false : true;
                                } else if (item.dataField === "reviewstatus") {
                                    if (!this.state.isbookingkeeping) {
                                        item.hidden = true;
                                        item.searchable = false;
                                    } else {
                                        item.hidden = false;
                                        item.searchable = true;
                                    }
                                } else {
                                    item.hidden = false;
                                }
                            }
                        })
                        tableColumnList.map(item => {
                            if (item.id === ritm) {
                                if (item.id === "letterCode") {
                                    item.disabled = isAll ? false : true;
                                    item.isChecked = isAll ? true : false;
                                } else if (item.id === "reviewstatus") {
                                    if (!this.state.isbookingkeeping) {
                                        item.isHide = true;
                                        item.isChecked = false;
                                    } else {
                                        item.isHide = false;
                                        item.isChecked = true;
                                    }
                                } else {
                                    item.isChecked = true;
                                }
                            }
                        })
                        columnSortingList.map(item => {
                            if (item.id === ritm) {
                                if (item.id === "letterCode") {
                                    item.isHidden = isAll ? false : true;
                                    item.isActive = isAll ? false : true;
                                } else if (item.id === "reviewstatus") {
                                    if (!this.state.isbookingkeeping) {
                                        item.isHidden = true;
                                    } else {
                                        item.isHidden = false;
                                    }
                                } else {
                                    item.isHidden = false;
                                }
                            }
                        })
                    })
                    this.setState(() => ({ columnsHeader, tableColumnList, columnSortingList }), () => {
                        this.getDefaultFilter();
                    });
                } else {
                    this.setState({ tableColumnList: data }, () => {
                        this.getDefaultFilter();
                    })
                }
            })
            .catch((error) => {
                this.setState({ tableColumnList: data }, () => {
                    this.getDefaultFilter();
                })
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
            });
    }

    saveDefaultColumn = (): void => {
        let tableColumnList = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        let selectedColumns: any = [];
        tableColumnList.map(item => {
            if (item.isChecked) {
                selectedColumns.push(item.id)
            }
        })
        const laborSaveAsDefaultdtos: any[] = [];
        laborSaveAsDefaultdtos.push({
            fieldName: "column",
            fieldValue: (selectedColumns).toString(),
            pageName: "womColumn",
        });
        laborPerformance.saveDefaultViewProperties(laborSaveAsDefaultdtos)
            .then(async (result: any | null) => {
                if (result != null) {
                    if (result.message === "Success") {
                        toast.success(result?.result?.message, { position: toast.POSITION.BOTTOM_RIGHT });
                    }
                }
            })
            .catch((error) => {
                Utils.toastError(error.message, { position: toast.POSITION.BOTTOM_RIGHT });
                this.setState({ showLoader: false });
            });
    };

    onChangeStatus = (e: any, items: any, index: any) => {
        let data = Object.assign([], JSON.parse(JSON.stringify(this.state.tableColumnList)));
        data[index].isChecked = e.target.checked;
        let { columnsHeader, columnSortingList, SortBy } = this.state;
        columnSortingList[index].isHidden = data[index].isChecked ? false : true;
        if (columnSortingList[index].isActive === true && columnSortingList[index].isHidden === true) {
            columnSortingList[index].isActive = false;
            if (columnSortingList[index + 1] !== undefined && columnSortingList[index + 1].isHidden !== true) {
                columnSortingList[index + 1].isActive = true
                SortBy.name = columnSortingList[index + 1].name;
                SortBy.id = columnSortingList[index + 1].id;
            } else {
                columnSortingList[0].isActive = true;
                SortBy.name = "Vendor";
                SortBy.id = "company";
            }
        }
        columnsHeader[index].hidden = data[index].isChecked ? false : true;
        columnsHeader[index].searchable = data[index].isChecked;

        this.setState(() => ({ tableColumnList: data, columnsHeader, columnSortingList, SortBy }), () => {
            this.readyForExport(this.state.tableData);
            this.updateColumns();
        })
    }

    handleOnSelect = (row: any, isSelect: any) => {
        let { tableData, selectedRows, bulkSelectRow, bulkSelect, batchTotalArray, batchTotalCount } = this.state;
        let tableLen = tableData.filter(item => item.viewType === 1);
        if (isSelect) {
            selectedRows = [...this.state.selectedRows, row.rowNo];
            batchTotalArray = [...this.state.batchTotalArray, { rowNo: row.rowNo, amount: parseFloat(row.transTotal) }];
        } else {
            selectedRows = this.state.selectedRows.filter(x => x !== row.rowNo);
            batchTotalArray = this.state.batchTotalArray.filter(x => x.rowNo !== row.rowNo);
        }
        if (selectedRows.length === 0) {
            bulkSelectRow = false;
            bulkSelect = false;
            batchTotalArray = [];
            $("#footerBulkSelect").prop("checked", false).prop("indeterminate", false);
        } else if (selectedRows.length > 0 && selectedRows.length < tableLen.length) {
            bulkSelectRow = false;
            bulkSelect = true;
            $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
        } else if (selectedRows.length === tableLen.length) {
            bulkSelectRow = true;
            bulkSelect = true;
            $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
        }
        batchTotalCount = _.sumBy(batchTotalArray, 'amount');
        this.setState(() => ({ selectedRows, bulkSelectRow, bulkSelect, batchTotalArray, batchTotalCount }));
    }

    handleOnSelectAll = (isSelect: any, rows: any) => {
        let { selectedRows, batchTotalArray, batchTotalCount } = this.state;
        if (rows.length > 0) {
            if (isSelect) {
                rows.forEach(item => {
                    selectedRows.push(item.rowNo)
                    batchTotalArray.push({ rowNo: item.rowNo, amount: parseFloat(item.transTotal) });
                });
                $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
            } else {
                selectedRows = [];
                batchTotalArray = [];
                this.footerBulkClose();
            }
            batchTotalCount = _.sumBy(batchTotalArray, 'amount');
            this.setState(() => ({ selectedRows, bulkSelectRow: isSelect, bulkSelect: isSelect, batchTotalArray, batchTotalCount }));
        }
    }

    footerBulkSelectAll = (event) => {
        let { tableData, selectedRows, searchResult, batchTotalArray, batchTotalCount } = this.state;
        selectedRows = [];
        batchTotalArray = [];
        let tableLen = tableData.filter(item => item.viewType === 1);
        let checked = event.target.checked;
        if (checked) {
            searchResult.filter((item, i) => {
                if (item.viewType === 1) {
                    selectedRows.push(item.rowNo);
                    batchTotalArray.push({ rowNo: item.rowNo, amount: parseFloat(item.transTotal) });
                }
                return item;
            });
        } else {
            selectedRows = [];
            batchTotalArray = [];
            this.footerBulkClose();
        }
        if (selectedRows.length > 0 && selectedRows.length < tableLen.length) {
            $("#footerBulkSelect").prop("checked", false).prop("indeterminate", true);
        } else if (selectedRows.length === tableLen.length) {
            $("#footerBulkSelect").prop("checked", true).prop("indeterminate", false);
        }
        batchTotalCount = _.sumBy(batchTotalArray, 'amount');
        this.setState({ bulkSelectRow: checked, selectedRows, bulkSelect: checked, batchTotalArray, batchTotalCount });
    }

    footerBulkClose = () => {
        this.setState({ selectedRows: [], bulkSelect: false, bulkSelectRow: false }, () => {
            $("#footerBulkSelect").prop("checked", false).prop("indeterminate", false);
        })
    }

    handleToggleRows = (type) => {
        let { expList, expandedList, tableData, searchResult } = this.state;
        expandedList = (type === "Collapse_All" ? [] : expList)
        let currentExportData = searchResult.length > 0 ? searchResult : tableData;
        this.setState(() => ({ expandedList }), () => { this.readyForExport(currentExportData) });
    }

    searchResult = (resultAfterSearch: any) => {
        let { disableExport, searchResult, tableData } = this.state;
        searchResult = [];
        let afterSearchUniqueno: any = [];
        resultAfterSearch.forEach(item => afterSearchUniqueno.push(item.uniqueno));
        afterSearchUniqueno.forEach(uNo => {
            tableData.filter(item => {
                if (item.uniqueno === uNo) {
                    searchResult.push(item);
                }
            })
        });
        disableExport = resultAfterSearch.length > 0 ? false : true;
        this.setState({ bulkSelectRow: false, disableExport, selectedRows: [], searchResult }, () => {
            this.footerBulkClose();
            this.readyForExport(this.state.searchResult);
            this.updateColumns();
        });
    }

    hideCheckApprovalModal = (isTrue) => {
        let { resetTableData } = this.state;
        this.setState({ tableData: resetTableData, isBulkSelected: false, iasModalData: [], modalTypeBulk: false, bulkSelectRow: false }, () => {
            this.footerBulkClose();
            this.props.handleBothReload(isTrue)
            $("#footerBulkSelect").prop("checked", false).prop("indeterminate", false);
            $("body").trigger("click");
            this.handleSortTable(this.state.SortBy.id);
        })
    }

    showCheckApprovalModal = (row, type) => {
        let { iasModalData, tableData, modalTypeBulk, isBulkSelected, selectedRows } = this.state;
        iasModalData = [];
        this.setState({ iasModalData });
        if (type === "row") {
            tableData = tableData.map((items) => {
                if (items.uniqueno === row.uniqueno && items.viewType === 1) {
                    return { ...items, isSpinnerLoader: true };
                } else {
                    return items;
                }
            });

            iasModalData.push(row.uniqueno);
        } else {
            row.forEach(item => {
                let isId = selectedRows.filter(itm => itm === item.rowNo);
                if (isId.length > 0) {
                    iasModalData.push(item.uniqueno);
                }
            })
            modalTypeBulk = true;
            isBulkSelected = true;
        }
        this.setState((curr: any) => ({ ...curr, tableData, iasModalData: iasModalData.toString(), modalTypeBulk, isBulkSelected }))
    }


    headerFormatterDef = (column: any, colIndex: any) => {
        return (<>
            <div className='wom_Tabheader'>
                <Form.Check type="checkbox" label="" id="headerBulkSelect" checked={this.state.bulkSelectRow} onClick={(e: any) => this.footerBulkSelectAll(e)} />
            </div>
        </>);
    }

    loadBackData() {
        this.GetInvoiceWaitingOnMe();
        this.hideCheckApprovalModal(true);
    }


    hideSlideOut(isSave) {
        if (isSave) {
            this.setState({ isSlideOut: false }, () => {
                this.GetInvoiceWaitingOnMe();
                this.hideCheckApprovalModal(true);
            });
        } else {
            this.setState({ isSlideOut: false });
        }
    }

    handleSortTable = (eventKey: any) => {
        let { columnSortingList, tableData, SortBy } = this.state;
        columnSortingList.map(item => item.id === eventKey ? (item.isActive = true) : (item.isActive = false))
        let isRow = columnSortingList.filter(item => item.id === eventKey)
        SortBy.name = isRow[0].name;
        SortBy.id = isRow[0].id;
        if (eventKey === "company") {
            tableData = _.orderBy(tableData, [(obj) => obj?.company?.toUpperCase()], ['asc']);
        } else if (eventKey === "invoiceNo") {
            tableData = _.orderBy(tableData, [(obj) => obj?.invoiceNo?.toUpperCase()], ['asc']);
        } else if (eventKey === "dueDate") {
            tableData = _.orderBy(tableData, [(obj) => new Date(obj.dueDate)], ['asc']);
        } else if (eventKey === "billDate") {
            tableData = _.orderBy(tableData, [(obj) => new Date(obj.billDate)], ['asc']);
        } else if (eventKey === "accper") {
            tableData = _.orderBy(tableData, [(obj) => new Date(obj.accper1)], ['asc']);
        } else if (eventKey === "reviewstatus") {
            // Define custom order for reviewstatus
            const customOrder = ["Needs Review", "Reviewed", "Review Complete", ""];

            // Use a custom comparator for sorting reviewstatus
            tableData = _.orderBy(tableData, [(obj) => {
                const index = customOrder.indexOf(obj.reviewstatus);
                return index === -1 ? customOrder.length : index; // Place unknown statuses at the end
            }], ['asc']);
        } else {
            tableData = _.sortBy(tableData, eventKey);
        }
        this.setState({ SortBy, tableData, columnSortingList });
    }

    handleOnExpand = (row, isExpand, rowIndex, e) => {
        let { tableData, searchResult } = this.state;
        let currentExportData = searchResult.length > 0 ? searchResult : tableData;
        if (isExpand) {
            this.setState(() => ({
                expandedList: [...this.state.expandedList, row.rowNo]
            }), () => {
                this.readyForExport(currentExportData);
            });
        } else {
            this.setState(() => ({
                expandedList: this.state.expandedList.filter(x => x !== row.rowNo)
            }), () => {
                this.readyForExport(currentExportData);
            });
        }
    }
    handleEmitMessage = () => {
        this.props?.handleEmitMessage();
    }

    ////New Code---------------
    setZindex = (e: any, row: any) => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            if (items.rowNo === row.rowNo) {
                if (items.isSpinnerLoader || items.isSpinnerPP) {
                    return { ...items, isDropShow: true };
                } else {
                    return { ...items, isDropShow: e };
                }
            } else {
                return items;
            }
        });
        this.setState({ tableData, hidValue: row.hid });
    }

    hidePPModal = (value) => {
        let { tableData } = this.state;
        tableData = tableData.map((items) => {
            return { ...items, isSpinnerPP: false, };
        });
        this.setState({ tableData }, () => {
            if (value) {
                this.loadBackData();
            }
            $("body").trigger("click");
        });
    }

    handleBtnEvent = (event: any, row: any, type) => {
        let { tableData } = this.state;
        let etText = event.target.innerHTML.toString().toLocaleLowerCase();
        if (etText === "partial payment") {
            tableData = tableData.map((items) => {
                if (items.rowNo === row.rowNo) {
                    return { ...items, isSpinnerPP: true };
                } else {
                    return items;
                }
            });
            this.setState({ tableData });
        }
    }

    savePartialPayment = (amount: any, uniqueNo: any, HotelID: any) => {
        let request: any = {};
        request.Uniqueno = uniqueNo;
        request.PartAmt = Utils.removecurrencyFormat(amount)?.replace(',', '')?.trim();
        request.HotelID = this.state.hidValue;
        InvoiceApprovalS.savePartialPayment(request)
            .then(async (result: any) => {
                if (result?.result?.saveStatus?.toLowerCase() === "success") {
                    toast.success(`Partial payment created successfully.`, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                    this.hidePPModal(true);
                } else {
                    this.hidePPModal(true);
                    Utils.toastError(result?.result?.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.hidePPModal(true);
                Utils.toastError("Something went wrong.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                reject();
            });
    }

    render() {

        const columnsChild = [{
            dataField: "letterCode",
            text: "EHID",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (<EllipsisWithTooltip placement="bottom">{row.splitEHIDLetter}</EllipsisWithTooltip>)
            },
            headerClasses: "wom_colcoa",
        }, {
            dataField: "coaName",
            text: "COA",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (<EllipsisWithTooltip placement="bottom">{row.coa + " " + row.coaName}</EllipsisWithTooltip>)
            },
            headerClasses: "wom_colcoa",
        }, {
            dataField: "description",
            text: "DESCRIPTION",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (<EllipsisWithTooltip placement="bottom">{row.description}</EllipsisWithTooltip>)
            },
            headerClasses: "wom_colDesc",
        }, {
            dataField: "amount",
            text: "AMOUNT",
            formatter: (cell: any, row: any, rowIndex: any) => {
                return (
                    <EllipsisWithTooltip placement="bottom">{Utils.currencyFormatWithOutdoller(row.amount)}</EllipsisWithTooltip>
                )
            },
            headerClasses: "wom_colTotalAmount",
            classes: 'text-right',
        },
            // {
            //     dataField: "",
            //     text: "",
            //     classes: 'wom_colNestedLast',
            //     headerClasses: "wom_colNestedLast",
            // }
        ];

        const renderChild = (row) => {
            let uniqueID = Math.floor(Math.random() * 90 + 10);
            let splitItems = this.state.tableData.filter(x => x.uniqueno === row.uniqueno && (x.viewType === 2))
            openRow = row;
            return (
                <div className="wom_nestedChildTable innerInvoiceTable">
                    <BootstrapTable
                        key={uniqueID}
                        id={uniqueID}
                        keyField='uniqueno'
                        data={splitItems}
                        columns={columnsChild}
                        rowEvents={this.rowEvents}
                    />
                </div>
            );
        };

        const expandCreditCardRow = {
            renderer: (row: any, rowIndex: any) => renderChild(row),
            expanded: this.state.expandedList,
            showExpandColumn: true,
            expandByColumnOnly: true,
            onExpand: this.handleOnExpand,
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                if (isAnyExpands) {
                    return <div></div>;
                }
                return <div></div>;
            },
            expandColumnRenderer: ({ expanded }) => {
                if (expanded) {
                    return (
                        <span className="chevronExpended">
                            <img src={chevronRight} alt="chevron Right" />
                        </span>
                    );
                }
                return (
                    <span className="chevronCollpsed">
                        <img src={chevronRight} alt="chevron Right" />
                    </span>
                );
            },
        };

        const bulkResponsiveList = () => {
            return (
                <div className="loader-responsive register">
                    <div className="performanceList">
                        {/* <div className="tableHeader height-40 loadingAnimation"></div> */}
                        <div className="tableList">
                            <ul>
                                <li className="d-flex">
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                    <div className="list d-flex align-self-center">
                                        <div className="group">
                                            <div className="loadingAnimation"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }

        const sortSvg = () => {
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 15L7.5 12H5.25V3H3.75V12H1.5L4.5 15ZM8.25 6H15V7.5H8.25V6ZM8.25 9H13.5V10.5H8.25V9ZM8.25 3H16.5V4.5H8.25V3ZM8.25 12H12V13.5H8.25V12Z" />
                </svg>
            )
        }
        const gearSvg = () => {
            return (
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.56286 10.1808L2.89619 12.4875C3.08019 12.8062 3.48819 12.9155 3.80686 12.7308L4.73752 12.1935C5.12419 12.4982 5.55152 12.7482 6.00086 12.9348V14.0002C6.00086 14.3682 6.29886 14.6668 6.66752 14.6668H9.33419C9.70286 14.6668 10.0009 14.3682 10.0009 14.0002V12.9348C10.4502 12.7482 10.8769 12.4982 11.2642 12.1935L12.1949 12.7308C12.5129 12.9142 12.9222 12.8042 13.1055 12.4875L14.4389 10.1808C14.6222 9.8635 14.5122 9.4535 14.1955 9.27016L13.2809 8.7415C13.3155 8.4955 13.3335 8.2475 13.3335 8.00016C13.3335 7.75283 13.3155 7.50483 13.2795 7.25883L14.1942 6.73016C14.5115 6.54683 14.6215 6.13683 14.4375 5.8195L13.1042 3.51283C12.9202 3.19416 12.5122 3.08483 12.1935 3.2695L11.2629 3.80683C10.8762 3.50216 10.4495 3.2515 10.0002 3.0655V2.00016C10.0002 1.63216 9.70219 1.3335 9.33352 1.3335H6.66686C6.29819 1.3335 6.00019 1.63216 6.00019 2.00016V3.0655C5.55086 3.25216 5.12419 3.50216 4.73686 3.80683L3.80686 3.2695C3.48752 3.08483 3.08019 3.19416 2.89619 3.51283L1.56286 5.8195C1.37953 6.13683 1.48953 6.54683 1.80619 6.73016L2.72086 7.25883C2.68486 7.50483 2.66686 7.75283 2.66686 8.00016C2.66686 8.2475 2.68486 8.4955 2.72086 8.7415L1.80619 9.27016C1.48886 9.4535 1.37886 9.8635 1.56286 10.1808ZM8.00019 5.3335C9.47086 5.3335 10.6669 6.5295 10.6669 8.00016C10.6669 9.47083 9.47086 10.6668 8.00019 10.6668C6.52952 10.6668 5.33352 9.47083 5.33352 8.00016C5.33352 6.5295 6.52952 5.3335 8.00019 5.3335Z" />
                </svg>)
        }
        const checkSvg = () => {
            return (
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                </svg>
            )
        }
        let { selectedRows, tableData, defaultDensityView } = this.state;
        let densityView = defaultDensityView;
        let className;
        let tableLen = tableData.filter(item => item.viewType === 1);
        let isLenLess = selectedRows.length > 0 && selectedRows.length < tableLen.length;
        className = isLenLess ? "showTip" : "";

        const selectRow = {
            mode: 'checkbox',
            // clickToSelect: true,
            selected: this.state.selectedRows,
            headerClasses: "selectionHeader",
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll,
        };
        return (
            <>
                {this.state.isSlideOut && (
                    <InvoiceEntrySlideout
                        mainHidValue={this.props.hidValue}
                        hidValue={this.state.sildeoutValue}
                        hotelName={this.state.sildeoutCode}
                        uniqueNo={this.state.uniqueNo}
                        pageType={"Invoice"}
                        saveAction={"WaitingOnMe"}
                        oprId={this.state.oprId}
                        vid={this.state.vid}
                        hideSlideOut={this.hideSlideOut.bind(this)}
                        loadBackData={this.loadBackData.bind(this)}
                        loadFrom={"PaymentPage"}
                    //OpenInvoiceSlideOut={this.OpenInvoiceSlideOut.bind(this)}
                    //selectedRow={this.state.selectedRow}
                    />
                )}
                {this.state.allCaughtUP ? (<>
                    <div className="vt5allCaughtUp d-flex align-items-center justify-content-center">
                        <img src={invoice_inv} alt="You’re all caught up!" />
                        <div className="label-sec"> You’re all caught up on your invoice approvals. <br />Nice Work <img src={caught} alt="You’re all caught up!" /></div>
                    </div>
                </>) : (
                    <ToolkitProvider
                        keyField="rowId"
                        data={tableData.filter(x => x.viewType === 1)}
                        columns={this.columnsHeader}
                        search={{ afterSearch: (newResult) => this.searchResult(newResult) }}
                    >
                        {(props: {
                            searchProps: JSX.IntrinsicAttributes;
                            baseProps: JSX.IntrinsicAttributes;
                        }) => (
                            <>
                                <div className="vt5InnerTabPanel">
                                    <div className='wom_TabBody'>
                                        <div className="search-header d-flex">
                                            <div className={tableData.length === 0 ? "search-filter disabled-area" : "search-filter"}>
                                                <label id="labeltableSearch" className="search-label">
                                                    <TableSearch id="tableSearch" key="tableSearch" ref={this.child}
                                                        {...props.searchProps} CleanSearch={this.CleanSearch.bind(this)}
                                                        placeholder={"Filter invoices"}
                                                    />
                                                </label>
                                            </div>
                                            <div className="d-flex ml-auto">
                                                <div className={`${tableData.length === 0 ? "disabled-area" : ""} hasShortingBtn wom_hasGearIcon mr-2`}>
                                                    <Dropdown className="pos-static more-action bg-blue dropdown wom_dropdown" alignRight>
                                                        <Dropdown.Toggle id="dropdown-ptoType" disabled={tableData.length === 0 || this.state.isSpinner}>
                                                            <div className="d-flex align-items-center wom_svg">
                                                                <div className="d-flex align-items-center wom_svg">
                                                                    {sortSvg()}
                                                                    {this.state.SortBy.name}
                                                                    <div className="drop-arrow d-flex ml-1">
                                                                        <FiChevronDown />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="wom_DencityMenu">
                                                            <div className="wom_MenuOverlap">
                                                                {this.state.columnSortingList.map((item, index) => {
                                                                    if (item.isHidden || item.id === "internalNotes") {// || item.id === "tabStatus"
                                                                        return (<></>)
                                                                    } else {
                                                                        return (
                                                                            <div key={index}
                                                                                className={item.isActive ? "active wom_dropdownItem" : "wom_dropdownItem"}
                                                                                onClick={(e) => this.handleSortTable(item.id)}>
                                                                                <div className="d-flex">
                                                                                    <div className="mr-auto">{item.name}</div>
                                                                                    <div className="ml-auto">{item.isActive && checkSvg()}</div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}
                                                            </div>
                                                            <div className="m-0 dropdown-divider"></div>
                                                            <Dropdown.Item className="wom_saveDefaultBtn" onClick={() => this.saveDefaultFilter()} >Save as Default Filter</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>


                                                <div className="wom_hasToggleBtn">
                                                    <Button type="button"
                                                        onClick={(e: any) => this.handleToggleRows(this.state.expandedList.length > 0 ? "Collapse_All" : "Expend_All")}
                                                        className="btn-outline-primary btnHeight"
                                                        disabled={tableData.length === 0}
                                                    >{this.state.expandedList.length > 0 ? "Collapse All" : "Expand All"}</Button>
                                                </div>

                                                <div className="ml-1 wom_hasGearIcon">
                                                    <Dropdown className="pos-static more-action bg-blue dropdown wom_dropdown">
                                                        <Dropdown.Toggle id="dropdown-ptoType" disabled={tableData.length === 0}>
                                                            <div className="d-flex align-items-center wom_svg">
                                                                {gearSvg()}
                                                                <div className="drop-arrow d-flex">
                                                                    <FiChevronDown />
                                                                </div>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="wom_DencityMenu">
                                                            <>
                                                                <div className="wom_gearIconHeader wom_headerTop"><strong>Density View Control</strong></div>
                                                                {this.state.densityView.map((items, index) => (
                                                                    <div className={densityView === items.densityType ? 'wom_dropdownItem wom_ddactive' : 'wom_dropdownItem'}
                                                                        key={items.id}
                                                                        onClick={(e: any) => this.handleDensityView(items.densityType)}>{items.name}
                                                                        {densityView === items.densityType && (<>{checkSvg()}</>)}
                                                                    </div>
                                                                ))}
                                                                <div className="m-0 dropdown-divider"></div>
                                                                <Dropdown.Item className="wom_saveDefaultBtn" onClick={() => this.saveDefaultView()} >Save as Default View</Dropdown.Item>
                                                            </>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div id="wom_CombinedTable" className={`${this.state.bulkSelect ? "wom_CombinedTable hasFooter" : "wom_CombinedTable noFooter"} ${className}`} ref={this.scrollArea}>
                                            {(this.state.isSpinner) ? (
                                                <div className="bulkImportList mt-0">
                                                    <div className="bulkImportList1 d-flex">
                                                        <div className="single-loader loadingAnimation"></div>
                                                    </div>
                                                    <>{bulkResponsiveList()}</>
                                                    <>{bulkResponsiveList()}</>
                                                    <>{bulkResponsiveList()}</>
                                                </div>
                                            ) : (
                                                <div className={this.state.isBulkSelected ? `${densityView} disabled-area stickey` : `${densityView} stickey`}>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        keyField="rowNo"
                                                        hover
                                                        noDataIndication={'No record found.'}
                                                        selectRow={selectRow}
                                                        expandRow={expandCreditCardRow}
                                                        filter={filterFactory()}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className={this.state.bulkSelect ? "wom_TabFooter" : "d-none wom_TabFooter"}>
                                            <div className="bulkSelectFooter">
                                                <Form.Check type="checkbox" label=""
                                                    id="footerBulkSelect"
                                                    checked={this.state.bulkSelectRow}
                                                    onClick={(e: any) => this.footerBulkSelectAll(e)} />
                                                <span className="shift-selected-count">{this.state.selectedRows.length} Invoice
                                                    {this.state.selectedRows.length > 1 && ("s")} Selected</span>
                                                <div className="footer-vertical-line"></div>
                                                <span className="shift-selected-count">Batch Total: {Utils.currencyFormatWithOutdoller(this.state.batchTotalCount)}</span>
                                                <div className="action-group d-flex flex-row mr-auto">
                                                    <Dropdown className="wom_hasDropShadow" alignRight>
                                                        <Dropdown.Toggle
                                                            className="isNoBtn"
                                                            variant="success" id="dropdown-split-basic">
                                                            <Button onClick={(e: any) => this.showCheckApprovalModal(this.state.tableData, "bulk")}> Approve</Button>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className={this.state.modalTypeBulk ? "wom_setToRight" : "d-none wom_setToRight"}>
                                                            {this.state.modalTypeBulk && (
                                                                <InvoiceApprovalSubmit
                                                                    data={this.state.iasModalData}
                                                                    reloadTableData={this.GetInvoiceWaitingOnMe}
                                                                    hideCheckApprovalModal={this.hideCheckApprovalModal}
                                                                    handleEmitMessage={this.handleEmitMessage}
                                                                />
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <Button className="closeButton btn-ghost-light" onClick={() => this.footerBulkClose()}
                                                >&times;</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </ToolkitProvider>
                )}

            </>


        )
    }
}