import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container, Dropdown, Form, Row, Col, Button, Tabs, Tab, Badge } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { resolve, reject } from "q";
import { IHotelResponseWithDataDto } from "../../../Common/Contracts/IHotel";
import { Hotel as hotel } from "../../../Common/Services/Hotel";
import './invoiceApproval.scss';
import { WaitingonMe } from './waitingonme'
import { AllInvoices } from "./Allinvoices";
import { PendingVendorApproval } from "./PendingVendorApproval";
import { InvoiceApprovalS } from "../../../Common/Services/InvoiceApprovals";

import { HubConnectionBuilder } from "@microsoft/signalr";
import { HIDWithData } from "../../../Common/Components/HIDWithData";
import { Utils } from "../../../Common/Utilis";

let localStorageUser: any;
export class InvoiceApproval extends React.Component<any, any> {
    private WaitingonMe: any;
    private AllInvoices: any;
    private AllUnAppVendor: any;
    constructor(props: any) {
        super(props);
        this.WaitingonMe = React.createRef();
        this.AllInvoices = React.createRef();
        this.AllUnAppVendor = React.createRef();
        this.state = {
            hidList: [],
            hidFilterList: [],
            hidValue: "Select",
            hotelName: "Select",
            key: "WaitingOnMe",
            bothPageReload: false,
            VendorCount: 0,
            InvoiceCount: 0,
            allInvoiceCount: 0
        };

        this.reloadAllTab = this.reloadAllTab.bind(this);
    }

    componentDidMount() {

        let parm = this.getURLParamsValues()?.parm;
        const { location, history } = this.props;
        if (parm && parm.length > 0) {
            let parmval = atob(parm);
            let hotelId = parmval.split('_')[0];
            let tabtype = parmval.split('_')[1];
            if (tabtype === "VendorApproval") {
                this.setState({ key: "PendingVendorApproval" });
            }
            if (tabtype === "AllInvoices") {
                this.setState({ key: "AllInvoices" });
            }
            // this.gettabCount(hotelId);
            // history.replace();
            if (hotelId === '-1') {
                this.loadHotelForAll('All');
            }
            else {
                this.loadHotelForAll(hotelId);
            }
        }
        else {
            this.loadHotelForAll('All');
            // this.gettabCount("All");
        }

        this.handleSocketConnection();
    }

    handleSocketConnection() {
        // const hubConnection = new HubConnectionBuilder()
        //     .withUrl('https://inn-flow-v2-api-testv2.azurewebsites.net/hubs/chat')
        //     .withAutomaticReconnect()
        //     .build();
        // // Handle ReceiveMessage - getMessage
        // this.setState({ hubConnection }, () => {
        //     this.state.hubConnection
        //         .start()
        //         .then(() => console.log('Connection started!'))
        //         .catch(err => console.log('Error while establishing connection :('));

        //     this.state.hubConnection.on('ReceiveMessage', (message) => {
        //         console.log("message::", message)
        //         this.loadHotelForAll(this.state.hidValue)
        //     });
        // });

    }

    handleEmitMessage = async () => {
        // const chatMessage = { message: "User is updated!" };
        // try {
        //     let respHeader: any = { method: 'POST', body: JSON.stringify(chatMessage), headers: { 'Content-Type': 'application/json' } }
        //     await fetch('https://inn-flow-v2-api-testv2.azurewebsites.net/api/chat/messages', respHeader);
        //     this.state.hubConnection.send('SendMessage', chatMessage);
        // } catch (e) {
        //     console.log("event::", e)
        // }

        this.loadHotelForAll(this.state.hidValue)
    };

    getParamValueByParamName(paramName: string): any {
        const valuesArray = decodeURIComponent(this.props.history.location.search)?.replace('?', '')?.split('&');
        const item = valuesArray?.find(r => r?.includes(paramName));
        return item?.split('=')[1];
    }
    getURLParamsValues = (): any => {
        const paramObject: any = {
            parm: this.getParamValueByParamName("parm")
        }
        return paramObject;
    }

    loadHotelForAll = (defaultHID) => {
        localStorageUser = JSON.parse(localStorage.getItem("storage")!);
        const hotelid = JSON.parse(localStorage.getItem("hotelid")!);
        const hotelName = JSON.parse(localStorage.getItem("hotelName")!);
        hotel.UserHotelAccessWithData("Invoice", "Approval").then(async (result: IHotelResponseWithDataDto[] | null) => {
            // debugger;
            if (result != null && result.length > 0) {
                //result = result.filter((o) => o.hotelType !== "3");
                const allAppendedHotel = {
                    ehidName: "All",
                    hotelID: "All",
                    hotelName: "All",
                    hotelType: "1",
                    lettercode: "All",
                    tenantID: localStorageUser.tenantID,
                };

                this.setState({ hidList: [] }, () => {
                    this.setState({ hidList: result }, () => {
                        if (result !== null && result?.length > 1) {
                            this.state.hidList.unshift(allAppendedHotel);
                        }
                    });
                })

                this.setState({ hidFilterList: result });
                if (hotelName === null || hotelid === null) {
                    if (defaultHID === 'All') {
                        if (result.length > 1) {
                            this.setState({
                                hotelName: this.state.hidList[0].lettercode,
                                hidValue: this.state.hidList[0].hotelID,
                            }, () => {
                                this.gettabCount(this.state.hidValue);
                            });
                        } else {
                            this.setState({
                                hotelName: this.state.hidList[0].lettercode,
                                hidValue: this.state.hidList[0].hotelID,
                            }, () => {
                                this.gettabCount(this.state.hidValue);
                            });
                        }
                    }
                    else {
                        let hotelDetail: any = [];
                        hotelDetail = result.find(
                            (r) => +r?.hotelID === +defaultHID);
                        this.setState({
                            hotelName: hotelDetail.lettercode,
                            hidValue: hotelDetail.hotelID,
                        }, () => {
                            this.AllInvoices.current?.GetInvoiceDataAllInvoices();
                            this.WaitingonMe.current?.GetInvoiceWaitingOnMe();
                            this.AllUnAppVendor.current?.GetUnApproveVendors();
                            this.gettabCount(this.state.hidValue);
                        });
                    }
                } else {
                    this.setState({ hotelName: hotelName });
                }
            }
            resolve();
        }).catch((error) => {
            Utils.toastError(`Server Error: ${error}`, { position: toast.POSITION.BOTTOM_RIGHT });
            reject();
        });
    }

    loadHotelsDetailsDup = () => {
        let { hidList } = this.state;
        hotel.UserHotelAccessWithData("Invoice", "Approval").then(async (result: IHotelResponseWithDataDto[] | null) => {
            if (result != null && result.length > 0) {
                result.forEach((rs: any) => {
                    hidList.forEach((sl: any) => {
                        if (sl?.hotelID === rs?.hotelID) {
                            sl.dataExists = rs.dataExists
                        }
                    })
                })
                this.setState({ hidList });
            }
            resolve();
        }).catch((error) => {
            Utils.toastError(`Server Error: ${error}`, { position: toast.POSITION.BOTTOM_RIGHT });
            reject();
        });
    }

    getBudget = (hotelDetails: any) => {
        this.setState({
            hotelName: hotelDetails.lettercode,
            hidValue: hotelDetails.hotelID,
        }, () => {
            this.WaitingonMe.current?.resetInvoiceWaitingOnMe();
            this.AllInvoices.current?.resetInvoiceDataAllInvoices();

            this.WaitingonMe.current?.GetInvoiceWaitingOnMe();
            this.AllInvoices.current?.GetInvoiceDataAllInvoices();
            // this.WaitingonMe.current?.resetUnApproveVendors();
            this.AllUnAppVendor.current?.GetUnApproveVendors();
        });
        this.gettabCount(hotelDetails.hotelID);
        this.loadHotelsDetailsDup();
    };

    searchClear = () => {
        this.setState({ search: "" });
        this.setState({ hidList: this.state.hidFilterList });
    };

    bothApiReload = (isTrue) => {
        this.setState({ bothPageReload: isTrue }, () => {
            this.gettabCount(this.state.hidValue)
            this.loadHotelForAll(this.state.hidValue);
        });
    }

    handleTotalCount = (VendorCount) => {
        //this.setState({ VendorCount: VendorCount });
    }


    handleInvoiceTotalCount = (InvoiceCount) => {
        //this.setState({ InvoiceCount: InvoiceCount });
    }


    handleAllInvoiceTotalCount = (allInvoiceCount) => {
        //this.setState({ allInvoiceCount: allInvoiceCount });
    }

    reloadAllTab = () => {
        this.WaitingonMe.current?.GetInvoiceWaitingOnMe();
        this.AllInvoices.current?.GetInvoiceDataAllInvoices();
        this.AllUnAppVendor.current?.GetUnApproveVendors();

    }

    gettabCount(hotelId) {

        let request: any = {};
        if (hotelId !== null) {
            request.HID = hotelId === "Select" ? "-1" : hotelId === "All" ? '-1' : hotelId;
            InvoiceApprovalS.getInvoiceApprovalCount(request)
                .then(async (result: any | null) => {
                    if (result.length > 0) {
                        this.setState({
                            VendorCount: result[0].unAppVendor,
                            InvoiceCount: result[0].waingOnMeCount,
                            allInvoiceCount: result[0].allCount

                        })
                    }
                    resolve();
                })
                .catch((error) => {
                    Utils.toastError(`Server Error: ${error}`, { position: toast.POSITION.BOTTOM_RIGHT });
                    reject();
                });
        }

    }

    handleTabSelect = (eventKey: any) => {
        let parm = this.getURLParamsValues()?.parm;
        if (this.state.bothPageReload || (parm && parm.length > 0)) {
            this.WaitingonMe.current?.GetInvoiceWaitingOnMe();
            this.AllInvoices.current?.GetInvoiceDataAllInvoices();
            this.WaitingonMe.current?.resetInvoiceWaitingOnMe();
            this.AllInvoices.current?.resetInvoiceDataAllInvoices();
        }
        this.AllUnAppVendor.current?.GetUnApproveVendors();

        this.setState({ bothPageReload: false })
        if (eventKey === "WaitingOnMe") {
            setTimeout(() => { this.WaitingonMe.current?.updateColumns() }, 100);
        } else if (eventKey === "WaitingOnOthers") {
        } else if (eventKey === "AllInvoices") {
            setTimeout(() => { this.AllInvoices.current?.updateColumns() }, 100);
        } else {

        }
        this.setState({ key: eventKey }, () => {
            this.gettabCount(this.state.hidValue);
        })
    };

    render() {
        return (
            <>
                <div className="vt5_invoiceApproval invoice-approval-page" onClick={this.searchClear}>
                    <ToastContainer autoClose={3000} />
                    <Container fluid className="vt5_posRel body-sec horizontal-scroll">
                        <div className="page-heading d-flex">
                            {this.state.hidList.length > 0 ? (
                                <HIDWithData
                                    hotelName={this.state.hotelName}
                                    hidList={this.state.hidList}
                                    selectHID={this.getBudget}
                                    hidFilterList={this.state.hidFilterList}
                                />
                            ) : (
                                <Dropdown className="hid-select disabled-area">
                                    <Dropdown.Toggle id="dropdown-hid" disabled={this.state.loaderTrue}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            {this.state.hotelName}
                                            <div className="drop-arrow d-flex"><FiChevronDown /></div>
                                        </div>
                                    </Dropdown.Toggle>
                                </Dropdown>
                            )}

                            <div className="mr-auto">Invoice Approval</div>
                            <div className="action-group d-flex">
                                {this.state.key.toString().toLowerCase() !== "pendingvendorapproval" && (
                                    <Dropdown className="pos-static more-action btn-dropdown" alignRight>
                                        <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more" >
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                                <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                                <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {this.state.key === "WaitingOnMe" && (<>{this.WaitingonMe.current?.exportWaitingonMe()}</>)}
                                            {this.state.key === "WaitingOnOthers" && (<Dropdown.Item>Export</Dropdown.Item>)}
                                            {this.state.key === "AllInvoices" && (<>{this.AllInvoices.current?.exportAllInvoices()}</>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </div>
                        </div>


                        <Tabs id="controlled-tab-example" className="vt5_TabContent" activeKey={this.state.key} onSelect={this.handleTabSelect} >
                            <Tab eventKey="WaitingOnMe" title={
                                <React.Fragment>
                                    <div className="d-flex">
                                        <div>Waiting on Me</div>
                                        <div className="count">{this.state.InvoiceCount}</div>
                                    </div>
                                </React.Fragment>
                            }>
                                <WaitingonMe
                                    ref={this.WaitingonMe}
                                    hidValue={this.state.hidValue}
                                    hotelName={this.state.hotelName}
                                    handleBothReload={this.bothApiReload}
                                    handleInvoiceTotalCount={this.handleInvoiceTotalCount}
                                    handleEmitMessage={this.handleEmitMessage}
                                />
                            </Tab>
                            {/* <Tab eventKey="WaitingOnOthers" title="Waiting on Others"></Tab> */}
                            <Tab eventKey="AllInvoices" title={
                                <React.Fragment>
                                    <div className="d-flex">
                                        <div>All Invoices</div>
                                        <div className="count">{this.state.allInvoiceCount}</div>
                                    </div>
                                </React.Fragment>
                            }>
                                <AllInvoices
                                    ref={this.AllInvoices}
                                    hidValue={this.state.hidValue}
                                    hotelName={this.state.hotelName}
                                    handleBothReload={this.bothApiReload}
                                    handleAllInvoiceTotalCount={this.handleAllInvoiceTotalCount}
                                />
                            </Tab>
                            <Tab eventKey="PendingVendorApproval" title={
                                <React.Fragment>
                                    <div className="d-flex">
                                        <div>Pending Vendor Approval</div>
                                        <div className="count">{this.state.VendorCount}</div>
                                    </div>
                                </React.Fragment>
                            }>
                                <PendingVendorApproval
                                    ref={this.AllUnAppVendor}
                                    hidValue={this.state.hidValue}
                                    hotelName={this.state.hotelName}
                                    handleBothReload={this.bothApiReload}
                                    handleTotalCount={this.handleTotalCount}
                                    reloadAllTab={this.reloadAllTab}
                                />
                            </Tab>
                        </Tabs>
                    </Container>
                </div>
            </>
        );
    }
}