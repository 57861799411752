import * as React from "react";
import { Form, Container } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { PmsMonitoringService, PmsMonitoringService as pmsMonitoringService } from "../../../Common/Services/PmsMonitoringService";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import "./pmsImport.scss";
import { resolve, reject } from "q";
import { ReactPageLoader } from "../../../Common/Components/ReactPageLoader";
import { ToastContainer, toast } from "react-toastify";
import { DropDownList } from "../../Reports/common-components/dropdown-list";
import { Utils } from "../../../Common/Utilis";
import DatePicker from "react-datepicker";
import _ from "lodash";

export class PmsMonitoring extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            isPermission: "No",
            pmsMonitoringData: [],
            filteredpmsMonitoringData: [],
            isLoader: false,
            tenantsList: [],
            tenantData: {
                userUniqueno: this.props?.userUniqueNo,
                tenant: 0
            },
            hotelList: [],
            hotelData: {
                hotelid: 0,
                lettercode: ""
            },
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            maxDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            minDate: new Date(new Date().setDate(new Date().getDate() - 7))
        };
    }

    componentDidMount() {
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : storage.tenantID;
        if (tenantID !== 230) {
            window.location.href = "/unauthorize-access";
        } else {
            this.setState({ isPermission: "Yes" }, () => {
                this.getPmsMonitoringData();
            })
        }
    }

    getPmsMonitoringData = () => {
        const { startDate } = this.state;

        let request: any = {};
        request.Date = startDate;
        this.setState({ isLoader: true })

        pmsMonitoringService.GetPmsMonitoringData(request)
            .then(async (result: any | null) => {
                if (result.length > 0) {
                    this.setState({ pmsMonitoringData: result, filteredpmsMonitoringData: result, isLoader: false }, () => this.getTenantsList(result))
                }
                else {
                    this.setState({ pmsMonitoringData: [], filteredpmsMonitoringData: [], isLoader: false });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ pmsMonitoringData: [], filteredpmsMonitoringData: [], isLoader: false }, () => {
                    Utils.toastError(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "PmsMonitoring",
                    });
                });
                reject();
            });
    }

    getTenantsList = (result) => {
        let uniqueTenantList = _.uniqBy(result, "tenantId");
        uniqueTenantList.map((items, index) => ((items["id"] = index, items["tenantid"] = items.tenantId, items["tenantname"] = items.tenantId)));

        const tenantsList = [
            { id: -1, tenantname: "All", tenantid: "All" },
            ...uniqueTenantList
        ];
        this.setState({ tenantsList })
    }

    handleDateChange = (date) => {
        const { tenantData, hotelData } = this.state;
        tenantData["tenant"] = 0;
        hotelData["hotelid"] = 0;
        hotelData["lettercode"] = "";
        if (date === null) date = new Date(new Date().setDate(new Date().getDate() - 1));
        this.setState({ startDate: date, tenantData, hotelData, hotelList: [] }, () => this.getPmsMonitoringData());
    }

    onFieldChange = (event, inputPosition, isBlur = false) => {
        const { tenantData, pmsMonitoringData, hotelData } = this.state;
        let value = event.target.value;
        const fieldName = event.target.name;
        tenantData[fieldName] = value;
        hotelData.hotelid = 0;
        hotelData.lettercode = ""

        let filteredData;

        if (value === "All") {
            filteredData = pmsMonitoringData;
        } else {
            filteredData = pmsMonitoringData.filter(x => x.tenantId === value);
        }

        this.setState({ tenantData, filteredpmsMonitoringData: filteredData, hotelList: [], hotelData }, () => {
            this.getHotelsListFromTenantId(value);
        });
    };

    getHotelsListFromTenantId = (selectedTenantId) => {
        let request: any = {};
        request.STenantId = selectedTenantId;
        request.usedFor = "API";
        PmsMonitoringService.GetHidListFromTenantId(request)
            .then((data) => {
                const hotelList = [
                    { id: -1, lettercode: "All", hotelid: "All" },
                    ...data
                ];
                this.setState({ hotelList })
            }).catch((error) => {
                this.setState({ hotelList: [] }, () => {
                    Utils.toastError(error.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        containerId: "PmsMonitoring",
                    });
                });
                reject();
            });
    }

    onEhidChange = (event, inputPosition, isBlur = false) => {
        const { tenantData, pmsMonitoringData, hotelData } = this.state;
        let value = event.target.value;
        let name = event.target.name;

        hotelData["lettercode"] = value;
        hotelData["hotelid"] = name;

        let filteredData = pmsMonitoringData;

        if (value === "All") {
            filteredData = filteredData.filter(x => x.tenantId === tenantData.tenant);
        } else {
            filteredData = filteredData.filter(x => x.tenantId === tenantData.tenant && x.hotel === value);
        }

        this.setState({ filteredpmsMonitoringData: filteredData, hotelData });
    };

    tenantIdFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{row.isChild ? "" : row.tenantId}</div>
        );
    }

    ehidFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div>{row.isChild ? "" : row.hotel}</div>
        );
    }

    receivedFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div className={row.pstatus === "RECEIVED" ? "dot-icon green" : "dot-icon red"}></div>
        );
    }

    startedFormatter(cell: any, row: any, rowIndex: any) {
        return (
            row.rstatus === "RUNNING" ?
                <div>{row.runningTime}</div>
                : <div className={row.rstatus === "IMPORTED" ? "dot-icon green" : "dot-icon red"}></div>
        );
    }

    importedFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <div className={row.sstatus === "PROCESSED" ? "dot-icon green" : row.sstatus === "NOTRECEIVED" ? "dot-icon orange" : "dot-icon red"}></div>
        );
    }

    errorFormatter(cell: any, row: any, rowIndex: any) {
        return (
            <EllipsisWithTooltip placement="bottom">
                <div className="error-msg">{row.sstatus === "ERROR" && row.error}</div>
            </EllipsisWithTooltip>
        );
    }

    render() {
        const { filteredpmsMonitoringData, tenantsList, hotelList, startDate, maxDate, minDate, isPermission,
            tenantData: {
                tenant
            },
            hotelData: {
                hotelid
            }
        } = this.state;

        const rowClasses = (row, rowIndex) => {
            let classes = 'hide-bottom-bdr';

            if (!row.isChild) {
                classes = classes + ' show-top-bdr';
            }

            return classes;
        };

        const columns = [
            {
                dataField: "tenantId",
                text: "Tenant ID",
                headerClasses: "width8",
                formatter: this.tenantIdFormatter
            }, {
                dataField: "hotel",
                text: "EHID",
                formatter: this.ehidFormatter
            }, {
                dataField: "propertycode",
                text: "Property Code",
            }, {
                dataField: "pstatus",
                text: "Received",
                formatter: this.receivedFormatter
            }, {
                dataField: "rstatus",
                text: "Started",
                formatter: this.startedFormatter
            }, {
                dataField: "sstatus",
                text: "Imported",
                formatter: this.importedFormatter
            }, {
                dataField: "error",
                text: "Error",
                headerClasses: "width25",
                formatter: this.errorFormatter
            },
        ];
        return (
            <Container fluid className="body-sec pms-import">
                <ToastContainer
                    autoClose={3000}
                    containerId={"PmsMonitoring"}
                    enableMultiContainer
                />
                {isPermission === "Yes" && (
                    <>
                        <div className="page-heading underline d-flex">
                            <div className="mr-auto d-flex align-items-center">PMS Import Monitoring (API Based)</div>
                        </div>
                        <div className="main-Page">
                            <div className="bdy-sec">
                                {!this.state.isLoader && (
                                    <div className="search-header">
                                        <div className="filter-section d-flex">
                                            <Form.Group controlId="date">
                                                <Form.Label>Date</Form.Label>
                                                <div className="date-picker calender-picker">
                                                    <div className="drop-arrow">
                                                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.28122e-05 1.19724C3.28122e-05 0.889538 0.253262 0.653982 0.543288 0.653982C0.688297 0.653982 0.833313 0.708449 0.923855 0.816677L4.0023 4.0217L7.08074 0.816677C7.2979 0.599519 7.6424 0.599519 7.84116 0.816677C8.04064 1.01616 8.05831 1.34153 7.85955 1.55941L4.40046 5.18112C4.20098 5.39827 3.85721 5.39828 3.64005 5.19951L3.62166 5.18112L0.145009 1.55941C0.0544669 1.46887 0 1.34226 0 1.19724L3.28122e-05 1.19724Z" fill="#3F4347" />
                                                        </svg>
                                                    </div>
                                                    <DatePicker
                                                        className="form-control"
                                                        placeholderText={"Select Date"}
                                                        dateFormat="MM/dd/yyyy"
                                                        autoComplete="off"
                                                        selected={startDate}
                                                        minDate={minDate}
                                                        maxDate={maxDate}
                                                        onChange={(date) => this.handleDateChange(date)}
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="type" >
                                                <Form.Label>Tenant</Form.Label>
                                                {(tenantsList && tenantsList.length > 1 && <DropDownList
                                                    defaultTextColor={true}
                                                    placeHolder={"Search Tenant"}
                                                    data={tenantsList}
                                                    isSearchRequired={true}
                                                    label={"tenantname"}
                                                    value={"tenantid"}
                                                    onDropDownChange={(item) => {
                                                        if (!item) {
                                                            return;
                                                        }
                                                        this.onFieldChange(
                                                            Utils.BuildCustomEventObject(
                                                                "tenant",
                                                                item.tenantid
                                                            ),
                                                            1
                                                        );
                                                    }}
                                                    selectedItem={[
                                                        ...[{ tenantid: 0, tenantname: "Select Tenant" }],
                                                        ...tenantsList,
                                                    ].find((r) => +r.tenantid === tenant)}
                                                />
                                                )}
                                            </Form.Group>
                                            {(hotelList && hotelList.length > 1 &&
                                                <Form.Group controlId="type" >
                                                    <Form.Label>EHID</Form.Label>
                                                    {(hotelList && hotelList.length > 1 && <DropDownList
                                                        defaultTextColor={true}
                                                        placeHolder={"Search EHID"}
                                                        data={hotelList}
                                                        isSearchRequired={true}
                                                        label={"lettercode"}
                                                        value={"hotelid"}
                                                        onDropDownChange={(item) => {
                                                            if (!item) {
                                                                return;
                                                            }
                                                            this.onEhidChange(
                                                                Utils.BuildCustomEventObject(
                                                                    item.hotelid,
                                                                    item.lettercode
                                                                ),
                                                                1
                                                            );
                                                        }}
                                                        selectedItem={[
                                                            ...[{ hotelid: 0, lettercode: "Select EHID" }],
                                                            ...hotelList,
                                                        ].find((r) => +r.hotelid === hotelid)}
                                                    />
                                                    )}
                                                </Form.Group>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {this.state.isLoader ? (
                                    <ReactPageLoader useas={"pmsMonitoring"} />
                                ) : (
                                    <div>
                                        <BootstrapTable
                                            rowClasses={rowClasses}
                                            keyField='id'
                                            data={filteredpmsMonitoringData}
                                            columns={columns}
                                            noDataIndication="No matching record found"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Container>
        )
    }
}