import * as React from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Button,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  FormLabel,
  Form,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { HidSelector } from "../../Common/Components/HidSelector";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import { Facility } from "../../Common/Services/Facility";
// import "./Location.scss";
// import "./facilities.scss";
import "./workOrderDetails.scss";
import _ from "lodash";
// import { AddAsset } from "./AddAsset";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import TableSearch from "../../Common/Components/TableSearch";
import { AddWorkOrder } from "./AddWorkOrder";
import { FiTrendingUp } from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert";

import WorkOrderSlideOut from "./WorkOrderSlideOut";
import { resolve, reject } from "q";
import {
  IWorkOrderRequestDto,
  IWorkOrderSummaryRequestDto,
  IWorkOrderDeleteRequestDto,
  IWorkOrderCommentStatusRequestDto,
  IWorkOrderPermissionRequestDto,
} from "../../Common/Contracts/IWorkOrder";

import { WorkOrder } from "../../Common/Services/WorkOrder";
import moment from "moment-timezone";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { Hotel as hotel } from "../../Common/Services/Hotel";
import { IHotelResponseDto } from "../../Common/Contracts/IHotel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import profile from "../../Common/Assets/Images/profile.png";
import { Utils } from "../../Common/Utilis";

let hotelDetailsData: any;
export class workOrderDetails extends React.Component<any, any> {
  private tableSearchnew: any;
  private childHID: any;
  private wororderSlideOut: any;
  private workOrderRef: any;
  constructor(props: any) {
    super(props);
    this.tableSearchnew = React.createRef();
    this.childHID = React.createRef();
    this.wororderSlideOut = React.createRef();
    this.workOrderRef = React.createRef();
    this.state = {
      scrollPos: 0,
      pageLoader: false,
      showAddWorkOrderModal: false,
      hidValue: "Select",
      hotelName: "Select",
      tableData: [],
      hidList: [],
      tableDataFilter: [],
      locationTypesList: [],
      assetTypesList: [],
      createEditPermission: true,
      locationList: [],
      searchText: "",
      assetTypeId: 0,
      sortingOrder: "asc",
      sortingField: "location",
      priorityList: [
        { id: "All", name: "All" },
        { id: "Low", name: "Low" },
        { id: "Medium", name: "Medium" },
        { id: "High", name: "High" },
        { id: "Urgent", name: "Urgent" },
      ],
      workOrderStatus: [
        { id: "All", name: "All" },
        { id: "In Progress", name: "In Progress" },
        { id: "Open", name: "Open" },
        { id: "On Hold", name: "On Hold" },
        { id: "Completed", name: "Completed" },
      ],
      isSlideOut: false,
      selectedRows: [],
      preSelectedRows: [],
      assignToList: [],
      //assignToChieldList: [],
      isDayDropDown: false,
      isDateFilter: "Last 7 Days",
      parentRowData: [],
      lastWorkId: "",
      searchAssignUserId: 0,
      searchPririty: "",
      isworkLoader: false,
      isWorkOrderType: "",
      isWorkOrderDetailsChange: false,
      isRefresh: false,
      assignToListToChild: [],
      assignToListRightPanel: [],
      completedTop: "",
      completedCheakox: [],
      isPermission: "",
      pramworkOrdrid: "",
      ParmaworkOrdrValue: "",
      workorderPermission: "",
      locationSearchTypesList: [],
      searchStatus: "",
      searchLocationId: "",
      eventName: "",
      PageType: "workOrderDetails",
    };

    this.handleSelectedItem = this.handleSelectedItem.bind(this);
  }

  componentDidMount() {
    let hotelId = this.getURLParamsValues()?.hotelId;
    let status = this.getURLParamsValues()?.status;
    let source = this.getURLParamsValues()?.source;

    let storage = JSON.parse(localStorage.getItem("hotelSelected")!);
    let storagehotelid = storage === null ? -1 : (storage.hotelID as any);

    let deepParam = localStorage.getItem("deepParam");
    let param = "";
    if (source == "overview") {
      this.loadHotelsDetailsOverview(hotelId);
    } else if (deepParam !== "") {
      try {
        param = atob(String(window.location.href.split("?")[1]));

        let parmaValue = param.split("&")[0];
        let parmaValue1 = param.split("&")[1];
        let hid = parmaValue.split("=")[1];
        let workOrdrid = parmaValue1.split("=")[1];
        //  this.GetWorkOrdeStatus(hid,workOrdrid);
        this.loadHotelsDetails(hid, workOrdrid, "redirectLink");
      } catch {
        this.setState({ isLoader: false, mobileLoader: false });
        window.location.href = "/unauthorize-access";
      }
    }

    this.GetWorkOrderPermission();
  }

  getParamValueByParamName(paramName: string): any {
    const valuesArray = decodeURIComponent(this.props.history.location.search)
      ?.replace("?", "")
      ?.split("&");
    const item = valuesArray?.find((r) => r?.includes(paramName));
    return item ? window.atob(item?.split("=")[1] as any) : "";
  }
  getURLParamsValues = (): any => {
    const paramObject: any = {
      hotelId: this.getParamValueByParamName("hotelId"),
      status: this.getParamValueByParamName("status"),
      source: this.getParamValueByParamName("source"),
    };
    return paramObject;
  };

  GetWorkOrdeStatus(hid: any, workOrdrid: any) {
    let request = {} as IWorkOrderCommentStatusRequestDto;
    request.workorderno = Number(workOrdrid);
    this.setState({ ParmaworkOrdrValue: "Parmavalue" });
    WorkOrder.WorkOrderCommentStatus(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          debugger;
          //this.loadHotelsDetails(hid,workOrdrid,result);

          if (result === "Completed") {
            this.getWorkOrderSummaryDetails("completed", "");
            this.setState({ isDayDropDown: true });
          } else {
            this.getWorkOrderSummaryDetails("notcompleted", "");
          }
        } else {
          //this.loadHotelsDetails(hid,workOrdrid,result);
        }
        this.setState({ ParmaworkOrdrValue: "" });

        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  }

  loadHotelsDetailsOverview(hid) {
    hotel
      .getUserHotelAccess("Facility Maintenance")
      .then(async (result: IHotelResponseDto[] | null) => {
        if (result != null) {
          const hotelDetail: any = result.find((r) => +r?.hotelID === +hid);

          localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
          sessionStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
          this.childHID?.current?.updateChildState(hotelDetail);
          this.setState({ hidValue: hotelDetail.hotelID }, () => {
            this.selectHotelDropDown(
              hotelDetail,
              "ParmaworkOrdridType",
              "",
              ""
            );
          });
          //  this.selectHotelDropDown(hotelDetail);
        }
      })
      .catch((error) => {
        reject();
      });
  }

  loadHotelsDetails(hid: any, workOrdrid: any, type: any) {
    debugger;
    hotel
      .getUserHotelAccess("Facility Maintenance")
      .then(async (result: IHotelResponseDto[] | null) => {
        debugger;
        if (result != null) {
          this.setState({ hidList: result });
          if (hid) {
            const hotelDetail = result.find((r) => +r?.hotelID === +hid);
            localStorage.setItem("hotelSelected", JSON.stringify(hotelDetail));
            sessionStorage.setItem(
              "hotelSelected",
              JSON.stringify(hotelDetail)
            );

            this.setState({
              lastWorkId: workOrdrid,
              isWorkOrderType: "ParmaworkOrdridType",
              ParmaworkOrdrValue: "Parmavalue",
            });

            // this.selectHotelDropDown(hotelDetail,"ParmaworkOrdridType",workOrdrid,type);
          }
        }
        resolve();
      })
      .catch((error) => {
        reject();
      });
  }

  getrooms = () => {
    let hotelID = Number(this.state.hidValue);
    WorkOrder.GetFacilityRooms(hotelID)
      .then(async (result: any | null) => {
        let DayApproveData: any = [];

        if (result != null && result.length > 0) {
          let Data = result.map((x) => {
            return { id: x?.roomID, name: x?.roomName };
          });

          this.setState({
            locationTypesList: Data,
          });
          this.setState({ locationSearchTypesList: [] }, () => {
            this.setState({
              locationSearchTypesList: [{ id: -1, name: "All" }, ...Data],
            });
          });
        } else {
          this.setState({
            locationTypesList: [],
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  };

  GetFacilityAsset = () => {
    let hotelID = Number(this.state.hidValue);
    WorkOrder.GetFacilityAsset(hotelID)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let Data = result.map((x) => {
            return {
              id: x?.roomID,
              name: x?.roomName,
              locationID: x?.locationID,
            };
          });
          this.setState({ assetTypesList: Data });
          // this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }, ...Data] });
        } else {
          //this.setState({ assetTypesList: [{ id: 0, name: "Select an asset" }] });
          this.setState({ assetTypesList: [] });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  };

  getAssignUserList() {
    let request = {} as IWorkOrderRequestDto;
    request.hotelID = Number(this.state.hidValue);
    request.type = "AssignUsers";
    WorkOrder.GetFcUserDetailsList(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let Data = result.map((x) => {
            return { id: x?.user_UniqueID, name: x?.employee };
          });

          this.setState({ assignToList: [] }, () => {
            this.setState({ assignToList: [{ id: -1, name: "All" }, ...Data] });
            this.setState({ assignToListRightPanel: Data });
            this.setState({
              assignToListToChild: [
                { id: -1, name: "Select a technician" },
                ...Data,
              ],
            });
          });
        } else {
          this.setState({
            assignToList: [{ id: -1, name: "All" }],
            assignToListToChild: [
              {
                id: -1,
                name: "Select a technician",
                assignToListRightPanel: [],
              },
            ],
          });
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  }
  priorityListChange = () => {
    let priorityListData = this.state.priorityList;
    this.setState({ priorityList: [] }, () => {
      this.setState({ priorityList: priorityListData });
    });
  };
  statusListChange = () => {
    let workOrderStatus = this.state.workOrderStatus;
    this.setState({ workOrderStatus: [] }, () => {
      this.setState({ workOrderStatus: workOrderStatus });
    });
  };
  tempRoomListChange = () => {
    let locationSearchTypesList = this.state.locationSearchTypesList;
    this.setState({ locationSearchTypesList: [] }, () => {
      this.setState({ locationSearchTypesList: locationSearchTypesList });
    });
  };
  tempAssignUserList = () => {
    let assignToList = this.state.assignToList;
    this.setState({ assignToList: [] }, () => {
      this.setState({ assignToList: assignToList });
    });
  };

  selectHotelDropDown = (
    hotelDetails: any,
    ParmaworkOrdridType: any,
    workOrdrid: any,
    type: any
  ) => {
    this.setState({ pageLoader: true });
    if (this.state.isWorkOrderDetailsChange) {
      this.setState({ eventName: "selectHotelDropDown" });

      hotelDetailsData = hotelDetails;
      this.isValidateModelPoupTab();
    } else {
      this.childHID?.current?.updateChildState(hotelDetails);
      hotelDetailsData = null;
      this.setState(
        {
          hotelName: hotelDetails.lettercode,
          hidValue: hotelDetails.hotelID,
          isSlideOut: false,
          isDayDropDown: false,
          eventName: "",
        },
        () => {
          this.getAssignUserList();
          this.priorityListChange();
          this.getrooms();
          this.GetFacilityAsset();
          debugger;
          if (this.state.ParmaworkOrdrValue === "Parmavalue") {
            debugger;
            this.GetWorkOrdeStatus(hotelDetails.hotelID, this.state.lastWorkId);
          } else {
            if (this.state.isDayDropDown) {
              this.getWorkOrderSummaryDetails("completed", "");
            } else {
              this.getWorkOrderSummaryDetails("notcompleted", "");
            }
          }
        }
      );
    }
  };

  getWorkOrderSummaryDetails(type: any, filtertype: any) {
    this.setState({ isworkLoader: true });
    let request = {} as IWorkOrderSummaryRequestDto;
    request.hotelID = Number(this.state.hidValue);
    request.strtype = type;
    request.pastDays = "";
    if (type === "completed") {
      if (this.state.isDateFilter === "Last 7 Days") {
        request.pastDays = "7";
      } else {
        request.pastDays = "30";
      }
    }

    WorkOrder.getWorkOrderSummaryDetails(request)
      .then(async (result: any | null) => {
        if (result != null && result.length > 0) {
          let isPermission = result[0].isPermission;
          this.setState(
            {
              tableData: result,
              tableDataFilter: result,
              sortingOrder: "",
              sortingField: "",
              isPermission: isPermission,
            },
            () => {
              // if(filtertype==="addedFilter"){
              //   this.FilterByDropDownList(this.state.searchAssignUserId, this.state.searchPririty, "completedToggleOn");
              // }
              let source = this.getURLParamsValues()?.source;
              let status = this.getURLParamsValues()?.status;

              if (source == "overview") {
                if (status == "Open") {
                  let workOrderSummery: any[] = [];
                  workOrderSummery = _.cloneDeep(this.state.tableDataFilter);
                  workOrderSummery = workOrderSummery.filter((item) =>
                    ["Open", "In Progress", "On Hold"].includes(item.status)
                  );
                  this.setState({ tableData: workOrderSummery });
                } else {
                  let workOrderSummery: any[] = [];
                  workOrderSummery = _.cloneDeep(this.state.tableDataFilter);
                  workOrderSummery = workOrderSummery.filter(
                    (item) =>
                      ["Open", "In Progress", "On Hold"].includes(
                        item.status
                      ) && moment(item.dueDate).isBefore(moment(), "day")
                  );
                  this.setState({ tableData: workOrderSummery });
                }
              }

              if (this.state.isWorkOrderType == "addWorkOrder") {
                this.onRowClick(
                  "",
                  "row",
                  "",
                  "addWorkOrder",
                  Number(this.state.lastWorkId)
                );
              }
              if (this.state.isWorkOrderType == "completed") {
                this.onRowClick(
                  "",
                  "row",
                  "",
                  "completed",
                  Number(this.state.lastWorkId)
                );
              }
              if (this.state.isWorkOrderType == "NotCompletedStatus") {
                this.onRowClick(
                  "",
                  "row",
                  "",
                  "NotCompletedStatus",
                  Number(this.state.lastWorkId)
                );
              }
              if (this.state.isWorkOrderType == "FinalSave") {
                this.onRowClick(
                  "",
                  "row",
                  "",
                  "FinalSave",
                  Number(this.state.lastWorkId)
                );
              }
              if (this.state.isWorkOrderType == "ParmaworkOrdridType") {
                this.onRowClick(
                  "",
                  "row",
                  "",
                  "ParmaworkOrdridType",
                  Number(this.state.lastWorkId)
                );
              }
            }
          );
        } else {
          this.setState(
            {
              tableData: [],
              tableDataFilter: [],
              lastWorkId: 0,
              pramworkOrdrid: "",
            },
            () => {
              if (this.wororderSlideOut.current !== null) {
                this.wororderSlideOut.current.closeSlideOut();
              }
            }
          );
        }
        setTimeout(() => {
          this.CleanSearch();
          // this.cheackboxCheckedInFilter();
        }, 200);

        this.setState({ isworkLoader: false });
        if (this.state.completedTop == "") {
          //setTimeout(() => {
          this.workOrderRef.current?.childNodes[0].scrollTo({
            top: this.state.scrollPos,
            behavior: "smooth",
          });
        } else {
          this.setState({ scrollPos: 0 });
        }
        //}, 1000);

        // setTimeout(() => {
        //   scrollPos
        //   this.workOrderRef.current.childNodes[0].childNodes[0].childNodes[1]?.childNodes[0].getElementsByClassName('table-Row-Selected')[0]?.focus();
        // }, 100);
        resolve();
      })
      .catch((err) => {
        this.setState({ isworkLoader: false });
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  }

  GetWorkOrderPermission = () => {
    let request = {} as IWorkOrderPermissionRequestDto;
    WorkOrder.GetWorkOrderPermission(request)
      .then(async (result: any | null) => {
        let DayApproveData: any = [];
        if (result != null && result.length > 0) {
          console.log("result", result[0].permission);
          // console.log();

          if (result[0].permission === "Yes") {
            this.setState({ workorderPermission: "Yes" });
          } else {
            this.setState({ workorderPermission: "No" });
          }
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  };

  addWorkOrder = () => {
    debugger;
    if (!this.state.isWorkOrderDetailsChange) {
      this.setState({ showAddWorkOrderModal: true, eventName: "" });
      this.closeWorkSlideOut();
    } else {
      this.setState({ eventName: "addWorkOrder" }, () => {
        this.isValidateModelPoupTab();
      });
    }
  };
  isRequestSave = (lastId: any, modalvalue: any) => {
    this.setState(
      {
        showAddWorkOrderModal: modalvalue,
        isRefresh: true,
        lastWorkId: lastId,
        isWorkOrderType: "addWorkOrder",
        searchStatus: "",
        searchLocationId: -1,
        searchPririty: "",
        searchAssignUserId: -1,
      },
      () => {
        if (this.state.isDayDropDown) {
          this.getWorkOrderSummaryDetails("completed", "");
        } else {
          this.getWorkOrderSummaryDetails("notcompleted", "");
        }
      }
    );
  };
  iscloseModulePopup = () => {
    this.setState({ showAddWorkOrderModal: false });
  };

  handleSortByColumn = (field) => {
    this.setState(
      {
        sortingField: field,
        sortingOrder: this.state.sortingOrder === "asc" ? "desc" : "asc",
      },
      () => {
        this.sortTableData(this.state.tableData, this.state.sortingField);
      }
    );
  };

  sortTableData = (data: any, fieldName) => {
    if (fieldName === "locationName") {
      if (this.state.sortingOrder === "asc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [
            fieldName === "locationName" ? "locationName" : fieldName,
            "locationName",
          ],
          ["asc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [
            fieldName === "locationName" ? "locationName" : fieldName,
            "locationName",
          ],
          ["asc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      } else if (this.state.sortingOrder === "desc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [
            fieldName === "locationName" ? "locationName" : fieldName,
            "locationName",
          ],
          ["desc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [
            fieldName === "locationName" ? "locationName" : fieldName,
            "locationName",
          ],
          ["desc", "desc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      }
    }
    if (fieldName === "status") {
      if (this.state.sortingOrder === "asc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [fieldName === "status" ? "status" : fieldName, "status"],
          ["asc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [fieldName === "status" ? "status" : fieldName, "status"],
          ["asc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      } else if (this.state.sortingOrder === "desc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [fieldName === "status" ? "status" : fieldName, "status"],
          ["desc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [fieldName === "status" ? "status" : fieldName, "status"],
          ["desc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      }
    }
    if (fieldName === "dueDate") {
      if (this.state.sortingOrder === "asc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [fieldName === "dueDate" ? "dueDate" : fieldName, "dueDate"],
          ["asc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [fieldName === "dueDate" ? "dueDate" : fieldName, "dueDate"],
          ["asc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      } else if (this.state.sortingOrder === "desc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [fieldName === "dueDate" ? "dueDate" : fieldName, "dueDate"],
          ["desc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [fieldName === "dueDate" ? "dueDate" : fieldName, "dueDate"],
          ["desc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      }
    }
    if (fieldName === "priorityType") {
      if (this.state.sortingOrder === "asc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [
            fieldName === "priorityType" ? "priorityType" : fieldName,
            "priorityType",
          ],
          ["asc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [
            fieldName === "priorityType" ? "priorityType" : fieldName,
            "priorityType",
          ],
          ["asc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      } else if (this.state.sortingOrder === "desc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [
            fieldName === "priorityType" ? "priorityType" : fieldName,
            "priorityType",
          ],
          ["desc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [
            fieldName === "priorityType" ? "priorityType" : fieldName,
            "priorityType",
          ],
          ["desc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      }
    }

    if (fieldName === "username") {
      if (this.state.sortingOrder === "asc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [fieldName === "username" ? "username" : fieldName, "username"],
          ["asc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [fieldName === "username" ? "username" : fieldName, "username"],
          ["asc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      } else if (this.state.sortingOrder === "desc") {
        let tempNotcompleted = data.filter((o) => o.status !== "Completed");
        let tempcompleted = data.filter((o) => o.status == "Completed");
        tempNotcompleted = _.orderBy(
          tempNotcompleted,
          [fieldName === "username" ? "username" : fieldName, "username"],
          ["desc", "asc"]
        );
        tempcompleted = _.orderBy(
          tempcompleted,
          [fieldName === "username" ? "username" : fieldName, "username"],
          ["desc", "asc"]
        );
        data = tempNotcompleted;
        if (tempcompleted.length > 0) {
          tempcompleted.map((item: any) => {
            return data.push(item);
          });
        }
      }
    }
    this.setState({ tableData: data });
  };

  sortImage = (order) => {
    if (!order)
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "asc")
      return (
        <span className="ascending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    else if (order === "desc")
      return (
        <span className="descending">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4712 8.47133L11.5286 7.52867L8.66656 10.3907V4H7.33323V10.3907L4.47123 7.52867L3.52856 8.47133L7.9999 12.9427L12.4712 8.47133Z"
              fill="#1D1E20"
            />
          </svg>
        </span>
      );
    return null;
  };

  onRowClick = (
    e: any,
    row: any,
    rowIndex: any,
    type: any,
    lastworkOrderID: any
  ) => {
    debugger;
    if (lastworkOrderID == "" && this.state.completedTop === "") {
      let currentScrollPos = Math.floor(
        this.workOrderRef.current?.childNodes[0].scrollTop
      );
      this.setState({ scrollPos: currentScrollPos }, () => {
        this.setState({ scrollPos: currentScrollPos });
      });
    }

    // if(!this.state.isWorkOrderDetailsChange)
    // {
    if (type === "addWorkOrder") {
      let data = this.state.tableData;
      let row1 = data.filter((item) => item.workOrderID === lastworkOrderID);
      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].seletedColor = "";
      }
      if (row1.length > 0) {
        row1[0].seletedColor = "yes";
        this.setState(
          { tableData: data, isSlideOut: false, isRefresh: false },
          () => {
            this.setState({
              lastWorkId: "",
              isSlideOut: true,
              parentRowData: row1[0],
            });
          }
        );
      }
    }
    if (type === "table") {
      let data = [...this.state.tableData];
      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].seletedColor = "";
      }
      let index = data.indexOf(data[rowIndex]);
      data[index].seletedColor = "yes";
      this.setState({ tableData: data, isSlideOut: false }, () => {
        this.setState({ isSlideOut: true, parentRowData: row });
      });
    }

    if (type === "completed") {
      let data = this.state.tableData;
      let row1 = data.filter((item) => item.workOrderID === lastworkOrderID);
      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].seletedColor = "";
      }

      if (row1.length > 0) {
        row1[0].seletedColor = "yes";
        this.setState({ tableData: data, isSlideOut: false }, () => {
          let row1 = data.filter(
            (item) =>
              item.status !== "Completed" &&
              item.workOrderID === lastworkOrderID
          );
          if (row1.length > 0) {
            this.setState(
              { lastWorkId: "", isSlideOut: true, parentRowData: row1[0] },
              () => {
                if (this.wororderSlideOut.current !== null) {
                  this.wororderSlideOut.current.enableutton();
                }
              }
            );
          } else {
            this.setState({ lastWorkId: "" });
          }
        });
      }
    }

    if (type === "NotCompletedStatus") {
      let data = this.state.tableData;
      let row1 = data.filter((item) => item.workOrderID === lastworkOrderID);
      if (row1.length > 0) {
        row1[0].seletedColor = "yes";
        this.setState({ tableData: data, isSlideOut: false }, () => {
          this.setState(
            { lastWorkId: "", isSlideOut: true, parentRowData: row1[0] },
            () => {
              if (this.wororderSlideOut.current !== null) {
                this.wororderSlideOut.current.enableutton();
              }
            }
          );
        });
      }
    }
    if (type === "FinalSave") {
      let data = this.state.tableData;
      let row1 = data.filter((item) => item.workOrderID === lastworkOrderID);
      if (row1.length > 0) {
        row1[0].seletedColor = "yes";
        this.setState({ tableData: data, isSlideOut: false }, () => {
          this.setState({
            lastWorkId: "",
            isSlideOut: true,
            parentRowData: row1[0],
          });
        });
      }
    }
    if (type === "ParmaworkOrdridType") {
      let data = this.state.tableData;
      let row1 = data.filter((item) => item.workOrderID === lastworkOrderID);
      if (row1.length > 0) {
        row1[0].seletedColor = "yes";
        this.setState({ tableData: data, isSlideOut: false }, () => {
          this.setState({
            lastWorkId: "",
            pramworkOrdrid: "",
            isSlideOut: true,
            parentRowData: row1[0],
          });
        });
      }
    }
  };

  closeWorkSlideOut = () => {
    let data = this.state.tableData;
    for (let i = 0; i < data.length; i++) {
      let index = data.indexOf(data[i]);
      data[index].seletedColor = "";
    }
    this.setState({
      tableData: data,
      isSlideOut: false,
      isWorkOrderDetailsChange: false,
    });
  };

  onRowSelect = (
    selectedRow: any,
    isSelect: boolean,
    rowIndex: any,
    e: any
  ) => {
    debugger;
    let data = this.state.tableData;
    let selectedRows = this.state.selectedRows;
    let preSelectedRows = this.state.preSelectedRows;
    let completedCheakox = this.state.completedCheakox;
    if (isSelect === true) {
      selectedRows.push(selectedRow);
      preSelectedRows.push(selectedRow.workOrderID);
      completedCheakox.push(selectedRow);

      let index = data.indexOf(data[rowIndex]);
      data[index].hoverOutAction = "selected";
      data[index].flyOutAction = "rowSeclected";

      // for (let i = 0; i < data.length; i++) {
      //   let index = data.indexOf(data[i]);
      //   data[index].hoverOutAction = "selected";
      //   //data[index].flyOutAction = "rowSeclected";
      // }

      this.setState({ tableData: data });

      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
        completedCheakox: completedCheakox,
      });
    } else {
      let indexOf = selectedRows.indexOf(selectedRow);
      selectedRows.splice(indexOf, 1);
      preSelectedRows.splice(indexOf, 1);
      completedCheakox.splice(indexOf, 1);

      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].hoverOutAction = "";
        data[index].flyOutAction = "";
      }
      this.setState({ tableData: data });
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
        completedCheakox: completedCheakox,
      });
    }
  };

  onRowSelectAll = (isSelect: any, rows: any, e: any) => {
    debugger;
    let data = this.state.tableData;
    if (isSelect === true) {
      let tempSelectedRows: any = [];
      let selectedRows = this.state.selectedRows;
      let preSelectedRows = this.state.preSelectedRows;
      let completedCheakox = this.state.completedCheakox;
      selectedRows = tempSelectedRows;
      for (let i = 0; i < rows.length; i++) {
        selectedRows.push(rows[i]);
        preSelectedRows.push(rows[i].workOrderID);
        let index = data.indexOf(rows[i]);
        data[index].hoverOutAction = "selected";
        data[index].flyOutAction = "rowSeclected";
        if (rows[i].status !== "Completed") {
          completedCheakox.push(rows[i]);
        }
      }

      this.setState({ tableData: data });
      this.setState({
        selectedRows: selectedRows,
        preSelectedRows: preSelectedRows,
        completedCheakox: completedCheakox,
      });
    } else {
      this.setState({
        multipleRowSelected: false,
        selectedRows: [],
        preSelectedRows: [],
        completedCheakox: [],
      });

      for (let i = 0; i < data.length; i++) {
        let index = data.indexOf(data[i]);
        data[index].hoverOutAction = "";
        data[index].flyOutAction = "";
      }
      this.setState({ tableData: data });
    }
  };

  handleSelectedItem = (controlID: any, id: any) => {
    debugger;
    if (controlID === 1) {
      this.setState({
        searchPririty: id,
        isSlideOut: false,
        sortingOrder: "",
        sortingField: "",
      });
      this.FilterByDropDownList(
        this.state.searchAssignUserId,
        id,
        this.state.searchStatus,
        this.state.searchLocationId
      );
    }
    if (controlID === 2) {
      this.setState({
        searchAssignUserId: id,
        isSlideOut: false,
        sortingOrder: "",
        sortingField: "",
      });
      this.FilterByDropDownList(
        id,
        this.state.searchPririty,
        this.state.searchStatus,
        this.state.searchLocationId
      );
    }
    if (controlID === 3) {
      this.setState({
        searchStatus: id,
        isSlideOut: false,
        sortingOrder: "",
        sortingField: "",
      });
      this.FilterByDropDownList(
        this.state.searchAssignUserId,
        this.state.searchPririty,
        id,
        this.state.searchLocationId
      );
    }
    if (controlID === 4) {
      this.setState({
        searchLocationId: id,
        isSlideOut: false,
        sortingOrder: "",
        sortingField: "",
      });
      this.FilterByDropDownList(
        this.state.searchAssignUserId,
        this.state.searchPririty,
        this.state.searchStatus,
        id
      );
    }
  };

  FilterByDropDownList(
    userId: number,
    pryrity: string,
    status: string,
    locationId: number
  ) {
    let workOrderSummery: any[] = [];
    workOrderSummery = _.cloneDeep(this.state.tableDataFilter);

    if (userId > 0) {
      workOrderSummery = workOrderSummery.filter(
        (item) => item.user_UniqueID === userId
      );
    }
    if (pryrity && pryrity !== "All") {
      workOrderSummery = workOrderSummery.filter(
        (item) => item.priorityType === pryrity
      );
    }
    if (status && status !== "All") {
      workOrderSummery = workOrderSummery.filter(
        (item) => item.status === status
      );
    }
    if (locationId > 0) {
      workOrderSummery = workOrderSummery.filter(
        (item) => Number(item.locationID) === Number(locationId)
      );
    }

    this.setState({
      tableData: workOrderSummery,
      selectedRows: [],
      preSelectedRows: [],
      completedCheakox: [],
    });
  }

  cheackboxCheckedInFilter = () => {
    debugger;
    let data: any[] = [];
    data = _.cloneDeep(this.state.tableData);

    for (let i = 0; i < data.length; i++) {
      let index = data.indexOf(data[i]);
      data[index].hoverOutAction = "";
      data[index].flyOutAction = "";
    }
    this.setState(
      {
        tableData: [],
        selectedRows: [],
        preSelectedRows: [],
        completedCheakox: [],
      },
      () => {
        this.setState({ tableData: data });
      }
    );
  };

  handleDropdownDataChange = (id: any, type: any) => {
    this.setState(
      {
        isDateFilter: type,
        sortingOrder: "",
        sortingField: "",
        searchStatus: "",
        searchLocationId: -1,
        searchPririty: "",
        searchAssignUserId: -1,
      },
      () => {
        this.priorityListChange();
        this.statusListChange();
        this.tempRoomListChange();
        this.tempAssignUserList();
        this.sortImage("");
        this.getWorkOrderSummaryDetails("completed", "");
      }
    );
  };

  completedOnChange(
    e: any,
    type: string,
    isBlur = false,
    isInput = false,
    isChange = false
  ) {
    this.setState(
      {
        isDayDropDown: e.currentTarget.checked,
        isDateFilter: "Last 7 Days",
        selectedRows: [],
        preSelectedRows: [],
        lastWorkId: "",
        sortingOrder: "",
        sortingField: "",
        pramworkOrdrid: "",
        searchStatus: "",
        searchLocationId: -1,
        searchPririty: "",
        searchAssignUserId: -1,
      },
      () => {
        if (this.wororderSlideOut.current !== null) {
          this.wororderSlideOut.current.closeSlideOut();
        }
        // this.getAssignUserList();
        // this.priorityListChange();
        // this.getrooms();
        // this.statusListChange();
        this.priorityListChange();
        this.statusListChange();
        this.tempRoomListChange();
        this.tempAssignUserList();
        this.sortImage("");
        if (this.state.isDayDropDown) {
          this.getWorkOrderSummaryDetails("completed", "addedFilter");
        } else {
          this.getWorkOrderSummaryDetails("notcompleted", "addedFilter");
        }
      }
    );
  }

  cancleClick = () => {
    let data = this.state.tableData;
    this.setState({
      multipleRowSelected: false,
      selectedRows: [],
      preSelectedRows: [],
      completedCheakox: [],
    });

    for (let i = 0; i < data.length; i++) {
      let index = data.indexOf(data[i]);
      data[index].hoverOutAction = "";
      data[index].flyOutAction = "";
    }
    this.setState({ tableData: data });
  };

  deletedWorkOrder = () => {
    this.isdeletedWorkOrderConfirmPop();
  };

  isdeletedWorkOrderConfirmPop() {
    confirmAlert({
      // title: "Unsaved confirmation",
      message:
        "This will permanently delete the selected work orders. Are you sure you want to continue?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.DeletedWorkOrderDetails(),
        },
        {
          label: "No",
          onClick: () => reject(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  DeletedWorkOrderDetails = () => {
    let data = this.state.selectedRows;
    let storage = JSON.parse(localStorage.getItem("storage")!);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);
    let deleteWorkOrder: any[] = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== "Completed") {
        let newItem: any = {};
        newItem.hotelID = Number(this.state.hidValue);
        newItem.tenantID = tenantID;
        newItem.workOrderID = data[i].workOrderID;
        deleteWorkOrder.push(newItem);
      }
    }

    WorkOrder.DeleteWorkOrderSummaryDetails(deleteWorkOrder)
      .then(async (result: any | null) => {
        if (result != null && result.result != null) {
          if (result.result.messageCode === "Success") {
            toast.success(result.result.message, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          this.setState({
            selectedRows: [],
            preSelectedRows: [],
            isRefresh: true,
            completedCheakox: [],
          });
          if (this.state.isDayDropDown) {
            this.getWorkOrderSummaryDetails("completed", "");
          } else {
            this.getWorkOrderSummaryDetails("notcompleted", "");
          }
        }
        resolve();
      })
      .catch((err) => {
        Utils.toastError(`Server Error, ${err}`, {});

        reject();
      });
  };
  CleanSearch = () => {
    if (this.tableSearchnew.current !== null)
      this.tableSearchnew.current.CleanSearch();
  };

  workOrderStatusUpdate = (type: any, workOrderID: any) => {
    let data = [...this.state.tableData];
    let completedTop = "";
    // let filterByWorkId = data.filter((item) => Number(item.workOrderID) === Number(workOrderID));
    //let filterByWorkId = data.filter((item) => Number(item.rowID) === Number(workOrderID));
    let index = data.findIndex(
      (item) => Number(item.rowID) === Number(workOrderID)
    );
    if (type === "Completed") {
      let findPulusInd = data.indexOf(data[index + 1]);
      //let findMinusInd=data.indexOf(data[index-1]);

      if (findPulusInd !== -1 && data[index + 1].status !== "Completed") {
        workOrderID = data[index + 1].workOrderID;
      } else {
        let index = data.indexOf(data[0]);
        if (index !== -1 && data[0].status !== "Completed") {
          workOrderID = data[0].workOrderID;
        }
        this.workOrderRef.current?.childNodes[0].scrollTo({
          top: 0,
          behavior: "smooth",
        });
        completedTop = "TopRow";
      }

      this.setState(
        {
          isWorkOrderType: "completed",
          lastWorkId: workOrderID,
          completedTop: completedTop,
          completedCheakox: [],
          selectedRows: [],
          preSelectedRows: [],
        },
        () => {
          if (this.state.isDayDropDown) {
            this.getWorkOrderSummaryDetails("completed", "");
          } else {
            this.getWorkOrderSummaryDetails("notcompleted", "");
          }
        }
      );
    } else {
      if (index !== undefined) {
        let filterByWorkId = data.filter(
          (item) => Number(item.rowID) === Number(workOrderID)
        );
        this.setState(
          {
            isWorkOrderType: "NotCompletedStatus",
            lastWorkId: filterByWorkId[0]?.workOrderID,
            completedCheakox: [],
            selectedRows: [],
            preSelectedRows: [],
          },
          () => {
            if (this.state.isDayDropDown) {
              this.getWorkOrderSummaryDetails("completed", "");
            } else {
              this.getWorkOrderSummaryDetails("notcompleted", "");
            }
          }
        );
      }
    }
    this.priorityListChange();
    this.statusListChange();
    this.tempRoomListChange();
    this.tempAssignUserList();

    this.setState({
      tableData: data,
      searchStatus: "",
      searchPririty: "",
      searchLocationId: -1,
      searchAssignUserId: -1,
    });
  };

  changesOfSlideOut = (ChildeState: any) => {
    this.setState({ isWorkOrderDetailsChange: ChildeState }, () => {
      if (this.state.eventName === "addWorkOrder") {
        this.addWorkOrder();
      }
      if (this.state.eventName === "selectHotelDropDown") {
        this.selectHotelDropDown(hotelDetailsData, "", "", "");
      }
    });
  };

  refeshTable = (workOrderID) => {
    this.setState(
      { isWorkOrderType: "NotCompletedStatus", lastWorkId: workOrderID },
      () => {
        if (this.state.isDayDropDown) {
          this.getWorkOrderSummaryDetails("completed", "");
        } else {
          this.getWorkOrderSummaryDetails("notcompleted", "");
        }
      }
    );
  };

  isValidateModelPoupTab() {
    confirmAlert({
      title: "Unsaved confirmation",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.isChangeUnsaved(),
        },
        {
          label: "No",
          onClick: () => this.NotChanges(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }

  isChangeUnsaved = () => {
    if (this.wororderSlideOut.current !== null) {
      this.wororderSlideOut.current.disccardChanges();
    }
  };
  NotChanges = () => {
    this.setState({ eventName: "" });
    reject();
  };

  render() {
    const autoPunchOutSelectRow = {
      mode: "checkbox",
      classes: "selection-row",
      selected: this.state.preSelectedRows,
      clickToExpand: true,
      onSelect: (
        selectedRow: any,
        isSelect: boolean,
        rowIndex: any,
        e: any
      ) => {
        this.onRowSelect(selectedRow, isSelect, rowIndex, e);
        // this.setState((prev) => ({isRowSelected: !prev.isRowSelected}))
      },
      onSelectAll: (isSelect: boolean, rows: any, e: any) => {
        this.onRowSelectAll(isSelect, rows, e);
      },
    };

    const tableRowEvents = {
      onClick: (e, row, rowIndex) => {
        this.onRowClick(e, row, rowIndex, "table", "");
      },
    };

    const rowClasses = (row: any, rowIndex: any) => {
      if (row.isPermission === "no") {
        return "row-disabled";
      }

      if (row.status.toLowerCase() === "completed") {
        if (
          row.seletedColor === "yes" &&
          row.status.toLowerCase() === "completed"
        ) {
          return "work-order-completed table-Row-Selected";
        } else {
          return "work-order-completed";
        }
      }

      if (row.seletedColor === "yes") {
        if (this.state.isWorkOrderDetailsChange) {
          return "row-disabled table-Row-Selected";
        } else {
          return "table-Row-Selected";
        }
      } else {
        if (this.state.isWorkOrderDetailsChange) {
          return "row-disabled";
        }
      }
    };

    const columns = [
      {
        dataField: "name",
        text: "Name",
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                <div className="work-name"> {row.name}</div>
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "locationName",
        searchable: false,
        text: "Location",
        headerFormatter: (column, colIndex) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "locationName" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("locationName")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip placement="bottom">
                {row.locationName}
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "status",
        searchable: false,
        text: "Status",
        headerFormatter: (column, colIndex) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "status" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("status")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip>
                <div
                  className={`badge ${
                    row.status.toLowerCase() === "on hold"
                      ? "hold-badge"
                      : row.status.toLowerCase() === "in progress"
                      ? "progress-badge"
                      : row.status.toLowerCase() === "completed"
                      ? "completed"
                      : ""
                  }`}
                >
                  {row.status}
                </div>
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "dueDate",
        searchable: false,
        text: "Due Date",
        headerFormatter: (column, colIndex) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "dueDate" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("dueDate")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip>
                <div className={row.pastdate == "yes" ? "past-date" : ""}>
                  {row.dueDate}
                </div>
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "priorityType",
        searchable: false,
        text: "Priority",
        headerFormatter: (column, colIndex) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "priorityType" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("priorityType")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              <EllipsisWithTooltip>
                <div className={`priority ${row.priorityType.toLowerCase()}`}>
                  {row.priorityType}
                </div>
              </EllipsisWithTooltip>
            </>
          );
        },
      },
      {
        dataField: "username",
        searchable: false,
        text: "Assigned To",
        headerFormatter: (column, colIndex) => {
          return (
            <div
              className={`d-inline-block ${
                this.state.sortingField === "username" ? "active" : ""
              }`}
              onClick={() => this.handleSortByColumn("username")}
            >
              {column.text}
              {this.sortImage(this.state.sortingOrder)}
            </div>
          );
        },
        formatter: (cell: any, row: any, rowIndex: any) => {
          return (
            <>
              {row?.smileIdUrl ? (
                <div className="actual-profile">
                  <div>
                    <img
                      src={row?.smileIdUrl}
                      className="profileimg"
                      alt="Profile"
                    />
                  </div>
                  <span>
                    {" "}
                    <EllipsisWithTooltip>{row.username}</EllipsisWithTooltip>
                  </span>
                </div>
              ) : (
                <div className="actual-profile">
                  {row.username ? (
                    <>
                      <div className="border">
                        <img src={profile} alt="user" className="m-0" />
                      </div>
                      <div className="user-name">
                        {" "}
                        <EllipsisWithTooltip placement="bottom">
                          {row.username}
                        </EllipsisWithTooltip>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </>
          );
        },
      },
    ];

    return (
      <>
        {this.state.showAddWorkOrderModal && (
          <AddWorkOrder
            hid={this.state.hidValue}
            // priorityList={this.state.priorityList}
            showAddWorkOrderModal={this.state.showAddWorkOrderModal}
            isRequestSave={this.isRequestSave}
            assignToList={this.state.assignToListToChild}
            iscloseModulePopup={this.iscloseModulePopup}
            locationTypesList={this.state.locationTypesList}
          />
        )}

        <div className="workorder-wrapper">
          <div
          //className={this.state.isWorkOrderDetailsChange ? "disable-page-controls":""}
          ></div>
          <Container fluid className="body-sec">
            <div className="page-heading underline d-flex">
              <HidSelector
                onClickEvent={this.selectHotelDropDown}
                ref={this.childHID}
                alertComponent={this.state.isWorkOrderDetailsChange}
                modulename={"Facility Maintenance"}
              />

              <div className="mr-auto d-flex align-items-center">
                Work Orders
              </div>

              {this.state.hidValue !== "Select" &&
                this.state.workorderPermission === "Yes" && (
                  <Button onClick={this.addWorkOrder}>New Work Order</Button>
                )}

              <Dropdown className="more-action ml-3">
                <DropdownToggle
                  className="btn-outline-primary btn btn-primary more"
                  id="dropdown-more"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                    <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                    <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                  </svg>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            {/* {this.state.pageLoader ? (
              <ReactPageLoader useas={"timesheetMain"} />
            ) : ( */}
            {this.state.hidValue !== "Select" && (
              <div className="main-Page">
                <div className="bdy-sec">
                  <ToolkitProvider
                    keyField="userUniqueno"
                    data={this.state.tableData}
                    columns={columns}
                    search
                    hover
                  >
                    {(props: {
                      searchProps: JSX.IntrinsicAttributes;
                      baseProps: JSX.IntrinsicAttributes;
                    }) => (
                      <div>
                        <div className="search-header d-flex my-4 position-relative">
                          <div className="schedule-main">
                            <div className="schedule-department-view-table mb-0">
                              <div className="departmentViewNavigation">
                                <div className="navigation-menus d-flex my-0 mr-4">
                                  <div className="schedule-table-search">
                                    <TableSearch
                                      id="tableSearch"
                                      key="tableSearch"
                                      ref={this.tableSearchnew}
                                      {...props.searchProps}
                                      //handleNewPosition={this.handleNewPosition.bind(this)}
                                      CleanSearch={this.CleanSearch.bind(this)}
                                      placeholder={"Search..."}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.isWorkOrderDetailsChange && (
                            <div className="disable-page-controls h-100"></div>
                          )}

                          <div className="dropdown-interface assigned-dropdown">
                            {this.state.assignToList.length > 0 && (
                              <SingleSearchDropdownList
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.assignToList}
                                handleSelectedItem={this.handleSelectedItem.bind(
                                  this,
                                  2
                                )}
                                defaultItem={this.state.assignToList}
                                defaultText={"Assigned To"}
                                defaultName={"Assigned To"}
                                controlID="2"
                              />
                            )}
                          </div>

                          <div className="dropdown-interface mx-2">
                            {this.state.priorityList.length > 0 && (
                              <SingleSearchDropdownList
                                // id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.priorityList}
                                handleSelectedItem={this.handleSelectedItem.bind(
                                  this,
                                  1
                                )}
                                allowSearch={false}
                                defaultItem={this.state.priorityList}
                                defaultText={"Search Status"}
                                defaultName={"Priority"}
                                controlID="1"
                              />
                            )}
                          </div>

                          <div className="dropdown-interface mx-2">
                            {this.state.workOrderStatus.length > 0 && (
                              <SingleSearchDropdownList
                                //id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.workOrderStatus}
                                handleSelectedItem={this.handleSelectedItem.bind(
                                  this,
                                  3
                                )}
                                allowSearch={false}
                                defaultItem={this.state.workOrderStatus}
                                defaultText={"Search Status"}
                                defaultName={
                                  this.state.searchStatus == ""
                                    ? "Status"
                                    : this.state.searchStatus
                                }
                                controlID="3"
                              />
                            )}
                          </div>

                          <div className="dropdown-interface mx-2">
                            {this.state.locationSearchTypesList.length > 0 && (
                              <SingleSearchDropdownList
                                // id={"tabletypeddl"}
                                isRefresh={this.state.isRefresh}
                                itemList={this.state.locationSearchTypesList}
                                handleSelectedItem={this.handleSelectedItem.bind(
                                  this,
                                  4
                                )}
                                defaultItem={this.state.locationSearchTypesList}
                                defaultText={"Search Location"}
                                defaultName={"Location"}
                                controlID="4"
                              />
                            )}
                          </div>

                          <div className="dropdown-ui">
                            {this.state.isDayDropDown && (
                              <Dropdown className="more-action days-dropdown">
                                <Dropdown.Toggle
                                  className="btn"
                                  id="dropdown-more"
                                >
                                  <span className="calender-icon">
                                    <svg
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 11.25H6.75V12.75H5.25V11.25ZM8.25 8.25H9.75V9.75H8.25V8.25ZM8.25 11.25H9.75V12.75H8.25V11.25ZM11.25 8.25H12.75V9.75H11.25V8.25ZM11.25 11.25H12.75V12.75H11.25V11.25Z"></path>
                                      <path d="M3.75 16.5H14.25C15.0773 16.5 15.75 15.8273 15.75 15V6V4.5C15.75 3.67275 15.0773 3 14.25 3H12.75V1.5H11.25V3H6.75V1.5H5.25V3H3.75C2.92275 3 2.25 3.67275 2.25 4.5V6V15C2.25 15.8273 2.92275 16.5 3.75 16.5ZM14.25 6L14.2507 15H3.75V6H14.25Z"></path>
                                    </svg>
                                  </span>
                                  <span>{this.state.isDateFilter}</span>
                                  <svg
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-width="2"
                                    viewBox="0 0 24 24"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.handleDropdownDataChange(
                                        7,
                                        "Last 7 Days"
                                      )
                                    }
                                  >
                                    Last 7 Days
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      this.handleDropdownDataChange(
                                        30,
                                        "Last 30 Days"
                                      )
                                    }
                                  >
                                    Last 30 Days
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>

                          <div className="ml-auto workorder-toogle">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label=""
                              checked={this.state.isDayDropDown}
                              onChange={(event: any) => {
                                this.completedOnChange(event, "Completed");
                              }}
                            />
                            <FormLabel className="mb-0">
                              Show Completed
                            </FormLabel>
                          </div>

                          <Dropdown
                            className="setting-dropdown ml-0 more-action"
                            alignRight
                          >
                            <Dropdown.Toggle className="" id="dropdown-more">
                              <div className="d-flex align-items-center">
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M0.562614 9.18059L1.89595 11.4873C2.07995 11.8059 2.48795 11.9153 2.80661 11.7306L3.73728 11.1933C4.12395 11.4979 4.55128 11.7479 5.00061 11.9346V12.9999C5.00061 13.3679 5.29861 13.6666 5.66728 13.6666H8.33395C8.70261 13.6666 9.00062 13.3679 9.00062 12.9999V11.9346C9.44995 11.7479 9.87661 11.4979 10.2639 11.1933L11.1946 11.7306C11.5126 11.9139 11.9219 11.8039 12.1053 11.4873L13.4386 9.18059C13.6219 8.86325 13.5119 8.45325 13.1953 8.26992L12.2806 7.74125C12.3153 7.49525 12.3333 7.24725 12.3333 6.99992C12.3333 6.75259 12.3153 6.50459 12.2793 6.25858L13.1939 5.72992C13.5113 5.54659 13.6213 5.13659 13.4373 4.81925L12.1039 2.51259C11.9199 2.19392 11.5119 2.08459 11.1933 2.26925L10.2626 2.80659C9.87595 2.50192 9.44928 2.25125 8.99995 2.06525V0.999919C8.99995 0.631919 8.70195 0.333252 8.33328 0.333252H5.66661C5.29795 0.333252 4.99995 0.631919 4.99995 0.999919V2.06525C4.55061 2.25192 4.12395 2.50192 3.73661 2.80659L2.80661 2.26925C2.48728 2.08459 2.07995 2.19392 1.89595 2.51259L0.562614 4.81925C0.379281 5.13659 0.489281 5.54659 0.805948 5.72992L1.72061 6.25858C1.68461 6.50459 1.66661 6.75259 1.66661 6.99992C1.66661 7.24725 1.68461 7.49525 1.72061 7.74125L0.805948 8.26992C0.488614 8.45325 0.378614 8.86325 0.562614 9.18059ZM6.99995 4.33325C8.47061 4.33325 9.66661 5.52925 9.66661 6.99992C9.66661 8.47059 8.47061 9.66659 6.99995 9.66659C5.52928 9.66659 4.33328 8.47059 4.33328 6.99992C4.33328 5.52925 5.52928 4.33325 6.99995 4.33325Z" />
                                </svg>
                                <div className="drop-arrow ml-0 d-flex">
                                  <FiChevronDown />
                                </div>
                              </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item></Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div
                          className="hasCursorPointer hide-header-scrollbar"
                          ref={this.workOrderRef}
                        >
                          {this.state.isworkLoader ? (
                            <ReactPageLoader useas="work-orders" />
                          ) : (
                            <BootstrapTable
                              id={"timesheet"}
                              {...props.baseProps}
                              keyField="workOrderID"
                              hover
                              selectRow={autoPunchOutSelectRow}
                              rowEvents={tableRowEvents}
                              rowClasses={rowClasses}
                              headerClasses={
                                this.state.isPermission === "no" ||
                                this.state.isWorkOrderDetailsChange
                                  ? "row-disabled"
                                  : ""
                              }
                            />
                          )}

                          {this.state.isSlideOut && (
                            <div>
                              <WorkOrderSlideOut
                                ref={this.wororderSlideOut}
                                closeWorkSlideOut={this.closeWorkSlideOut}
                                parentState={this.state}
                                parentRowData={this.state.parentRowData}
                                workOrderStatusUpdate={
                                  this.workOrderStatusUpdate
                                }
                                changesOfSlideOut={this.changesOfSlideOut}
                                refeshTable={this.refeshTable}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </div>
            )}
            {/* )} */}

            {this.state.completedCheakox.length > 0 && (
              <div className="fixed-action">
                <div className="d-flex align-content-center flex-wrap forecast-action-strip">
                  <div className="mr-auto message">
                    <span>You selected one or more work orders</span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary btn-discard btn-changes-discard"
                    onClick={this.deletedWorkOrder}
                  >
                    Delete
                  </button>

                  <button
                    type="button"
                    className="save-btn btn btn-primary"
                    onClick={this.cancleClick}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </Container>
        </div>
      </>
    );
  }
}
