import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { Container, Dropdown, Tab, Nav } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import profile from "../../../Common/Assets/Images/user-no-image.svg";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import { General, IGeneralProps } from "./General";
import { Labor } from "./Labor";
import { Payroll } from "./Payroll";
import { Documents } from "./Documents";
import { Sales } from "./Sales";
import { UserManagement } from "../../../Common/Services/UserManagement";
import { IGeneralInfo } from "../../../Common/Contracts/IGeneralInfo";
import { UsersLogHistory } from "./UsersLogHistory";
import { IEnterPriseWisePayroll } from "../../../Common/Contracts/IEnterpriseWisePayroll";
import {
  IPageSubPermissionRole,
  RoleSubPermissionSetting,
} from "../../../Common/Services/RoleSubPermissionSetting";
import { IUserRole } from "../../../Common/Contracts/IUserRole";
import { Utils } from "../../../Common/Utilis";
import moment from "moment";
import _ from "lodash";

let selectedRolesItem: any = [];
export class UserDetailSlideOut extends React.Component<any, any> {
  private laborRef: any;
  private payrollRef: any;

  constructor(props: any) {
    super(props);
    this.laborRef = React.createRef();
    this.payrollRef = React.createRef();
    this.state = {
      activeTab: this.props?.selectedTab || "general",
      userGeneralInfo: {},
      salesTabCount: 0,
      isGeneralTabChangesNotSaved: false,
      isLaborTabChangesNotSaved: false,
      isPayrollTabChangesNotSaved: false,
      hasPerformanceAccess: false,
      hasSalesAccess: false,
      generalTabCount: 0,
      laborTabCount: 0,
      payRollTabCount: 0,
      controlPosition: 0,
      user: {
        FirstName: "",
        LastName: "",
        Email: "",
        PhoneNo: "",
        UserId: 0,
      },
      loader: false,
      enterpriseAccess: false,
      enableEnterpriseAccess: true,
      tabs: [
        { tab: "general", order: 1 },
        { tab: "Labor", order: 2 },
        { tab: "Payroll", order: 3 },
        { tab: "Documents", order: 4 },
      ],
      showChangeLogModal: false,
      tabPermissions: [],
      isTabPermissionsLoaded: false,
      enterPriseWisePayrollData: {},
      isEnterpriseWideDataLoaded: false,
      clientSetupData: {},
      isClientSetupDataLoaded: false,
      sectionNames: [],
      loginUserHotels: [],
      shouldReloadMainPage: false,
      isEditLaborandPayrollInfo: false,
      isEditDocumentInfo: false,
      isEditSalesInfo: false,
      isEditGeneralInfo: false,
      isChangeUserRole: false,
      isResetPassword: false,
      isChangeStatus: false,
      isSendWelcomeEmail: false,
      isChangeEHIDAccess: false,
      isUserRolesLoaded: false,

      hotelWideTableExpended:[],
      ptoTableExpended:[],
    
    };
  }

  componentDidMount() {
    this.getAddUserPermission();
    this.getManageUserPermission();
    this.getUserGeneralInfo();
    this.getEnterPriseWisePayrollData();
    this.getClientSetup();
    this.getUserRoleList();
  }

  getUserRoleList = () => {
    this.setState({ isUserRolesLoaded: false });
    UserManagement.GetUserRoleList().then((response: IUserRole[]) => {
      const userRoles = [
        ...response.map((item) => ({
          id: item.roleID,
          name: item.roleName,
          isLowSecurity: item.isLowSecurity,
        })),
      ];

      this.setState({ userRoles }, () =>
        this.setState({ isUserRolesLoaded: true })
      );
    });
  };

  getAddUserPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 57;
    requestObject.Module = "All";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        // 2213	Change E/HID Access
        // 2214	Change User Role
        // 2215	Change User Status
        // 2218	Create New User
        // 2229	Edit User Information
        // 2231	Payroll Deduction Settings
        // 2234	Reset Password
        // 2235	Role Permission
        // 2237	Training Setup
        //NEW PERMISSION
        // Edit General Information
        // Edit Labor and Payroll Data
        // Edit Documents
        // Edit Sales Information
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionName === "Create New User") {
              this.setState({ isPageCreateNewUser: true });
            }
            if (element.permissionName === "Edit General Information") {
              this.setState({
                isEditGeneralInfo: true,
                isChangeUserRole: true,
                isResetPassword: true,
                isChangeStatus: true,
                isSendWelcomeEmail: true,
                isChangeEHIDAccess: true,
              });
            }
            if (element.permissionName === "Edit Labor and Payroll Data") {
              this.setState({ isEditLaborandPayrollInfo: true });
            }
            if (element.permissionName === "Edit Documents") {
              this.setState({ isEditDocumentInfo: true });
            }
            if (element.permissionName === "Edit Sales Information") {
              this.setState({ isEditSalesInfo: true });
            }
          });
        } 
      })
      .catch((err: any) => {});
  };

  getManageUserPermission = () => {
    let requestObject = {} as IPageSubPermissionRole;
    requestObject.permissionID = 10084;
    requestObject.Module = "All";
    requestObject.Type = "page";
    RoleSubPermissionSetting.GetPageSubPermissionDetails(requestObject)
      .then(async (result: any | null) => {
        // 2213	Change E/HID Access
        // 2214	Change User Role
        // 2215	Change User Status
        // 2218	Create New User
        // 2229	Edit User Information
        // 2231	Payroll Deduction Settings
        // 2234	Reset Password
        // 2235	Role Permission
        // 2237	Training Setup
        if (result != null && result.length > 0) {
          result.forEach((element: any) => {
            if (element.permissionName === "Edit Labor and Payroll Data") {
              this.setState({ isEditLaborandPayrollInfo: true });
            }
          });
        }
      })
      .catch((err: any) => {});
  };

  getClientSetup = (isDefaultLoad = false) => {
    this.setState({ isClientSetupDataLoaded: false });
    UserManagement.GetClientSetup()
      .then((data: any) => {
        this.setState({ clientSetupData: data?.result[0] }, () => {
          this.setState({ isClientSetupDataLoaded: true });
        });
      })
      .catch(() => this.setState({ isClientSetupDataLoaded: true }));
  };

  checkIfOnlyAccountingAccess = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) =>
            item.result?.toString() !== "Accounting" &&
            item.result?.toString() !== "Accounting Services" &&
            item.result?.toString() !== "Segmentation"
        )?.length === 0;
    } else {
      return true;
    }
    return hasAccess;
  };

  checkifHasLaborModule = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) =>
            item.result?.toString() == "Labor Management" ||
            item.result?.toString() == "LaborManagement" ||
            item.result?.toString() == "Labor"
        )?.length > 0;
    } else {
      return false;
    }
    return hasAccess;
  };

  getEnterPriseWisePayrollData = (isDefaultLoad = false) => {
    this.setState({ isEnterpriseWideDataLoaded: false });
    UserManagement.GetEnterPriseWisePayroll(
      this.props.userData?.uniqueno,
      "",
      this.props?.isNewUserAdded
    )
      .then((enterPriseWisePayrollData: IEnterPriseWisePayroll) => {
        if (isDefaultLoad) {
          this.setState({
            prevEnterPriseWisePayrollData: enterPriseWisePayrollData,
          });
        }
        if (!enterPriseWisePayrollData?.ssn) {
          this.setState({ payRollTabCount: 1 });
        }
        this.setState({ enterPriseWisePayrollData });
      })
      .finally(() => this.setState({ isEnterpriseWideDataLoaded: true }));
  };

  getGeneralTabModificationStatus = (isGeneralTabChangesNotSaved = false) => {
    this.setState({ isGeneralTabChangesNotSaved });
  };

  getLaborTabModificationStatus = (isLaborTabChangesNotSaved = false) => {
    this.setState({ isLaborTabChangesNotSaved });
  };

  getPayrollTabModificationStatus = (isPayrollTabChangesNotSaved = false) => {
    this.setState({ isPayrollTabChangesNotSaved });
  };

  closeSlideOut = () => {
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };
  closeSlideOutWithConfirmation = () => {
    const {
      isGeneralTabChangesNotSaved,
      isLaborTabChangesNotSaved,
      isPayrollTabChangesNotSaved,
    } = this.state;

    if (
      isGeneralTabChangesNotSaved ||
      isLaborTabChangesNotSaved ||
      isPayrollTabChangesNotSaved
    ) {
      this.confirmationOnSlideoutClose();
      return;
    }
    this.props.closeSlideOut(this.state?.shouldReloadMainPage);
  };

  setTabSelection = (selectedTab) => {
    this.getUserGeneralInfo();
    if (selectedTab === "payroll") {
      this.getEnterPriseWisePayrollData();
    }

    this.setState({
      activeTab: selectedTab,
      isGeneralTabChangesNotSaved: false,
      isLaborTabChangesNotSaved: false,
      isPayrollTabChangesNotSaved: false,
    });
  };

  setTabSelectionFromGuide = (selectedTab) => {
    if (this.state.generalTabCount > 0) {
      if (selectedTab === "general") {
        Utils.toastError("You are on the same tab", {
          position: toast.POSITION.BOTTOM_RIGHT,
          containerId: "userSlideOut",
        });
        return false;
      }
    }

    this.setState({
      activeTab: selectedTab,
      isGeneralTabChangesNotSaved: false,
      isLaborTabChangesNotSaved: false,
      isPayrollTabChangesNotSaved: false,
    });
  };

  stayOnSameTab = () => {
    // const { activeTab } = this.state;
  };

  handleSalesTabCount = (salesTabCount) => {
    this.setState({ salesTabCount });
  };
  confirmationOnTabChange = (selectedTab) => {
    if (this.state.activeTab === "labor") {
      this.setState({ controlPosition: 20 });
    }
    if (this.state.activeTab === "payroll") {
      this.setState({ controlPosition: 30 });
    }

    confirmAlert({
      title: "Unsaved confirmation?",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.setTabSelection(selectedTab),
        },
        {
          label: "No",
          onClick: () => this.stayOnSameTab(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  confirmationOnSlideoutClose = () => {
    confirmAlert({
      title: "Unsaved confirmation?",
      message:
        "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.closeSlideOut(),
        },
        {
          label: "No",
          onClick: () => this.stayOnSameTab(),
        },
      ],
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  setActiveTab = (selectedTab) => {
    const {
      isGeneralTabChangesNotSaved,
      isLaborTabChangesNotSaved,
      isPayrollTabChangesNotSaved,
      activeTab,
    } = this.state;

    if (activeTab === selectedTab) {
      return;
    }
    if (selectedTab === "payroll") {
      this.getEnterPriseWisePayrollData();
      this.payrollRef?.current?.getHotelWisePayrollData();
    }

    if (selectedTab === "labor") {
      //  this.setState({userGeneralInfo:{}},()=>{
      // this.getUserLaborInfo();
      // });
      //this.laborRef?.current?.getHotelWisePayrollData();
    }

    if (
      selectedTab === "documents" &&
      this.state?.userGeneralInfo?.status === "Incomplete"
    ) {
      Utils.toastError("Please complete user to access Documents Tab", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
      return;
    }

    if (
      selectedTab === "labor" &&
      this.state?.userGeneralInfo?.status === "Incomplete" &&
      this.state?.generalTabCount > 0
    ) {
      Utils.toastError("Please complete General Tab to access Labor Tab", {
        position: toast.POSITION.BOTTOM_RIGHT,
        containerId: "userSlideOut",
      });
      return;
    }

    switch (activeTab) {
      case "general":
        if (isGeneralTabChangesNotSaved) {
          this.confirmationOnTabChange(selectedTab);
          return;
        }
        break;

      case "labor":
        if (isLaborTabChangesNotSaved) {
          this.setState({ controlPosition: 20 }, () =>
            this.setState({ controlPosition: 0 })
          );

          this.confirmationOnTabChange(selectedTab);

          return;
        }
        break;

      case "payroll":
        if (isPayrollTabChangesNotSaved) {
          this.setState({ controlPosition: 30 }, () =>
            this.setState({ controlPosition: 0 })
          );

          this.confirmationOnTabChange(selectedTab);

          return;
        }

        break;

      default:
        break;
    }

    this.setState({ activeTab: selectedTab });
  };

  removeHighlightedColor = (obj, textToSearch) => {
    if (obj.children.length > 0) {
      for (let child of obj.children) {
        this.removeHighlightedColor(child, textToSearch);
        obj.backColorClass = "";
      }
    } else {
      obj.backColorClass = "";
    }
  };

  getGeneralTabCount = (generalTabCount = 0) => {
    this.setState({
      generalTabCount,
    });
  };

  getLaborTabCount = (laborTabCount = 0) => {
    this.setState({ laborTabCount });
  };

  shouldReloadMainPage = (reloadMainPage = false) => {
    if (reloadMainPage) {
      this.setState({ shouldReloadMainPage: reloadMainPage });
    }
  };

  getUserGeneralInfo = (shouldReloadMainPage = false) => {
    if (shouldReloadMainPage) {
      this.setState({ shouldReloadMainPage });
    }

    UserManagement.GetUserGeneralInfo(
      this.props.userData?.user_UniqueID,
      this.props?.isNewUserAdded
    ).then((userGeneralInfo: IGeneralInfo) => {
      const hasPerformanceAccess =
        this.checkHasPerformanceAccess(userGeneralInfo);
      const hasSalesAccess = this.checkHasSalesAccess(userGeneralInfo);

      if (!userGeneralInfo.dob?.toString()?.includes("*")) {
        userGeneralInfo.dob =
          userGeneralInfo.dob && userGeneralInfo.dob !== "0001-01-01T00:00:00"
            ? moment(userGeneralInfo?.dob).format("MM/DD/YYYY")
            : "";
      }
      userGeneralInfo.hireDate =
        userGeneralInfo.hireDate &&
        userGeneralInfo.hireDate !== "0001-01-01T00:00:00"
          ? moment(userGeneralInfo?.hireDate).format("MM/DD/YY")
          : "";

      userGeneralInfo.terminationDate =
        userGeneralInfo.terminationDate &&
        userGeneralInfo.terminationDate !== "0001-01-01T00:00:00"
          ? moment(userGeneralInfo?.terminationDate).format("MM/DD/YY")
          : "";

      this.setState(
        { userGeneralInfo: {}, hasPerformanceAccess, hasSalesAccess },
        () => {
          this.setState({
            userGeneralInfo,
          });

          // handle Socket
          // if(shouldReloadMainPage)
          // {
          //   this.props.handleEmitMessage()
          // }
        }
      );

      this.getUMPermissions();
    });
  };

  getUserGeneralInfoOnUpdate = (shouldReloadMainPage = true) => {
    if (shouldReloadMainPage) {
      this.setState({ shouldReloadMainPage });
    }

    UserManagement.GetUserGeneralInfo(
      this.props.userData?.user_UniqueID,
      this.props?.isNewUserAdded
    ).then((userGeneralInfo: IGeneralInfo) => {
      let _userGeneralInfo= _.cloneDeep(this.state.userGeneralInfo)

      _userGeneralInfo.groupHWPPayrollSetting = userGeneralInfo.groupHWPPayrollSetting
      _userGeneralInfo.status = userGeneralInfo.status
      _userGeneralInfo={..._userGeneralInfo,...userGeneralInfo}

      this.setState({
        userGeneralInfo:_userGeneralInfo,
      });
      

    
    });
  };

  getUserLaborInfo = (shouldReloadMainPage = false) => {
    // if (shouldReloadMainPage) {
    //   this.setState({ shouldReloadMainPage });
    // }

    // UserManagement.GetUserLaborInfo(
    //   this.props.userData?.user_UniqueID,
    //   this.props?.isNewUserAdded
    // ).then((data: any) => {
    //   const _userGeneralInfo = { ...this.state.userGeneralInfo, ...data };
    //   this.setState(
    //     {
    //       userGeneralInfo: _userGeneralInfo,
    //     },
    //     () => {
    //       if (shouldReloadMainPage) {
    //         // this.laborRef?.laborDataOnLoad();
    //       }
    //     }
    //   );
    // });
  };

  getUMPermissions = () => {
    this.setState({ tabPermissions: [], isTabPermissionsLoaded: false });
    UserManagement.GetUMPermissions()
      .then((tabPermissions) => {
        this.setState({ tabPermissions }, () =>
          this.setState({ isTabPermissionsLoaded: true })
        );
      })
      .catch(() => {
        this.setState({ isTabPermissionsLoaded: true });
      });
  };

  updatePayrollCount = (count, allowDeposit) => {
    const { userGeneralInfo } = this.state;
    userGeneralInfo.allowDeposit = allowDeposit;
    this.setState({ payRollTabCount: count, userGeneralInfo });
  };

  updateSalesTabCount = (count) => {
    this.setState({ salesTabCount: count });
  };

  getStatusBadgeclass = (status) => {
    let className = "";
    switch (status) {
      case "Active":
        className = "color-green";
        break;
      case "Inactive":
        className = "color-grey";
        break;
      default:
        className = "color-orange";
        break;
    }
    return className;
  };

  checkHasTabAccess = (tabName) => {
    const { userGeneralInfo } = this.state;
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule
          .filter((item) => item.result?.toString()?.toLowerCase())
          .includes(tabName?.toString()?.toLowerCase())?.length > 0;
    }
    return hasAccess;
  };

  checkHasPerformanceAccess = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) => item.result?.toString() === "Labor Management"
        )?.length > 0;
    }
    return hasAccess;
  };

  checkHasSalesAccess = (userGeneralInfo): boolean => {
    let hasAccess = false;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) => item.result?.toString() === "Sales"
        )?.length > 0;
    }
    return hasAccess;
  };
  checkHasPayrollModuleAccess = (): boolean => {
    let hasAccess = false;
    const { userGeneralInfo } = this.state;
    if (userGeneralInfo?.subscriptionModule?.length > 0) {
      hasAccess =
        userGeneralInfo?.subscriptionModule?.filter(
          (item) =>
            item.result?.toString() === "Payroll" ||
            item.result?.toString() === "Payroll Service" ||
            item.result?.toString() === "ShowPayroll"
        )?.length > 0;
    }
    return hasAccess;
  };

  checkHasTabPermissionAccess = (tabName) => {
    const { tabPermissions } = this.state;
    let hasAccess = false;
    if (tabPermissions?.length > 0) {
      hasAccess =
        tabPermissions.filter(
          (item) =>
            item?.displayPermissionName?.toString()?.toLowerCase() ===
            tabName?.toString()?.toLowerCase()
        )?.length > 0;
    }
    return hasAccess;
  };

  showHideChangeLogModal = () => {
    this.setState({
      showChangeLogModal: !this.state.showChangeLogModal,
    });
  };

  sendWelcomeEmail = () => {
    if (this.props.userData.status != "Active") {
      Utils.toastError("Welcome Email can only be sent to active users", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    let UpdateUserRoles = [] as any;
    let usertoupdate: any = {};
    usertoupdate.Username = this.props.userData.username;
    usertoupdate.RoleId = 0;
    usertoupdate.TenantId = this.props.userData.tenantId;
    UpdateUserRoles.push(usertoupdate);
    UserManagement.sendWelcomeEmail(UpdateUserRoles).then((response: any) => {
      if (response?.success) {
        toast.success("Welcome Email has been sent successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      } else {
        toast.success(response?.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "scheduleMain",
        });
      }
    });
  };

  handleSetHotelWideTableExpended = (itemArray)=>{
    this.setState({
      hotelWideTableExpended:itemArray
    })
  }

  handlePTOTableExpended =(itemArray)=>{
    this.setState({
      ptoTableExpended:itemArray
    })
  }

  
  render() {
    let {
      generalTabCount,
      user,
      enterpriseAccess,
      enableEnterpriseAccess,
      userGeneralInfo,
      showChangeLogModal,
      controlPosition,
      salesTabCount,
      isTabPermissionsLoaded,
      hasPerformanceAccess,
      enterPriseWisePayrollData,
      isEnterpriseWideDataLoaded,
      payRollTabCount,
      isClientSetupDataLoaded,
      clientSetupData,
      hasSalesAccess,
      laborTabCount,
      isUserRolesLoaded,
    } = this.state;

    const {
      userData: { username, imagePath },
      statusList,
    } = this.props;
    const generalTabFunctionsProps: IGeneralProps = {
      getUserGeneralInfo: this.getUserGeneralInfo,
      getGeneralTabModificationStatus: this.getGeneralTabModificationStatus,
      userGeneralInfo: this.state.userGeneralInfo,
      user,
      enterpriseAccess,
      enableEnterpriseAccess,
      userData: this.props.userData,
      statusList,
      uniqueno: this.props.userData?.uniqueno,
      isNewUserAdded: this.props.isNewUserAdded,
      isEditGeneralInfo: this.state.isEditGeneralInfo,
      getGeneralTabCount: this.getGeneralTabCount,
      userRoles: this.state.userRoles,
      payrollIntegrationHids:this.props.payrollIntegrationHids,
      activeTab:this.state.activeTab
    };

    return (
      <div id="performanceSlideOut" className="pos-fxd index-1000">
        <ToastContainer
          containerId={"userDetailSlideoutcontainer"}
          autoClose={3000}
        />

        {showChangeLogModal && (
          <UsersLogHistory
            payPeriodStartDate={""}
            payPeriodEndDate={""}
            showChangeLogModal={showChangeLogModal}
            showHideChangeLogModal={this.showHideChangeLogModal}
            hotelName={""}
            hotelId={0}
            hideCrossAndClearFilters={false}
            shiftId={this.props.userData?.uniqueno}
            shiftDate={""}
            accType={""}
            isViewAll={false}
          />
        )}
        {isClientSetupDataLoaded && (
          <div className="d-flex">
            <div
              className="back-drop"
              onClick={this.closeSlideOutWithConfirmation}
            ></div>

            <Container
              fluid
              className="body-sec performance-position user-management-detail-slideout justify-content-end"
            >
              <div className="page-heading d-flex">
                <div className="profile-img">
                  <img
                    src={imagePath ? imagePath : profile}
                    className={"default"}
                    width="48"
                    height="48"
                    onError={(e) => (e.currentTarget.src = profile)}
                  />
                </div>

                <div className="user-details align-items-center">
                  <div className="user-name d-flex align-items-center">
                    <EllipsisWithTooltip placement="bottom">
                      {userGeneralInfo?.userName}
                    </EllipsisWithTooltip>

                    {userGeneralInfo.status === "Inactive" && (
                      <span
                        className={`badge ${this.getStatusBadgeclass(
                          userGeneralInfo?.status
                        )}`}
                      >
                        {userGeneralInfo?.status}
                      </span>
                    )}
                  </div>

                  <div className="position-name">
                    {userGeneralInfo?.roleName}{" "}
                    {userGeneralInfo?.isLowSecurityUser == 1 && (
                      <div className={"badge color-blue"}>Low Security</div>
                    )}
                  </div>
                </div>

                <div className="header-popout ml-auto">
                  <Dropdown className="more-action" alignRight>
                    <Dropdown.Toggle
                      className="btn-outline-primary btn btn-primary more"
                      id="dropdown-more"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.showHideChangeLogModal}>
                        View Change Log
                      </Dropdown.Item>
                      {userGeneralInfo.status === "Active" &&
                        userGeneralInfo.role > 0 &&
                        userGeneralInfo.hids?.filter(
                          (x) => x?.status === "Active"
                        )?.length > 0 &&
                        userGeneralInfo.email && (
                          <Dropdown.Item onClick={this.sendWelcomeEmail}>
                            Re-send Welcome Email
                          </Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <button
                  type="button"
                  className="btn wht-bg cross"
                  onClick={this.closeSlideOutWithConfirmation}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                      fill="#84888C"
                    />
                  </svg>
                </button>
              </div>

              <Tab.Container
                activeKey={this.state.activeTab}
                onSelect={this.setActiveTab}
                id="left-tabs-example"
                defaultActiveKey="general"
              >
                <div>
                  <Nav className="nav-tabs flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="general">
                        General{" "}
                        {generalTabCount > 0 && (
                          <span className="tab-badge color-orange">
                            {generalTabCount}
                          </span>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    {this.checkifHasLaborModule(userGeneralInfo) && (
                      <Nav.Item>
                        <Nav.Link
                          eventKey="labor"
                          // disabled={generalTabCount > 0}
                        >
                          Labor{" "}
                          {laborTabCount > 0 && (
                            <span className="tab-badge color-red">
                              {laborTabCount}
                            </span>
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {this.checkHasPayrollModuleAccess() &&
                      userGeneralInfo?.employeeType !== "ExcludefromPayroll" &&
                      this.state.userGeneralInfo?.employeeType !==
                        "Contractor" && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="payroll"
                            //  disabled={this.getLaborTabPTOCount()>0 }
                          >
                            Payroll
                            {payRollTabCount > 0 && (
                              <span className="tab-badge color-red">
                                {payRollTabCount}
                              </span>
                            )}{" "}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    {Object.keys(userGeneralInfo)?.length > 0 && (
                      <Nav.Item>
                        <Nav.Link eventKey="documents">Documents </Nav.Link>
                      </Nav.Item>
                    )}

                    {hasSalesAccess && (
                      <Nav.Item>
                        <Nav.Link eventKey="sales">
                          Sales{" "}
                          {+salesTabCount > 0 && (
                            <span className="tab-badge color-orange">1</span>
                          )}
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </Nav>
                </div>
                <div>
                  <Tab.Content>
                    <Tab.Pane eventKey="general">
                      {Object.keys(userGeneralInfo)?.length > 0 &&
                        isUserRolesLoaded && (
                          <General {...generalTabFunctionsProps} />
                        )}
                    </Tab.Pane>{" "}
                    {this.checkifHasLaborModule(userGeneralInfo) && (
                      <Tab.Pane eventKey="labor">
                        {Object.keys(userGeneralInfo)?.length > 0 &&
                          isTabPermissionsLoaded && (
                            <Labor
                              getLaborTabModificationStatus={
                                this.getLaborTabModificationStatus
                              }
                              ref={(laborRef) => {
                                this.laborRef = laborRef;
                              }}
                              activeTab={this.state.activeTab}
                              isNewUserAdded={this.props?.isNewUserAdded}
                              canViewPerformance={
                                this.checkHasTabPermissionAccess(
                                  "View Sensitive data"
                                ) || this.props?.isNewUserAdded
                              }
                              canEditPerformance={
                                this.state.isEditLaborandPayrollInfo ||
                                this.props?.isNewUserAdded
                              }
                              hasViewSensitiveDataPermission={this.checkHasTabPermissionAccess(
                                "View Sensitive data"
                              )}
                              getUserGeneralInfo={this.getUserGeneralInfo}
                              getUserLaborInfo={this.getUserLaborInfo}
                              username={userGeneralInfo.userName}
                              uniqueno={this.props.userData?.uniqueno}
                              userGeneralInfo={userGeneralInfo}
                              clientSetupData={clientSetupData}
                              controlPosition={controlPosition}
                              isEditLaborandPayrollInfo={
                                this.state.isEditLaborandPayrollInfo
                              }
                              checkHasPerformanceAccess={hasPerformanceAccess}
                              hasOnlyAccountingAccess={this.checkIfOnlyAccountingAccess(
                                userGeneralInfo
                              )}
                              shouldReloadMainPage={this.shouldReloadMainPage}
                              getLaborTabCount={this.getLaborTabCount}
                              payrollIntegrationHids={this.props.payrollIntegrationHids}
                              checkHasPayrollModuleAccess={this.checkHasPayrollModuleAccess}

                              hotelWideTableExpended={this.state.hotelWideTableExpended}
                              handleSetHotelWideTableExpended={this.handleSetHotelWideTableExpended}

                              ptoTableExpended={this.state.ptoTableExpended}
                              handlePTOTableExpended={this.handlePTOTableExpended}
                              getUserGeneralInfoOnUpdate={this.getUserGeneralInfoOnUpdate}


                             
                            />
                          )}
                      </Tab.Pane>
                    )}
                    {this.props.userData?.uniqueno > 0 &&
                      Object.keys(userGeneralInfo)?.length > 0 &&
                      isTabPermissionsLoaded &&
                      this.state.userGeneralInfo?.employeeType !==
                        "ExcludefromPayroll" &&
                      this.state.userGeneralInfo?.employeeType !==
                        "Contractor" &&
                      this.checkHasPayrollModuleAccess() &&
                      isEnterpriseWideDataLoaded && (
                        <Tab.Pane eventKey="payroll">
                          <Payroll
                            ref={(payrollRef) => {
                              this.payrollRef = payrollRef;
                            }}
                            hasAddPermission={
                              this.state.isEditLaborandPayrollInfo ||
                              this.props?.isNewUserAdded
                            }
                            hasEditPermission={
                              this.state.isEditLaborandPayrollInfo ||
                              this.props?.isNewUserAdded
                            }
                            hasViewSensitiveDataPermission={
                              this.checkHasTabPermissionAccess(
                                "View Sensitive data"
                              ) || this.props?.isNewUserAdded
                            }
                            userUniqueNo={this.props.userData?.uniqueno}
                            userName={username}
                            userGeneralInfo={userGeneralInfo}
                            clientSetupData={clientSetupData}
                            hireDate={userGeneralInfo?.hireDate}
                            employeeType={userGeneralInfo?.employeeType}
                            enterPriseWisePayrollData={
                              enterPriseWisePayrollData
                            }
                            getEnterPriseWisePayrollData={
                              this.getEnterPriseWisePayrollData
                            }
                            getPayrollTabModificationStatus={
                              this.getPayrollTabModificationStatus
                            }
                            isNewUserAdded={this.props?.isNewUserAdded}
                            getUserGeneralInfo={this.getUserGeneralInfo}
                            getUserLaborInfo={this.getUserLaborInfo}
                            updatePayrollCount={this.updatePayrollCount}
                            shouldReloadMainPage={this.shouldReloadMainPage}
                            isEditLaborandPayrollInfo={
                              this.state.isEditLaborandPayrollInfo
                            }
                            getUserGeneralInfoOnUpdate={this.getUserGeneralInfoOnUpdate}
                          />
                        </Tab.Pane>
                      )}
                    {isTabPermissionsLoaded &&
                      this.checkHasTabPermissionAccess("User Management") &&
                      Object.keys(userGeneralInfo)?.length > 0 && (
                        <Tab.Pane eventKey="documents">
                          <Documents
                            hasAddEditPermission={
                              (this.state.isEditDocumentInfo &&
                                (userGeneralInfo.status === "Active" ||
                                  userGeneralInfo.status === "Inactive")) ||
                              this.props?.isNewUserAdded
                            }
                            hasDeletePermission={this.state.isEditDocumentInfo}
                            uniqueno={this.props.userData?.uniqueno}
                            userGeneralInfo={userGeneralInfo}
                            isEditDocumentInfo={this.state.isEditDocumentInfo}
                            isNewUserAdded={this.props?.isNewUserAdded}
                            shouldReloadMainPage={this.shouldReloadMainPage}
                          />
                        </Tab.Pane>
                      )}
                    {isTabPermissionsLoaded &&
                      this.checkHasTabPermissionAccess("User Management") &&
                      hasSalesAccess &&
                      Object.keys(userGeneralInfo)?.length > 0 && (
                        <Tab.Pane eventKey="sales">
                          {Object.keys(userGeneralInfo)?.length > 0 && (
                            <Sales
                              hasAddEditPermission={
                                this.state.isEditSalesInfo ||
                                this.props?.isNewUserAdded
                              }
                              handleSalesTabCount={this.handleSalesTabCount}
                              getUserGeneralInfo={this.getUserGeneralInfo}
                              userName={username}
                              userGeneralInfo={userGeneralInfo}
                              updateSalesTabCount={this.updateSalesTabCount}
                              shouldReloadMainPage={this.shouldReloadMainPage}
                              isEditSalesInfo={
                                this.state.isEditSalesInfo ||
                                this.props?.isNewUserAdded
                              }
                              isNewUserAdded={this.props?.isNewUserAdded}
                              getUserGeneralInfoOnUpdate={this.getUserGeneralInfoOnUpdate}
                            />
                          )}
                        </Tab.Pane>
                      )}
                  </Tab.Content>
                </div>
              </Tab.Container>
            </Container>
          </div>
        )}
      </div>
    );
  }
}
