import * as React from "react";
import { Dropdown, 
  // OverlayTrigger, Tooltip
 } from "react-bootstrap";
import {
  // IReportGroupResponseDto,
  IReportGroupSubGroupWithHotelCountDto,
} from "../Contracts/IReportParameter";
import { ReportGroup } from "../Services/ReportGroup";
import _ from "lodash";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";

export class SingleSelectReportGroupSearchForChart extends React.Component<any, any> {
  private inputElement: any;
  private ddl: any =React.createRef();
  constructor(props: any) {
    super(props);
    this.inputElement = React.createRef();
    this.state = {
      groupNameModal: "All",
      baseData: [],
      filterData: [],
      hotelList: [],
      inputValue: "",
      loading:true
    };
    this.handleChangeEhid = this.handleChangeEhid.bind(this);
    this.resetSerchBox = this.resetSerchBox.bind(this);
  }

  componentDidMount() {
 
    this.setState({ groupNameModal: this.props?.defaultValue });
  

    ReportGroup.GetReportGroupSubGroupWithHotelCountDashboard(this.props?.moduleName,this.props?.isAll, this.props?.notIncludeEnterpriseAccounts,this.props?.showOnlyHotelAccounts)
      .then(async (result: IReportGroupSubGroupWithHotelCountDto[] | null) => {
        if (result !== null && result.length > 0) {
          this.setState({ baseData: result });
          this.buildDropDownlist(result);
        }
      })
      .catch((error) => {
        // Need to handle this
        console.log(error);
      });
  }

  buildDropDownlist = (data: any[]) => {
    if (data?.length === 0) {
      this.setState({ filterData: [] });
      return false;
    }
    const groupedData = _.groupBy(data, "groupName");
    let groupList: any = [];
    Object.keys(groupedData).forEach((key) => {
      const childItems: any = groupedData[key];
      if (childItems?.length > 0) {
        const item: any = {};
        item.groupID = childItems[0].groupID;
        item.groupName = childItems[0].groupName;
        item.subGroups = childItems;
        groupList.push(item);
      }
    });

    const item: any = {};
    item.groupID = -1;
    item.groupName = "Select a Group";
    item.subGroups = [];
    const item2: any = {};
    item2.groupID = 0;
    item2.groupName = "All";
    item2.subGroups = [];
    groupList = [...[item, item2], ...groupList];
    this.setState({ filterData: groupList, hotelList: groupList, loading:false });
  };

  
  handleChangeEhid(e: { target: { value: string } }) {
    const baseData = [...this.state.baseData];
  
    if (e.target.value) {
      const updatedList = baseData.filter((item) => {
        return (
          item?.groupName?.toString()?.toLowerCase().indexOf(e.target.value?.toString()?.toLowerCase()) >
            -1 ||
          item.subGroupName?.toString()?.toLowerCase()
            .indexOf(e.target.value?.toString()?.toLowerCase()) > -1
        );
      });

      this.buildDropDownlist(updatedList);
    } else {
      this.buildDropDownlist(baseData);
    }

    this.setState({ inputValue: e.target.value });
  }

  componentDidUpdate = (prevProps: any) => {
    if (this.props?.defaultValue !== prevProps?.defaultValue) {
      this.setState({ groupNameModal: this.props?.defaultValue });
    }

    if (
      !this.props?.defaultValue &&
      this.props?.defaultValue !== prevProps?.defaultValue
    ) {
      const item: any = {};
      item.groupID = -1;
      item.groupName = "Select a Group";
      item.subGroups = [];
      this.handleDropdownChange(item, true);
    }

    //   const { defaultValue } = this.props;
    //  if(this.state.filterData?.length>0 && defaultValue && (prevProps?.defaultValue !==  defaultValue ||
    //   this.state.groupNameModal === "All" ) ) {
    //      const  item = this.state.filterData?.find(r=> r?.groupName?.toLowerCase() === defaultValue?.toLowerCase());
    //      this.hidSelectModal(item?.groupName);     this.handleDropdownChange(item);
    //  }
  };

  handleDropdownChange = (item: any, calledFromDidUpdate=  false) => {
    const placeHolderName =
      +item?.groupID === -1 || +item?.groupID === 0 ? item?.groupName : item?.subGroupName;
    this.setState({ groupNameModal: placeHolderName });
    this.props.onDDLChange(item); 
   if(  this.ddl?.current && !calledFromDidUpdate) {
   this.ddl.current.click();
   }
    this.resetSerchBox();
  };

  resetSerchBox = () => {
    this.setState({ inputValue: "" });
    this.setState({ filterData: this.state.hotelList });
    const baseData = [...this.state.baseData];
    this.buildDropDownlist(baseData);
  };

  render() {
    const onFocusInput = (eventKey: any) => {
      this.resetSerchBox();
      setTimeout(() => {
        this.inputElement?.current?.focus();
      }, 100);
    };
    const {isDashboard=false} =this.props

    if(this.state.loading){
      return <></>
    }

    return (
      <Dropdown  className="single-select" onClick={onFocusInput}>
        <Dropdown.Toggle ref= {this.ddl} id="Modal-hid">
          {this.state.groupNameModal === "Select a Group" && (
            <span className="placeholder">{isDashboard ? "Group":this.state.groupNameModal}</span>
          )}
          {this.state.groupNameModal !== "Select a Group" &&
            this.state.groupNameModal}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div className="search-sec">
            <input
              type="text"
              className="form-control"
              onChange={this.handleChangeEhid}
              placeholder="Filter Group"
              value={this.state.inputValue}
              ref={this.inputElement}
            />
          </div>
          <div className="list-area">
            {this.state.filterData?.length===0 && this.state.inputValue && (
                      
                      <div className="footer">
                      <span>No data found</span>
                      </div>
            )}
            {(this.state.filterData || []).map((item: any, idx: any) => (
              <div className="heroGroup">
                {item.subGroups?.length > 0 ? (
                  <div className="dropdown-header divider">
                    {item.groupName}
                  </div>
                ) : (
                  <div
                    className="all-item dropdown-item d-flex justify-content-between"
                    onClick={() => this.handleDropdownChange(item)}
                  >
                    <span className="subgroup-name">{item.groupName}</span>
                    {this.state.groupNameModal === item.groupName && (
                      <span className="active">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                            fill="#2CAF92"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                )}

                {item.subGroups?.length > 0 && (
                  <div>
                    {(item.subGroups|| []).map((subGroup: any, idxx: any) => (
                      <Dropdown.Item
                        onClick={() => this.handleDropdownChange(subGroup)}
                      >
                        <EllipsisWithTooltip placement="bottom">
                          <span className="subgroup-name">
                            {subGroup.subGroupName}{" "}
                          </span>
                        </EllipsisWithTooltip>
                        <span className="count">{subGroup?.noOfHotels}</span>
                        {this.state.groupNameModal ===
                          subGroup.subGroupName && (
                          <span className="active">
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z"
                                fill="#2CAF92"
                              />
                            </svg>
                          </span>
                        )}
                      </Dropdown.Item>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
