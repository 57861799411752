import * as React from "react";
import { Button, Container, Dropdown, Form, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import { FiChevronDown } from "react-icons/fi";
import { toast } from "react-toastify";
import { UserManagement } from "../../Common/Services/UserManagement";
import { bankSyncServices } from "../../Common/Services/bankSyncServices";
import "../../Common/Assets/Styles/Modules/epay-onboarding.scss";
import { ReactPageLoader } from "../../Common/Components/ReactPageLoader";
import { resolve, reject } from "q";
import _ from "lodash";
import { CommonService } from "../../Common/Services/CommonService";
import { Utils } from "../../Common/Utilis";
import { data } from "jquery";
export class EpayOnboarding extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            ePayTenant: { id: -1, name: "", status: "" },
            tenantSearch: "",
            isTenantLoading: false,
            tenantsList: [],
            filterTenantList: [],
            tableData: [],
            deftableData: [],
            isTableLoading: false,
            isbulkSelect: false,
            dataSearch: "",
            isEPayBulkSelect: false,
            isSubmitLoading: false,
            isDownloadReady: false,
            BlobbaseUrl: "",
            ePayTabs: [],
            activeTabkey: "Operating Accounts",
            dropProvider: [],
            dropStatus: [{ id: 1, value: "Active" }, { id: 2, value: "Inactive" }],
            isFileDownloading: false,
            ePayProviderFooter: "",
            ePayStatusFooter: ""
        };
    }

    componentDidMount() {
        this.getTenantsList();
        this.getBlobbaseUrl();
    }
    getBlobbaseUrl = () => {

        let keyName = "BlobbaseUrl";
        let BlobbaseUrl = "";
        CommonService.GetInnflowConfigKeyValue(keyName)
            .then(async (result: any | null) => {
                console.log(result);
                if (result !== null) {
                    BlobbaseUrl = result;
                    this.setState({ BlobbaseUrl: BlobbaseUrl });
                }

            });
    }
    getTenantsList = () => {
        this.setState({ isTenantLoading: true })
        UserManagement.GetTenantList().then(async (result: any) => {
            const data = result?.result?.result;
            let filterItem: any[] = [];
            filterItem = data.filter((item: any) => item.status.toString().toLocaleLowerCase() === "active")
            this.setState({ tenantsList: filterItem, filterTenantList: filterItem, isTenantLoading: false });
        })
    }

    searchItems = (e: any) => {
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.tenantsList)));
        let { filterTenantList } = this.state;
        let result = e.target.value;
        this.setState({ tenantSearch: result });
        if (result !== "") {
            filterTenantList = dataList.filter((o: any) => o.tenantname.toLowerCase().includes(result.toLowerCase().trim()));
        } else {
            filterTenantList = dataList;
        }
        this.setState({ filterTenantList });
    };

    tenantDDToggle = (e: any) => {
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.tenantsList)));
        this.setState({ tenantSearch: "", filterTenantList: dataList });
        (e === true) && setTimeout(() => { $("#idTenantSearch").focus(); }, 50);
    }

    tanentChange = (item: any) => {
        let { ePayTenant } = this.state;
        ePayTenant.id = item.tenantid;
        ePayTenant.name = item.tenantname;
        ePayTenant.status = item.status;
        this.setState({
            ePayTenant,
            ePayTabs: [],
            tableData: [],
            deftableData: [],
            isTableLoading: true,
            isbulkSelect: false,
            dataSearch: "",
            isEPayBulkSelect: false,
            isDownloadReady: false,
            activeTabkey: "Operating Accounts"
        }, () => {
            this.Get_ePayOnboardingProvider();
            this.GetePayOnboardingTenantWiseList(item.tenantid, "page");
            this.isEapyDocumentExists(item.tenantid);
        });
    }

    GetePayOnboardingTenantWiseList = (tenantid: any, type: any) => {
        let request: any = {};
        request.Tenantid = tenantid;
        bankSyncServices.GetePayOnboardingTenantWiseList(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    // debugger;
                    let data = result?.result;
                    data.forEach((o: any) => {
                        o["isDataChecked"] = false;
                        o["isDataDisabled"] = false;
                        o["isDataRowUpdated"] = false;
                        o["isFileDownloading"] = false;
                        o["isEhidChecked"] = false;
                        o["isEhidDisabled"] = false;
                        o["isSelectAll"] = false;
                    });
                    let accFilter = _.uniqBy(data, o => o.acctType?.toLowerCase()?.trim());
                    let isData = this.setTreeView(data, this.state.activeTabkey);
                    if (type !== "update") {
                        this.setState({ ePayTabs: [], tableData: [], deftableData: [] })
                    }
                    setTimeout(() => {
                        this.setState({
                            ePayTabs: accFilter,
                            tableData: isData,
                            deftableData: data,
                            isTableLoading: false,
                        })
                    }, 50)
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isTableLoading: false, isDownloadReady: false })
                reject();
            });
    }

    Get_ePayOnboardingProvider = () => {
        let request: any = {};
        bankSyncServices.Get_ePayOnboardingProvider(request)
            .then(async (result: any | null) => {
                if (result !== null) {
                    result = _.sortBy(result, "sort");
                    this.setState({
                        dropProvider: result
                    });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isTableLoading: false, isDownloadReady: false })
                reject();
            });
    }

    setTreeView = (list: any, type: any) => {
        let filterData: any[] = [];
        let accFilter = _.uniqBy(list, o => o.acctType?.toLowerCase()?.trim());
        accFilter.forEach((item: any, index: any) => {
            let hidFilter: any = list.filter((o) => o.acctType === item.acctType);
            hidFilter = _.uniqBy(hidFilter, o => o.hid);
            let row: any = {}
            row.srid = item.srid;
            row.acctType = item.acctType;
            row.isSelectAll = item.isSelectAll;
            row.ehidList = [];
            hidFilter.forEach(item2 => {
                let newArray: any = { ehid: "", srid: -1, isEhidChecked: item2.isEhidChecked, isEhidDisabled: item2.isEhidDisabled, status: "", data: {} };
                newArray.data = list.filter((it: any) => (it.hid === item2.hid && it.acctType === item2.acctType));
                newArray.srid = item2.srid;
                newArray.ehid = item2.lettercode;
                newArray.acctType = item2.acctType;
                newArray.status = newArray.data.some((it: any) => it.isdownloaded?.toString().toLocaleLowerCase() === "yes") ? "Download Ready" : "";
                row.ehidList.push(newArray);
            });
            filterData.push(row);
        });
        let isTypeData = filterData.filter((o: any) => o.acctType === type);
        return isTypeData;
    }

    handleDataSearch = (e: any) => {
        debugger;
        const dataList = Object.assign([], JSON.parse(JSON.stringify(this.state.deftableData)));
        let result = e.target.value
        this.setState({ dataSearch: result });
        let { tableData } = this.state;

        if (result !== "") {
            tableData = this.setTreeView(dataList.filter(item =>
                item.lettercode?.toLowerCase().includes(result.toLowerCase().trim()) ||
                item.customerID?.toString().toLowerCase().includes(result.toLowerCase().trim()) ||
                item.bName?.toLowerCase().includes(result.toLowerCase().trim())
            ), this.state.activeTabkey)
            // tableData.map((o: any) => o.isToggle = true);
        } else {
            tableData = this.setTreeView(dataList, this.state.activeTabkey);
            // tableData.map((o: any, i: any) => o.isToggle = i === 0 ? true : false)
        }
        this.setState({ tableData });
    }

    checkaccTypeLength = (type: any) => {
        let { deftableData, dataSearch, tableData } = this.state;

        let totalLength: any[] = [];
        let allData = this.setTreeView(deftableData, this.state.activeTabkey);
        let filterData: any = dataSearch !== "" ? tableData : allData;
        filterData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    if (type.toString().toLocaleLowerCase() === dataList.acctType.toString().toLocaleLowerCase()) {
                        totalLength.push(dataList);
                    }
                });
            });
        });
        return totalLength;
    }

    checkTotalLength = () => {
        let { deftableData, dataSearch, tableData } = this.state;
        let totalLength: any[] = [];
        let allData = this.setTreeView(deftableData, this.state.activeTabkey);
        let filterData: any = dataSearch !== "" ? tableData : allData;
        filterData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    totalLength.push(dataList);
                });
            });
        });
        return totalLength;
    }

    handleSelectChange = (item: any, row: any, type: any) => {
        let { tableData, deftableData } = this.state;
        let isUpdated: boolean = false;
        deftableData.map((o: any) => {
            if (o?.srid === row?.srid) {
                if ((type === "Provider" || type === "Status") && o.ePayProvider !== item?.srid) {
                    isUpdated = true;
                }
            }
        });

        tableData[0]?.ehidList.forEach((hidList: any) => {
            hidList.data.forEach((dataList: any) => {
                if (dataList?.srid === row?.srid) {
                    if (type === "Provider") {
                        dataList.ePayProvider = item?.srid;
                        dataList.isDataRowUpdated = isUpdated;
                    } else if (type === "Status") {
                        dataList.ePayStatus = item?.value;
                        dataList.isDataRowUpdated = isUpdated;
                    }
                }
            });
        });
        deftableData.map((o: any) => {
            if (o?.srid === row?.srid) {
                if (type === "Provider") {
                    o.ePayProvider = item?.srid;
                } else if (type === "Status") {
                    o.ePayStatus = item?.value
                }
            }
        });
        this.setState((props) => ({ ...props, tableData, deftableData }));
    }

    handleSelectCheck = (event: any, list: any, type: any) => {
        let { tableData, deftableData } = this.state;
        let isChecked = event.target.checked;
        let allSelect: any[] = [];
        tableData[0]?.ehidList.forEach((hidList: any) => {
            if (type === "parent" && hidList.ehid === list.ehid) {
                hidList.isEhidChecked = isChecked;
                hidList.data.forEach((dataList: any) => {
                    if (dataList.lettercode === list.ehid) {
                        dataList.isDataChecked = isChecked;
                    }
                });
            }
            hidList.data.forEach((dataList: any) => {
                if (dataList?.srid === list?.srid) {
                    dataList.isDataChecked = isChecked;
                }
            });
        });
        let isParentChecked: any[] = [];
        tableData[0].ehidList.forEach((o: any) => {
            if (o.ehid === list.lettercode) {
                o.data.filter((i: any) => {
                    if (i.isDataChecked) {
                        isParentChecked.push(o)
                    }
                })
            }
        });
        tableData[0].ehidList.forEach((hidList: any) => {
            if (type === "child" && hidList.ehid === list.lettercode) {
                hidList.isEhidChecked = isParentChecked.length > 0;
            }
        });
        deftableData.forEach((o: any) => {
            if (type === "parent") {
                let ehid: any = [];
                let srid: any = [];
                list.data.filter(o => {
                    srid.push(o.srid);
                    ehid.push(o.lettercode);
                })
                if (type === "parent" && ehid.toString().includes(o?.lettercode.toString()) && srid.toString().includes(o?.srid.toString()) && list?.acctType.toString().toLocaleLowerCase() === o?.acctType.toString().toLocaleLowerCase()) {
                    o.isEhidChecked = isChecked;
                    o.isDataChecked = isChecked;
                    o.isSelectAll = isChecked;
                }
            }
            if (type === "child" && o?.srid === list?.srid && o?.srid === list?.srid && list?.acctType.toString().toLocaleLowerCase() === o?.acctType.toString().toLocaleLowerCase()) {
                o.isEhidChecked = isChecked;
                o.isDataChecked = isChecked;
                o.isSelectAll = isChecked;
            }
        });
        allSelect = this.checkTotalLength().filter((o: any) => (o?.isDataChecked || o?.isEhidChecked));
        let isEqualLen = (allSelect.length === this.checkTotalLength().length);
        $("#selectallHeader").prop("checked", allSelect.length > 0 && isEqualLen).prop("indeterminate", allSelect.length > 0 && !isEqualLen);
        $("#selectallFooter").prop("checked", allSelect.length > 0 && isEqualLen).prop("indeterminate", allSelect.length > 0 && !isEqualLen);
        this.setState({ tableData, isbulkSelect: isEqualLen, isEPayBulkSelect: (allSelect.length > 0) });
        if (allSelect.length === 0) {
            this.setState({ ePayProviderFooter: "", ePayStatusFooter: "" })
        }
    }

    handleSelectAll = (type: any, isChecked) => {
        let { tableData, deftableData, dataSearch } = this.state;
        let allSelect: any[] = [];
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.isEhidChecked = !isChecked
                hidList.data.forEach((dataList: any) => {
                    if (type.acctType.toString().toLocaleLowerCase() === dataList.acctType.toString().toLocaleLowerCase()) {
                        dataList.isDataChecked = !isChecked;
                    }
                });
            });
        });
        deftableData.forEach((o: any) => {
            if (
                this.state.activeTabkey.toString().toLocaleLowerCase() === o.acctType.toString().toLocaleLowerCase() &&
                (o.lettercode.toLowerCase().includes(dataSearch.toLowerCase().trim()) ||
                    o.customerID?.toString().toLowerCase().includes(dataSearch.toLowerCase().trim()) ||
                    o.bName.toLowerCase().includes(dataSearch.toLowerCase().trim()))
            ) {
                o.isEhidChecked = !isChecked;
                o.isDataChecked = !isChecked;
            }
        });
        allSelect = this.checkTotalLength().filter((o: any) => (o?.isDataChecked || o?.isEhidChecked));
        let isEqualLen = (allSelect.length === this.checkTotalLength().length);
        $("#selectallHeader").prop("checked", allSelect.length > 0 && isEqualLen).prop("indeterminate", allSelect.length > 0 && !isEqualLen);
        $("#selectallFooter").prop("checked", allSelect.length > 0 && isEqualLen).prop("indeterminate", allSelect.length > 0 && !isEqualLen);
        this.setState({ tableData, deftableData, isbulkSelect: isEqualLen, isEPayBulkSelect: (allSelect.length > 0) });
        if (allSelect.length === 0) {
            this.setState({ ePayProviderFooter: "", ePayStatusFooter: "" })
        }
    }

    isAllSelected = (types) => {
        let allSelect = this.checkaccTypeLength(types).filter((o: any) => o.isDataChecked);
        return (allSelect.length === this.checkaccTypeLength(types).length);
    }

    handleTabSelect = (eventKey: any) => {
        this.footerBulkClose();
        let { deftableData } = this.state;
        let tabData = this.setTreeView(deftableData, eventKey);
        this.setState({ activeTabkey: eventKey, tableData: [] }, () => {
            this.setState({ tableData: tabData, dataSearch: "" })
        });
    }

    resetEpayTableData = () => {
        let { deftableData, tableData } = this.state;
        deftableData.map(o => { o.isEhidChecked = false; o.isDataChecked = false })
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.isEhidChecked = false
                hidList.data.forEach((dataList: any) => {
                    dataList.isDataChecked = false;
                });
            });
        });
        this.setState({ deftableData, tableData })
    }

    footerBulkClose = () => {
        let { tableData } = this.state;
        let isParentChecked: any[] = [];
        (tableData[0]?.ehidList || []).forEach((o: any) => {
            o.data.filter((i: any) => {
                if (i.isDataRowUpdated) {
                    isParentChecked.push(o)
                }
            })
        })
        if (isParentChecked.length > 0) {
            this.GetePayOnboardingTenantWiseList(this.state.ePayTenant.id, 'update');
        } else {
            this.setState({
                isEPayBulkSelect: false,
                ePayProviderFooter: "",
                ePayStatusFooter: ""
            }, () => {
                this.resetEpayTableData();
                $("#selectallHeader").prop("checked", false).prop("indeterminate", false);
                $("#selectallFooter").prop("checked", false).prop("indeterminate", false);
            })
        }
    }

    handleFooterSelect = (row: any, type: any) => {
        if (type === "Provider") {
            this.setState({ ePayProviderFooter: row.srid });
        } else if (type === "Status") {
            this.setState({ ePayStatusFooter: row.value });
        }
    }

    handleSaveEPay = () => {
        this.setState({ isSubmitLoading: true });
        let { tableData, ePayTenant, ePayProviderFooter, ePayStatusFooter } = this.state;
        let isSelectedHid: any[] = [];
        let isSelectedOprId: any[] = [];
        let isSelectedProvider: any[] = [];
        let isSelectedStatus: any[] = [];
        let request: any = {};
        request.Tenantid = ePayTenant.id;
        let isCheckedItems: any = [];
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.data.forEach((dataList: any) => {
                    if (dataList.isDataChecked || dataList.isDataRowUpdated) {
                        isCheckedItems.push(dataList);
                    }
                });
            });
        });
        isCheckedItems.forEach((chkitem: any) => {
            tableData.forEach((item: any) => {
                item.ehidList.forEach((hidList: any) => {
                    if (hidList.ehid === chkitem.lettercode && hidList.acctType === chkitem.acctType) {
                        hidList.status = "Pending";
                    }
                });
            })
        })
        tableData.forEach((item: any) => {
            item.ehidList.forEach((hidList: any) => {
                hidList.isDisabled = true;
                hidList.data.forEach((dataList: any) => {
                    dataList.isDisabled = true;
                });
            });
        });
        this.setState({ tableData });
        isCheckedItems.forEach((o: any) => isSelectedHid.push(o.hid));
        isCheckedItems.forEach((o: any) => isSelectedOprId.push(o.oprid));
        isCheckedItems.forEach((o: any) => isSelectedProvider.push(ePayProviderFooter === "" ? o.ePayProvider : ePayProviderFooter));
        isCheckedItems.forEach((o: any) => isSelectedStatus.push(ePayStatusFooter === "" ? o.ePayStatus : ePayStatusFooter));
        request.OprID = isSelectedOprId.toString();
        request.EHID = isSelectedHid.toString();
        request.ePayProvider = isSelectedProvider.toString();
        request.ePayStatus = isSelectedStatus.toString();
        bankSyncServices.GetePayOnboardingCustomerID(request)
            .then(async (result: any | null) => {
                if (result.success) {
                    toast.success("Successfully Submitted.", { position: toast.POSITION.BOTTOM_RIGHT });
                    this.setState({
                        isSubmitLoading: false,
                        isbulkSelect: false,
                        dataSearch: "",
                        isEPayBulkSelect: false,
                        isTableLoading: true,
                        isDownloadReady: true,
                        ePayProviderFooter: "",
                        ePayStatusFooter: ""
                    }, () => {
                        this.GetePayOnboardingTenantWiseList(ePayTenant.id, "");
                    });
                } else {
                    tableData.forEach((item: any) => {
                        item.ehidList.forEach((hidList: any) => {
                            hidList.isDisabled = false;
                            hidList.data.forEach((dataList: any) => {
                                dataList.isDisabled = false;
                            });
                        });
                    });
                    this.setState({ tableData, isSubmitLoading: false });
                    Utils.toastError("Something went wrong.", { position: toast.POSITION.BOTTOM_RIGHT });
                }
                resolve();
            })
            .catch((error) => {
                this.setState({ isSubmitLoading: false });
                reject();
            });
    }

    // DeleteePayOnboardingAutomationDocument = async (type: any) => {
    //     debugger;
    //     let tenantId = this.state.ePayTenant.id;
    //     let result = await bankSyncServices.DeleteePayOnboardingAutomationFile(tenantId);
    //     this.setState({ isDownloadReady: false });
    // }

    downloadFile = (type: any) => {
        debugger;
        let tenantId = this.state.ePayTenant.id;
        let IsDelete = type === "" ? true : false;
        bankSyncServices.DownloadePayOnboardingAutomationDocument("Samplechecks.pdf", tenantId, IsDelete)
            .then(async (result: any | null) => {
                if (type === "") {
                    this.downloadExcel(tenantId, IsDelete);
                    this.setState({ isDownloadReady: false });
                } else {
                    // result !== undefined && 
                    this.setState({ isDownloadReady: true });
                }
                resolve();
            })
            .catch((error) => {

                reject();
            });
    }
    isEapyDocumentExists = (type: any) => {

        let tenantId = this.state.ePayTenant.id;
        bankSyncServices.IsEapyDocumentExists({ tenantId })
            .then(async (result: any | null) => {

                if (result !== null) {

                    if (result) {
                        this.setState({ isDownloadReady: true });
                    }
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }
    downloadExcel = (tenantId, IsDelete) => {
        bankSyncServices.DownloadePayOnboardingAutomationDocument("epayonboardingAccounts.csv", tenantId, IsDelete)
            .then(async (result: any | null) => {
                resolve();
                this.setState({ isDownloadReady: false });
            })
            .catch((error) => {
                reject();
            });
    }


    rowFileLoader = (val: any, item: any) => {
        let { tableData } = this.state;
        tableData[0]?.ehidList.forEach((hidList: any) => {
            hidList.data.forEach((dataList: any) => {
                if (dataList?.srid === item?.srid) {
                    dataList.isFileDownloading = val;
                }
            });
        });
        this.setState((props) => ({ ...props, tableData }));
    }


    downloadepayBoth = async (item, type) => {
        if (type === "row") { this.rowFileLoader(true, item) } else { this.setState({ isFileDownloading: true }) }
        let request: any = {};
        request.Tenantid = type === "row" ? item.tenantid : item;
        request.OprID = type === "row" ? item.oprid : "";
        request.EHID = type === "row" ? item.hid : "";
        bankSyncServices.GetePayOnboardingDownloadDoc(request)
            .then(async (result: any | null) => {
                if (result?.success) {
                    this.downloadepayDocumentexcel(item, type);
                    this.downloadepayDocumentpdf(item, type);
                }
                resolve();
                //this.setState({ isDownloadReady: false });
            })
            .catch((error) => {
                reject();
            });
    };

    downloadepayDocumentpdf = (item, type) => {
        let BlobbaseUrl = this.state.BlobbaseUrl;
        let fileName = "Samplechecks_" + (type === "row" ? item.oprid + '_' + item.hid + '.pdf' : item + ".pdf");
        fetch(BlobbaseUrl + 'epayonboarding/' + (type === "row" ? item.tenantid : item).toString() + '/Files/' + fileName, {
            method: 'GET', headers: { 'Content-Type': 'application/pdf' }
        }).then(async (response) => response.blob()).then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            if (type === "row") {
                this.rowFileLoader(false, item);
            } else {
                this.setState({ isFileDownloading: false })
            }
            //this.DeleteePayOnboardingAutomationDocument(tenantId);
        });
    };

    downloadepayDocumentexcel = (item, type) => {
        let BlobbaseUrl = this.state.BlobbaseUrl;
        let fileName = "epayonboardingAccounts_" + (type === "row" ? item.oprid + '_' + item.hid + '.csv' : item + ".csv");
        let reqfileName = "epayonboardingAccounts_" + (type === "row" ? item.oprid + '_' + item.hid + '.csv' : item + ".csv");
        fetch(BlobbaseUrl + 'epayonboarding/' + (type === "row" ? item.tenantid : item).toString() + '/Files/' + reqfileName, {
            method: 'GET', headers: { 'Content-Type': 'text/csv' }
        }).then(async (response) => response.blob()).then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            if (type === "row") {
                this.rowFileLoader(false, item);
            } else {
                this.setState({ isFileDownloading: false })
            }
        });
    };

    render() {
        const { isTenantLoading, filterTenantList, ePayTenant, tableData, isTableLoading, dataSearch, isSubmitLoading, isEPayBulkSelect, isDownloadReady, dropProvider, dropStatus } = this.state;
        let isParentChecked: any[] = [];
        (tableData[0]?.ehidList || []).forEach((o: any) => {
            o.data.filter((i: any) => {
                if (i.isDataRowUpdated) {
                    isParentChecked.push(o)
                }
            })
        })
        let isDataRowUpdated = (isParentChecked.length > 0);
        const checkItem = () => {
            return (
                <span className="itemcheck">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.49998 11.6895L5.03023 9.21973L3.96973 10.2802L7.49998 13.8105L14.7802 6.53023L13.7197 5.46973L7.49998 11.6895Z" fill="#2CAF92" />
                    </svg>
                </span>
            )
        }

        let isFChkItem: any[] = ((this.state.ePayTabs || []).filter((o: any) => o?.acctType?.toString()?.toLocaleLowerCase() === this.state.activeTabkey?.toString()?.toLocaleLowerCase()))

        return (
            <div className="ePayOnboardingControlMain">
                <Container fluid className="body-sec pos-rel">
                    <div className="page-heading underline d-flex">
                        <div className="mr-auto">ePay Onboarding</div>
                        <div className="action-group d-flex">{/**/}</div>
                    </div>
                    <div className="d-flex headerDownControl">
                        <div className="ePayTenantSelector">
                            <label htmlFor="dropdown-Tenant">Tenant</label>
                            <div className="d-flex">
                                <Dropdown className={`${isTenantLoading ? "disabled-area" : ""} ePayTenantSelectorDropdown`} onToggle={this.tenantDDToggle}>
                                    <Dropdown.Toggle className="ePayButton" id="dropdown-Tenant">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="">
                                                {(ePayTenant.id !== -1 ? ePayTenant.name : "Select Tenant")}
                                            </div>
                                            <div className="ml-auto drop-arrow d-flex">
                                                <FiChevronDown />
                                            </div>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="ePayTenantSelectorMenu">
                                        <div className="ePayTenantSearch">
                                            <Form.Group controlId="ePayTenantSearch">
                                                <Form.Control
                                                    id="idTenantSearch"
                                                    type="text"
                                                    name="search"
                                                    value={this.state.tenantSearch}
                                                    autoComplete="off"
                                                    onChange={this.searchItems}
                                                    placeholder="Search Tenant"
                                                    autoFocus={true}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="dropdownItems">
                                            {filterTenantList.length > 0 ? (<>
                                                {filterTenantList.map((item: any, idx: any) => (
                                                    <Dropdown.Item key={idx} eventKey={item.tenantid} onClick={() => this.tanentChange(item)}>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className=""> {item.tenantname}</div>
                                                            <div className="ml-auto">
                                                                {(item.tenantid === ePayTenant.id) && (
                                                                    checkItem()
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </>) : (
                                                <Dropdown.Item>No Item Found</Dropdown.Item>
                                            )}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="ml-auto">
                                    {(ePayTenant.id !== -1 && isDownloadReady) && (<Button disabled={this.state.isFileDownloading} onClick={() => this.downloadepayBoth(ePayTenant.id, "all")}>
                                        {this.state.isFileDownloading && (<Spinner size="sm" className="disabled-area" animation="border" />)} Download Ready
                                    </Button>)}
                                </div>
                            </div>
                        </div>
                    </div>

                    {ePayTenant.id !== -1 && (
                        <>
                            {isTableLoading ? (
                                <ReactPageLoader useas={"ePayOnboarding"} />
                            ) : (
                                <div className="ePayOnBoardingListItems">

                                    <div className="ePayOnBoardingListBody">

                                        <Tabs defaultActiveKey={this.state.activeTabkey} id="ePay_Onboarding" onSelect={this.handleTabSelect}>
                                            {this.state.ePayTabs.map((item: any, itemIdx: any) => (
                                                <Tab key={item.acctType} eventKey={item.acctType} title={item.acctType}>
                                                    <div className="ePayOnBoardingListHeader d-flex align-self-center">
                                                        <div className="ePaySearchInput">
                                                            <Form.Group controlId="ePayDataSearch">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="search"
                                                                    value={dataSearch}
                                                                    autoComplete="off"
                                                                    onChange={this.handleDataSearch}
                                                                    placeholder="Search"
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className="ePayListItems">
                                                        <div className="ePayCollapse">
                                                            <div className="ePayItemBody">
                                                                <div className={`${isEPayBulkSelect ? "hasEpayBulkFooter" : ""} ePayTableContainer`}>
                                                                    <Table hover responsive size="sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>
                                                                                    <div className="d-flex">
                                                                                        <div className="has_ePayCheckBox">
                                                                                            {tableData.length > 0 ? (
                                                                                                <button type="button" className="headerCheckList isChkBtn form-check" onClick={() => this.handleSelectAll(item, this.isAllSelected(item.acctType))}>
                                                                                                    <input id="selectallHeader" type="checkbox" checked={this.isAllSelected(item.acctType)} />
                                                                                                    <label title="" htmlFor="selectallHeader" className="form-check-label"></label>
                                                                                                </button>
                                                                                            ) : (
                                                                                                <div className="headerCheckList form-check">
                                                                                                    <input id="selectalla" type="checkbox" disabled={true} />
                                                                                                    <label title="" htmlFor="selectalla" className="form-check-label"></label>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        EHID/Account
                                                                                    </div>
                                                                                </th>
                                                                                <th>Provider</th>
                                                                                <th>Identifier</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {(tableData[0]?.ehidList || []).map((hidList: any, hidIdx: any) => (
                                                                                <React.Fragment key={hidList.ehid}>
                                                                                    <tr>
                                                                                        <td colSpan={4}>
                                                                                            <div className="epayEhid">
                                                                                                <div className="has_ePayCheckBox">
                                                                                                    <Form.Check
                                                                                                        type="checkbox"
                                                                                                        className="parentCheckList"
                                                                                                        disabled={hidList?.isDisabled}
                                                                                                        label={`${hidList?.ehid}`}
                                                                                                        id={`id_${hidList?.ehid}`}
                                                                                                        checked={hidList?.isEhidChecked}
                                                                                                        onChange={(e: any) => this.handleSelectCheck(e, hidList, "parent")}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    {hidList.data.map((dataList: any, dataidx: any) => (
                                                                                        <tr key={dataList.srid}>
                                                                                            <td>
                                                                                                <div className="has_ePayCheckBox pl-4">
                                                                                                    <Form.Check
                                                                                                        type="checkbox"
                                                                                                        disabled={dataList.isDisabled}
                                                                                                        label={`${dataList.bName}`}
                                                                                                        id={`id_${dataList.srid}`}
                                                                                                        checked={dataList.isDataChecked}
                                                                                                        onChange={(e: any) => this.handleSelectCheck(e, dataList, "child")}
                                                                                                    />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Dropdown className="drop-down-select w-150">
                                                                                                    <Dropdown.Toggle disabled={dataList.isDisabled} className="ePayButton" id="dropdown-Provider">
                                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                                            <div className="">
                                                                                                                {dataList.ePayProvider === null ? "No Provider" : (dropProvider.filter((o: any) => o.srid === dataList.ePayProvider)[0]?.providerName)}
                                                                                                            </div>
                                                                                                            <div className="ml-auto drop-arrow d-flex">
                                                                                                                <FiChevronDown />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu className="ePayTenantSelectorMenu">
                                                                                                        <div className="dropdownItems">
                                                                                                            {dropProvider.map((ditm: any, idx: any) => (
                                                                                                                <Dropdown.Item
                                                                                                                    className={`${(dataList?.customerID) && ditm.srid === 1 ? "d-none" : ""}`}
                                                                                                                    key={ditm.srid} eventKey={ditm.srid} onClick={() => this.handleSelectChange(ditm, dataList, "Provider")}>
                                                                                                                    <div className="d-flex align-items-center justify-content-center">
                                                                                                                        <div className="">{ditm.providerName}</div>
                                                                                                                        <div className="ml-auto">
                                                                                                                            {(ditm.srid === dataList.ePayProvider) && (
                                                                                                                                checkItem()
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </Dropdown.Item>
                                                                                                            ))}
                                                                                                        </div>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Form.Control
                                                                                                    disabled={(dataList.isDisabled || dataList.ePayProvider === 1 || dataList.ePayProvider === null)}
                                                                                                    className="w-150 cusDivFC"
                                                                                                    readOnly
                                                                                                    type="text"
                                                                                                    value={dataList?.customerID}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                                    {dataList?.isdownloaded?.toString().toLocaleLowerCase() === "yes" ? (
                                                                                                        <button
                                                                                                            onClick={() => this.downloadepayBoth(dataList, "row")}
                                                                                                            disabled={(dataList.ePayProvider === 1 || dataList.ePayProvider === null || dataList.isDisabled || dataList.isFileDownloading)}
                                                                                                            className={`${(dataList.ePayProvider === 1 || dataList.ePayProvider === null || dataList.isDisabled || dataList.isFileDownloading) ? "disabled-area" : ""} epayDownloadBtn`}>
                                                                                                            {dataList.isFileDownloading ? (<Spinner size="sm" className="disabled-area" animation="border" />) : (
                                                                                                                <svg className={`${(dataList.ePayProvider === 1 || dataList.ePayProvider === null || dataList.isDisabled) ? "disabled-area" : ""}`} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                                                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                                                                                    <g id="SVGRepo_iconCarrier">
                                                                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.163 2.819C9 3.139 9 3.559 9 4.4V11H7.803c-.883 0-1.325 0-1.534.176a.75.75 0 0 0-.266.62c.017.274.322.593.931 1.232l4.198 4.401c.302.318.453.476.63.535a.749.749 0 0 0 .476 0c.177-.059.328-.217.63-.535l4.198-4.4c.61-.64.914-.96.93-1.233a.75.75 0 0 0-.265-.62C17.522 11 17.081 11 16.197 11H15V4.4c0-.84 0-1.26-.164-1.581a1.5 1.5 0 0 0-.655-.656C13.861 2 13.441 2 12.6 2h-1.2c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656zM5 21a1 1 0 0 0 1 1h12a1 1 0 1 0 0-2H6a1 1 0 0 0-1 1z" fill={(dataList.ePayProvider === 1 || dataList.ePayProvider === null) ? "#3f4347" : "#2caf92"}>
                                                                                                                        </path>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            )}
                                                                                                        </button>) : (<div className="epayDownloadBtn"></div>)}

                                                                                                    <Dropdown className={`customEpayDrop ${(dataList.ePayProvider === 1 || dataList.ePayProvider === null) ? "disabled-area" : ""} ${dataList.ePayStatus === null ? "Inactive" : (dropStatus.filter((o: any) => o.value.toString().toLocaleLowerCase() === dataList?.ePayStatus?.toString()?.toLocaleLowerCase())[0]?.value)} drop-down-select w-110`}>
                                                                                                        <Dropdown.Toggle disabled={dataList.isDisabled} className="ePayButton" id="dropdown-Status">
                                                                                                            <div className="d-flex align-items-center justify-content-center">
                                                                                                                <div className="">
                                                                                                                    {dataList.ePayStatus === null ? "Inactive" : (dropStatus.filter((o: any) => o.value.toString().toLocaleLowerCase() === dataList?.ePayStatus?.toString()?.toLocaleLowerCase())[0]?.value)}
                                                                                                                </div>
                                                                                                                <div className="ml-auto drop-arrow d-flex">
                                                                                                                    <FiChevronDown />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Dropdown.Toggle>
                                                                                                        <Dropdown.Menu className="ePayTenantSelectorMenu">
                                                                                                            <div className="dropdownItems">
                                                                                                                {dropStatus.map((sitem: any, idx: any) => (
                                                                                                                    <Dropdown.Item key={sitem.srid} eventKey={sitem.srid} onClick={() => this.handleSelectChange(sitem, dataList, "Status")}>
                                                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                                                            <div className=""> {sitem.value}</div>
                                                                                                                            <div className="ml-auto">
                                                                                                                                {(sitem.value.toString().toLocaleLowerCase() === dataList?.ePayStatus?.toString()?.toLocaleLowerCase()) && (
                                                                                                                                    checkItem()
                                                                                                                                )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </Dropdown.Item>
                                                                                                                ))}

                                                                                                            </div>
                                                                                                        </Dropdown.Menu>
                                                                                                    </Dropdown>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}

                                                                                </React.Fragment>
                                                                            ))}

                                                                        </tbody>
                                                                    </Table>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>


                                                </Tab>
                                            ))}
                                        </Tabs>


                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    <div className={isEPayBulkSelect || isDataRowUpdated ? "wom_TabFooter" : "d-none wom_TabFooter"}>
                        <div className="bulkSelectFooter">

                            <div className={`${isEPayBulkSelect ? "" : "d-none"} has_ePayCheckBox`}>
                                <button type="button" className="headerCheckList isChkBtn form-check" onClick={() => this.handleSelectAll(isFChkItem[0], this.isAllSelected(this.state.activeTabkey))}>
                                    <input id="selectallFooter" type="checkbox" checked={this.isAllSelected(this.state.activeTabkey)} />
                                    <label title="" htmlFor="selectallFooter" className="form-check-label"></label>
                                </button>
                            </div>
                            {(isEPayBulkSelect) && (
                                <>
                                    <span className="shift-selected-count">{this.checkTotalLength().filter((o: any) => (o?.isDataChecked)).length} of accounts selected</span>
                                    <div className="footer-vertical-line"></div>
                                    <div className="d-flex align-items-center footer-selection-group">
                                        <span className="shift-selected-count mr-2">Provider</span>
                                        <Dropdown className={`${isSubmitLoading ? "disabled-area" : ""} drop-down-select w-150`}>
                                            <Dropdown.Toggle disabled={isSubmitLoading} className="bg-white ePayButton" id="dropdown-Provider-Footer">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div className="">
                                                        {this.state.ePayProviderFooter === "" ? "Select" : dropProvider.filter((o: any) => o.srid === +this.state.ePayProviderFooter)[0]?.providerName}
                                                    </div>
                                                    <div className="ml-auto drop-arrow d-flex">
                                                        <FiChevronDown />
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="ePayTenantSelectorMenu">
                                                <div className="dropdownItems">
                                                    {dropProvider.map((ditm: any, idx: any) => (
                                                        <Dropdown.Item key={ditm.srid} eventKey={ditm.srid} onClick={() => this.handleFooterSelect(ditm, "Provider")}>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <div className=""> {ditm.providerName}</div>
                                                                <div className="ml-auto">
                                                                    {(ditm.srid === +this.state.ePayProviderFooter) && (
                                                                        checkItem()
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))}

                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="mr-2"></div>
                                        <span className="shift-selected-count mr-2">Status</span>
                                        <Dropdown className={`customEpayDrop ${isSubmitLoading ? "disabled-area" : ""} ${this.state.ePayStatusFooter} drop-down-select w-110`}>
                                            <Dropdown.Toggle disabled={isSubmitLoading} className={`${this.state.ePayStatusFooter === "" ? "bg-white" : ""} ePayButton`} id="dropdown-Status-Footer">
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <div className="">
                                                        {this.state.ePayStatusFooter === "" ? "Select" : (dropStatus.filter((o: any) => o.value.toString().toLocaleLowerCase() === this.state.ePayStatusFooter?.toString()?.toLocaleLowerCase())[0]?.value)}
                                                    </div>
                                                    <div className="ml-auto drop-arrow d-flex">
                                                        <FiChevronDown />
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="ePayTenantSelectorMenu">
                                                <div className="dropdownItems">
                                                    {dropStatus.map((sitem: any, idx: any) => (
                                                        <Dropdown.Item key={sitem.srid} eventKey={sitem.srid} onClick={() => this.handleFooterSelect(sitem, "Status")}>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <div className=""> {sitem.value}</div>
                                                                <div className="ml-auto">
                                                                    {(sitem.value.toString().toLocaleLowerCase() === this.state.ePayStatusFooter?.toString()?.toLocaleLowerCase()) && (
                                                                        checkItem()
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </Dropdown.Item>
                                                    ))}

                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div className="mr-2"></div>
                                    </div>
                                </>
                            )}
                            <div className="d-flex align-items-center ml-auto">
                                {(isEPayBulkSelect) &&
                                    <Button className="mr-2" disabled={true}>Download</Button>
                                }
                                <Button disabled={isSubmitLoading} onClick={this.handleSaveEPay}>
                                    {isSubmitLoading && (<Spinner size="sm" className="disabled-area" animation="border" />)} Submit</Button>
                                <Button className="closeButton btn-ghost-light" onClick={() => this.footerBulkClose()}>&times;</Button>
                            </div>
                        </div>
                    </div>

                </Container>
            </div >
        );
    }

}