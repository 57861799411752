import React, { Fragment } from "react";
import { Container, Row, Col, Form, FormGroup } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import { resolve, reject } from "q";
import $ from "jquery";
import { ReactPageLoader } from "../../../../Common/Components/ReactPageLoader";
import { PayrollExportService } from "../../../../Common/Services/PayrollExport";
import { LaborTips } from "../../../../Common/Services/LaborTips";
import {
  IGetPayrollExportSlideOutData,
  ISavePayrollExportFeildData,
} from "../../../../Common/Contracts/IPayrollExport";
import {
  ITipDetailsTempalteRequestDto,
  ILabourSaveTipsRequestDto,
} from "../../../../Common/Contracts/ILaborForecast";
import { Utils } from "../../../../Common/Utilis";

let pageEmployeeNameCode: string = "employee!!0";
let pagePositionCode: string = "positioN!!0";
let pageRowEmpCode: string = "empCode!!-1";
let pageRegCode: string = "";
let pageVacCode: string = "";
let pageSickCode: string = "";
let pageFFPSLCode: string = "";
let pageFPSLFCode: string = "";
let pageFFMEXCode: string = "";
let pageHolCode: string = "";
let pageOTCode: string = "";
let pageDOTCode: string = "";
let pageUTOCode: string = "";
let pageSR1Code: string = "";
let pageSR2Code: string = "";
let pageSR3Code: string = "";
let pageSR4Code: string = "";
let pageSR5Code: string = "";
let empShStatus: string = "s/H!!0";

export class PayrollExportSlideOut extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaderTrue: false,
      userData: this.props.parentState.slidwOutRowData,
      exportBanner: this.props.parentState.exportBanner,
      changeValue: false,
      payrollSectionData: [],
      showTipsTable: false,
      tableData: [],
      tipColumns_cashTips: "",
      tipColumns_chargedTips: "",
      tipColumns_tipsPaidOut: "",
    };
  }

  componentDidMount() {
    let columnData = this.props.parentState.columnData;

    for (let i = 0; i < columnData.length; i++) {
      if (columnData[i].uniqueNumber === "101") {
        pageVacCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "102") {
        pageSickCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "103") {
        pageHolCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "104") {
        pageRegCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "105") {
        pageOTCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "106") {
        pageDOTCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "108") {
        pageFFPSLCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "109") {
        pageFPSLFCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "110") {
        pageFFMEXCode = columnData[i].field;
      }
      if (columnData[i].uniqueNumber === "111") {
        pageUTOCode = columnData[i].field;
      }
      if (
        columnData[i].uniqueNumber === "501" ||
        columnData[i].uniqueNumber === "SR1"
      ) {
        pageSR1Code = columnData[i].field;
      }
      if (
        columnData[i].uniqueNumber === "502" ||
        columnData[i].uniqueNumber === "SR2"
      ) {
        pageSR2Code = columnData[i].field;
      }
      if (
        columnData[i].uniqueNumber === "503" ||
        columnData[i].uniqueNumber === "SR3"
      ) {
        pageSR3Code = columnData[i].field;
      }
      if (
        columnData[i].uniqueNumber === "504" ||
        columnData[i].uniqueNumber === "SR4"
      ) {
        pageSR4Code = columnData[i].field;
      }
      if (
        columnData[i].uniqueNumber === "505" ||
        columnData[i].uniqueNumber === "SR5"
      ) {
        pageSR5Code = columnData[i].field;
      }
    }

    this.getPayrollSlideOutData("componentMount");
    this.getTipsData("componentMount");
  }

  closeSlideOut = () => {
    this.props.hideSlideOut();
  };

  closeModalShow = () => {
    if (this.state.changeValue) {
      confirmAlert({
        title: "Unsaved confirmation",
        message:
          "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
        buttons: [
          {
            label: "Yes",
            onClick: () => this.closeSlideOut(),
          },
          {
            label: "No",
            onClick: () => reject(),
          },
        ],
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      this.closeSlideOut();
    }
  };

  getPayrollSlideOutData = (type: string) => {
    if (type === "componentMount") {
      this.setState({ loaderTrue: true });
    }

    let requestObject = {} as IGetPayrollExportSlideOutData;
    requestObject.Hotelid = this.props.parentState.hidValue;
    requestObject.userUniqueNo = this.state.userData[pageRowEmpCode];
    requestObject.Type = this.state.userData.empStatus;
    requestObject.dateFrom = this.props.parentState.navigationCalenderDates[0];
    requestObject.dateTo = this.props.parentState.navigationCalenderDates[1];
    requestObject.empStatus = this.state.userData.empStatus;

    PayrollExportService.GetPayrollSlideOutData(requestObject)
      .then(async (result: any | null) => {
        if (result !== null && result.length > 0) {
          let payrollSectionData: any = [];
          let showTipsTable: boolean = false;
          result.forEach((data: any, id: any) => {
            
            let pushObject: any = {};
            pushObject.rowNo = id;
            pushObject.columnName = data.columnName;
            pushObject.editable = data.isEditable === "Yes" ? true : false;
            pushObject.label = data.msg;
            pushObject.srNo = data.srNo;
            pushObject.showLayout =
              data.showTwoWeek === "Yes"
                ? data.weekLayOut === "weekly"
                  ? "one"
                  : "two"
                : "no";
            if (data.showTwoWeek === "No") {
              if (data.total === null) {
                pushObject.weekOne = "";
              } else {
                if(pushObject.label!=="Hrs"){
                pushObject.weekOne =  Utils.currencyFormatWithOutdoller(data.total);
                }else{
                  pushObject.weekOne =data.total;
                }
              }
            } else if (data.showTwoWeek === "Yes") {
              if (data.weekLayOut === "weekly") {
                if (data.total === null) {
                  pushObject.weekOne = "";
                } else {
                  if(pushObject.label!=="Hrs"){
                  pushObject.weekOne =Utils.currencyFormatWithOutdoller(data.total);
                  }else{
                    pushObject.weekOne =data.total;
                  }
                }
              } else {
                if (data.week1 === null) {
                  pushObject.weekOne = "";
                } else {
                  if(pushObject.label!=="Hrs"){
                  pushObject.weekOne = Utils.currencyFormatWithOutdoller(data.week1);
                  }else{
                    pushObject.weekOne=data.week1;
                  }
                }

                if (data.week2 === null) {
                  pushObject.weekTwo = "";
                } else {
                  if(pushObject.label!=="Hrs"){
                  pushObject.weekTwo = Utils.currencyFormatWithOutdoller(data.week2);
                  }else{
                    pushObject.weekTwo = data.week2;
                  }
                }
              }
            } else {
            }

            pushObject.total = data.total === null ? 0 :Utils.currencyFormatWithOutdoller(data.total);

            // Adding Regular from Table Data
            if (this.props.parentState.isPartialExport) {
              if (
                data.srNo === 104 &&
              //  this.state.userData.empStatus === "Active" 
               // &&
                this.state.userData[empShStatus] === "H"
              ) {
                let rowValue = this.state.userData[pageRegCode];

                let valuetoInsert =
                  rowValue === undefined || rowValue === "-" ? "" : rowValue;

                pushObject.weekOne = valuetoInsert;//Utils.currencyFormatWithOutdoller(valuetoInsert);
                pushObject.total = valuetoInsert;
              }
            } else {
              if (
                data.srNo === 104
                // &&
               // this.state.userData.empStatus === "Active"
              ) {
                let rowValue = this.state.userData[pageRegCode];

                let valuetoInsert =
                  rowValue === undefined || rowValue === "-" ? "" : rowValue;

                pushObject.weekOne = valuetoInsert;//Utils.currencyFormatWithOutdoller(valuetoInsert);
                pushObject.total = valuetoInsert;
              }
            }

            // Add Holiday from Table Data
            if (
              data.srNo === 103 
            //  &&
             // this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageHolCode];
              if (rowValue === "0.00" || rowValue === "") {
                rowValue = "-";
              }
              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding OT from Table Data
            if (
              data.srNo === 105 
              //&&
            //  this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageOTCode];
              if (rowValue === "0.00" || rowValue === "") {
                rowValue = "-";
              }
              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding DOT from Table Data
            if (
              data.srNo === 106 
              //&&
              //this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageDOTCode];
              if (rowValue === "0.00" || rowValue === "") {
                rowValue = "-";
              }
              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding UTO from Table Data
            if (
              data.srNo === 111
              //  &&
              // this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageUTOCode];
              if (rowValue === "0.00" || rowValue === "") {
                rowValue = "-";
              }
              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding SR1 from Table Data
            if (
              data.srNo === 501 
              // &&
              // this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageSR1Code];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding SR2 from Table Data
            if (
              data.srNo === 502
              //  &&
              // this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageSR2Code];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding SR3 from Table Data
            if (
              data.srNo === 503 
              // &&
              // this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageSR3Code];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding SR4 from Table Data
            if (
              data.srNo === 504 
              //&&
             // this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageSR4Code];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding SR5 from Table Data
            if (
              data.srNo === 505 
              //&&
              //this.state.userData.empStatus === "Active"
            ) {
              let rowValue = this.state.userData[pageSR5Code];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding Vacation from Table Data
            if (data.srNo === 101) {
              let rowValue = this.state.userData[pageVacCode];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding Sick from Table Data
            if (data.srNo === 102) {
              let rowValue = this.state.userData[pageSickCode];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding FFPSL from Table Data
            if (data.srNo === 108) {
              let rowValue = this.state.userData[pageFFPSLCode];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding FPSLF from Table Data
            if (data.srNo === 109) {
              let rowValue = this.state.userData[pageFPSLFCode];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }

            // Adding FFMEX from Table Data
            if (data.srNo === 110) {
              let rowValue = this.state.userData[pageFFMEXCode];

              let valuetoInsert =
                rowValue === undefined || rowValue === "-" ? "" : rowValue;

              pushObject.weekOne = Utils.currencyFormatWithOutdoller(valuetoInsert);
              pushObject.total = valuetoInsert;
            }
           
            // Removing Tips Columns from the Complete Array
            if (data.srNo === 5 || data.srNo === 9 || data.srNo === 17) {
              showTipsTable = true; // Showing Tips Table only when selected from payroll processor
            } else {
              payrollSectionData.push(pushObject);
            }
// notes
            if (data.srNo === 5000) {
              
              pushObject.notesValue = data.notesValue;

              // pushObject.weekOne = data.notesValue;
              // pushObject.weekTwo = data.notesValue;
              pushObject.total = '0';
            }
          });
          // 
          this.setState({
            payrollSectionData: payrollSectionData,
            showTipsTable,
          });
        }
        this.setState({ loaderTrue: false });
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "payrollExportIndex",
        });
        this.setState({ loaderTrue: false });
        reject();
      });
  };

  getTipsData = (type: string) => {
    let request = {} as ITipDetailsTempalteRequestDto;
    request.userType = this.state.userData[pageRowEmpCode];
    request.hotelID = this.props.parentState.hidValue;
    request.date = this.props.parentState.calenderSelectDateString;
    request.PageType = "ExportPayroll";

    LaborTips.GetLaborTipsData(request)
      .then(async (result: any) => {
        if (result.message === "Success") {
          let apiResult: any = result.result;
          if (apiResult !== null && apiResult.length > 0) {
            let tableData: any = [];

            let tipColumns_cashTips: any;
            let tipColumns_chargedTips: any;
            let tipColumns_tipsPaidOut: any;
            for (let i = 0; i < apiResult.length; i++) {
              let tempTipsColumn = apiResult[i].tipColumn.split(",");
              tipColumns_cashTips = tempTipsColumn[0];
              tipColumns_chargedTips = tempTipsColumn[1];
              tipColumns_tipsPaidOut = tempTipsColumn[2];

              let pushObject: any = apiResult[i];

              if (Number(apiResult[i].cashTips) === 0) {
                pushObject.cashTips = "";
              }else{
                pushObject.cashTips = Utils.currencyFormatWithOutdoller(apiResult[i].cashTips);
              }
              if (Number(apiResult[i].chargedTips) === 0) {
                pushObject.chargedTips = "";
              }else{
                pushObject.chargedTips = Utils.currencyFormatWithOutdoller(apiResult[i].chargedTips);
              }
              if (Number(apiResult[i].tipsPaidOut) === 0) {
                pushObject.tipsPaidOut = "";
              }else{
                pushObject.tipsPaidOut = Utils.currencyFormatWithOutdoller(apiResult[i].tipsPaidOut);
              }

              let dayValue = moment(apiResult[i].date).format("ddd");

              pushObject.dayValue = dayValue;
              pushObject.rowNo = i;
              pushObject.rowAction = "";

              tableData.push(pushObject);
            }

            this.setState({
              tableData,
              tipColumns_cashTips: tipColumns_cashTips,
              tipColumns_chargedTips: tipColumns_chargedTips,
              tipColumns_tipsPaidOut: tipColumns_tipsPaidOut,
            });
          }
        } else {
          Utils.toastError(result.message, {
            // position: toast.POSITION.BOTTOM_RIGHT,
            // containerId: "payrollExportIndex",
          });
        }
        resolve();
      })
      .catch((err: any) => {
        Utils.toastError(`Server Error, ${err}`, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "payrollExportIndex",
        });
        reject();
      });
  };

  handleSave = async () => {
    // Codition Check for Tip Data for blank tip entered
    let blankTipFlag: boolean = false;
    // 
    let newTableData = [...this.state.tableData];
    newTableData = newTableData.map((d) => {
     
      if (d.cashTips !== "" || d.chargedTips !== "" || d.tipsPaidOut !== "") {

        let tipTotal = Number(d.total);
        if (tipTotal > 0) {
          return d;
        } else {
          blankTipFlag = true;
          return {
            ...d,
            action: "error",
          };
        }
      } else {
        return d;
      }
    });
    this.setState((curr: any) => ({
      ...curr,
      tableData: newTableData,
    }));

    if (blankTipFlag) {
      Utils.toastError("Addition of all tips must be greater than 0", {
        // position: toast.POSITION.BOTTOM_RIGHT,
        // containerId: "payrollExportIndex",
      });
      return false;
    }

    // After Cecking all vaidations
    this.setState({ changeValue: false });

    const storage = JSON.parse(localStorage.getItem("storage")!);
    const tenantID = storage === null ? 0 : (storage.tenantID as any);
    let userName = storage === null ? 0 : (storage.userName as any);
    let notes = "";
    let payrollSectionData = [...this.state.payrollSectionData];
    let tableData = [...this.state.tableData];

    let payrollRrequestArray: any = [];
    let tipsRequestArray: any = [];

    payrollSectionData.forEach((data: any) => {
      let payrollRequestObject = {} as ISavePayrollExportFeildData;

      payrollRequestObject.employee = this.state.userData[pageEmployeeNameCode];
      payrollRequestObject.Hotelid = this.props.parentState.hidValue;
      payrollRequestObject.DateFrom =
        this.props.parentState.navigationCalenderDates[0];
      payrollRequestObject.DateTo =
        this.props.parentState.navigationCalenderDates[1];
      //  debugger;
      payrollRequestObject.ColumnName = data.columnName;
      if(data.srNo===5000)
      {
        notes=  data.notesValue;
         
      }
      payrollRequestObject.Total = data.weekOne === "" ? "0" : Utils.removeInvaildCharFromAmount(data.weekOne);
      payrollRequestObject.UniqueNo = this.state.userData[pageRowEmpCode];
      payrollRequestObject.Week2 =
        data.weekTwo === undefined || data.weekTwo === null
          ? "0"
          : data.weekTwo === ""
          ? "0"
          :  Utils.removeInvaildCharFromAmount(data.weekTwo);
      payrollRequestObject.Srno = data.srNo;
      payrollRequestObject.TenantID = tenantID;
      payrollRequestObject.EmpStatus = this.state.userData.empStatus;
      payrollRequestObject.Notes = notes;
      payrollRrequestArray.push(payrollRequestObject);
    });

    tableData.forEach((data) => {
      let tipsRequestObject = {} as ILabourSaveTipsRequestDto;
      tipsRequestObject.Tenantid = tenantID;
      tipsRequestObject.loginUser = userName;
      tipsRequestObject.Date = data.date;
      tipsRequestObject.old_Date = data.date;
      tipsRequestObject.UserUniqueno = data.userUniqueno;
      tipsRequestObject.old_UserUniqueno = data.userUniqueno;
      tipsRequestObject.Employee = data.username;
      tipsRequestObject.CashTipID = Number(data.cashTipsID);
      tipsRequestObject.CashTipValue = Number(Utils.removeInvaildCharFromAmount(data.cashTips))
      tipsRequestObject.CatTipID = Number(data.chargedTipsID);
      tipsRequestObject.CatTipValue = Number(Utils.removeInvaildCharFromAmount(data.chargedTips))
      tipsRequestObject.PcTipID = Number(data.tipsPaidID);
      tipsRequestObject.PcTipValue = Number(Utils.removeInvaildCharFromAmount(data.tipsPaidOut))
      tipsRequestObject.Hid = this.props.parentState.hidValue;
      tipsRequestObject.Datefrom =
        this.props.parentState.navigationCalenderDates[0];
      tipsRequestObject.Dateto =
        this.props.parentState.navigationCalenderDates[1];
      tipsRequestObject.entryType = "update";

      // let total =
      //   Number(data.cashTips) +
      //   Number(data.chargedTips) +
      //   Number(data.tipsPaidOut);

      // if (total > 0) {
        tipsRequestArray.push(tipsRequestObject);
      // }
    });

   // debugger

    try {
      const response = await Promise.all([
        PayrollExportService.SavePayrollFieldData(payrollRrequestArray),
        LaborTips.saveLaborTips(tipsRequestArray),
      ]);

      let firstResponse = response[0];
      let secondResponse = response[1].result;
      let firstResponseMessage = firstResponse.msg;
      let secondResponseMessage = secondResponse.message;

      if (
        firstResponse.msg === "Success" &&
        secondResponse.message === "Success"
      ) {
        this.props.slideOutDataSave();
        toast.success("Payroll data successfully updated.", {
          // position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "payrollExportIndex",
        });
      } else {
        let validationMessage = "Save not Successfull, please try again.";
        if (firstResponseMessage !== "" && firstResponseMessage !== "Success") {
          validationMessage = firstResponseMessage;
        }
        if (
          secondResponseMessage !== "" &&
          secondResponseMessage !== "Success"
        ) {
          validationMessage = secondResponseMessage;
        }

        Utils.toastError(validationMessage, {
          // position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "payrollExportIndex",
        });
        this.setState({ changeValue: true });
      }
    } catch (error: any) {
      Utils.toastError(error, {
        // position: toast.POSITION.BOTTOM_RIGHT,
        // containerId: "payrollExportIndex",
      });
      this.setState({ changeValue: true });
    }
  };

  handleDiscard = () => {
    this.setState({ changeValue: false });
    this.getPayrollSlideOutData("discard");
    this.getTipsData("discard");
  };

  getInputFieldValue = (inputValue)=>{
    // 
    let value = Utils.removeInvaildCharFromAmount(inputValue);
    const rx_live = /^[0-9]*([.][0-9]?[0-9]?)?$/gm;///^[-]?\d*(?:[.]\d*)?$/;
    let lenbeforePoint = 8;
    
    if (rx_live.test(value)) {
      if (value.includes(".")) {
        const timeArray = (value)?.split(".");
        if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
          value = value;
        }
      } else {
        if (value.length <= lenbeforePoint) {
          value = value;
        }
      }
    } else if (value.includes(".") && value.length === 1) {
      value = value;
    } else {
      return false;
    }

    return value;
  }

  editableChange = (e: any, data: any, idx: any, weekType: any) => {
    
    // let value = Utils.removeInvaildCharFromAmount(e.target.value);
    let value = this.getInputFieldValue(e.target.value)
    // const numberRegex = /^[0-9]*([.][0-9]?[0-9]?)?$/gm;

    //if (value) {
      // if (value.length > 8) {
      //   value = value.substring(0, value.length - 1);
      // }

      let payrollSectionData = [...this.state.payrollSectionData];

      if (weekType === "week1") {
        payrollSectionData[idx].weekOne = value;
      } else {
        payrollSectionData[idx].weekTwo = value;
      }

      this.setState({ payrollSectionData, changeValue: true });
    // } else {
    //   return false;
    // }
  };
  
  getInputFieldStringValue = (inputValue)=>{
    
    let value = Utils.removeInvaildCharFromAmount(inputValue);
    const rx_live = /^[0-9]*([.][0-9]?[0-9]?[A-Z]?[a-z]?)?$/gm;///^[-]?\d*(?:[.]\d*)?$/;
    let lenbeforePoint = 200;
    
    if (rx_live.test(value)) {
      // if (value.includes(".")) {
      //   const timeArray = (value)?.split(".");
      //   if (timeArray[0].length <= lenbeforePoint && timeArray[1].length <= 2) {
      //     value = value;
      //   }
      // } else {
        if (value.length <= lenbeforePoint) {
          value = value;
        }
      
    // } else if (value.includes(".") && value.length === 1) {
    //   value = value;
    } else {
      return false;
    }

    return value;
  }
  editableChangeString = (e: any, data: any, idx: any, weekType: any) => {
    
    // let value = Utils.removeInvaildCharFromAmount(e.target.value);
    let value = this.getInputFieldStringValue(e.target.value)
    // const numberRegex = /^[0-9]*([.][0-9]?[0-9]?)?$/gm;

    //if (value) {
      // if (value.length > 8) {
      //   value = value.substring(0, value.length - 1);
      // }

        value =  e.target.value;
      let payrollSectionData = [...this.state.payrollSectionData];

      // if (weekType === "week1") {
      //   payrollSectionData[idx].weekOne = value;
      // } else {
      //   payrollSectionData[idx].weekTwo = value;
      // }
      payrollSectionData[idx].notesValue = value;

      this.setState({ payrollSectionData, changeValue: true });
    // } else {
    //   return false;
    // }
  };
  oneditableFocus = (e: any, data: any, idx: any, weekType: any)=>{
// 
    let value = Utils.removeInvaildCharFromAmount(e.target.value);
    let payrollSectionData = [...this.state.payrollSectionData];


    if (weekType === "week1") {
      payrollSectionData[idx].weekOne = value;
    } else {
      payrollSectionData[idx].weekTwo = value;
    }

    this.setState({ payrollSectionData, changeValue: true });
  }

  editableBlur = (e: any, data: any, idx: any, weekType: any) => {
    let value = e.target.value;

    if (value !== "" && value !== ".") {      
      value = Number(Number(Utils.removeInvaildCharFromAmount(value))).toFixed(2);

      let payrollSectionData = [...this.state.payrollSectionData];

      if (weekType === "week1") {
        if(data.label!=="Hrs"){
        payrollSectionData[idx].weekOne = Utils.currencyFormatWithOutdoller(value);
        }else{
          payrollSectionData[idx].weekOne = value;
        }
      } else {
        if(data.label!=="Hrs"){
        payrollSectionData[idx].weekTwo = Utils.currencyFormatWithOutdoller(value);
        }else{
          payrollSectionData[idx].weekTwo = value;
        }
      }

      this.setState({ payrollSectionData });
    } else {
      return false;
    }
  };

  onTipsInputChange = (e: any, row: any, rowIndex: any, cellType: any) => {
    let inputId = `${rowIndex}-${cellType}`;
    let start = e.target.selectionStart;
    let end = e.target.selectionEnd;
    // let value = Utils.removeInvaildCharFromAmount(e.target.value);
    let value = this.getInputFieldValue(e.target.value);
    // const numberRegex = /^[0-9]*([.][0-9]?[0-9]?)?$/gm;

    // if (value) {
      // if (value.length > 8) {
      //   value = value.substring(0, value.length - 1);
      // }

      let newTableData = [...this.state.tableData];
      newTableData = newTableData.map((d) => {
        if (d.rowNo === row.rowNo) {
          if (cellType === "cashTips") {
            return {
              ...d,
              cashTips: value,
            };
          } else if (cellType === "chargedTips") {
            return {
              ...d,
              chargedTips: value,
            };
          } else if (cellType === "tipsPaidOut") {
            return {
              ...d,
              tipsPaidOut: value,
            };
          } else {
            return d;
          }
        } else {
          return d;
        }
      });
      this.setState(
        (curr: any) => ({
          ...curr,
          tableData: newTableData,
          changeValue: true,
        }),
        () => {
          // let txtID = 'txttotalAmt';
          let txttotAmt = $("input[id^='" + inputId + "']")[0] as any;
          txttotAmt.selectionStart = start;
          txttotAmt.selectionEnd = end;
          $("#" + inputId)
            .focus()
            // .prop("selectionEnd", cursorIndex);
        }
      );
    // } else {
    //   return false;
    // }
  };

  onTipsInputBlur = (e: any, row: any, rowIndex: any, cellType: any) => {
    let value = e.target.value;

    if (value !== "" && value !== ".") {
      value = Number(Utils.removeInvaildCharFromAmount(value)).toFixed(2);
// 
      let rowData = this.state.tableData[rowIndex];

      let newTipTotal = (
        Number(Utils.removeInvaildCharFromAmount(rowData.tipsPaidOut)) +
        Number(Utils.removeInvaildCharFromAmount(rowData.chargedTips)) +
        Number(Utils.removeInvaildCharFromAmount(rowData.cashTips))
      )
        .toFixed(2)
        .toString();

      let newTipTotalFlag: boolean = false;

      if (Number(newTipTotal) <= 0) {
        newTipTotalFlag = true;
        Utils.toastError("Addition of all tips must be greater than 0", {
          // position: toast.POSITION.BOTTOM_RIGHT,
          // containerId: "payrollExportIndex",
        });
      }

      let newTableData = [...this.state.tableData];
      newTableData = newTableData.map((d) => {
        if (d.rowNo === row.rowNo) {
          if (cellType === "cashTips") {
            if (newTipTotalFlag) {
              return {
                ...d,
                cashTips: Utils.currencyFormatWithOutdoller(value),
                total: newTipTotal,
                action: "error",
              };
            } else {
              return {
                ...d,
                cashTips:Utils.currencyFormatWithOutdoller(value),
                total: newTipTotal,
                action: "",
              };
            }
          } else if (cellType === "chargedTips") {
            if (newTipTotalFlag) {
              return {
                ...d,
                chargedTips: Utils.currencyFormatWithOutdoller(value),
                total: newTipTotal,
                action: "error",
              };
            } else {
              return {
                ...d,
                chargedTips: Utils.currencyFormatWithOutdoller(value),
                total: newTipTotal,
                action: "",
              };
            }
          } else if (cellType === "tipsPaidOut") {
            if (newTipTotalFlag) {
              return {
                ...d,
                tipsPaidOut: Utils.currencyFormatWithOutdoller(value),
                total: newTipTotal,
                action: "error",
              };
            } else {
              return {
                ...d,
                tipsPaidOut: Utils.currencyFormatWithOutdoller(value),
                total: newTipTotal,
                action: "",
              };
            }
          } else {
            return d;
          }
        } else {
          return d;
        }
      });
      this.setState((curr: any) => ({
        ...curr,
        tableData: newTableData,
      }));
    } else {
      return false;
    }
  };

  onAmountFocus = (e: any, row: any, rowIndex: any, cellType: any) => {
    // 
    let inputId = `${rowIndex}-${cellType}`;
    let cursorIndex = e.target.selectionStart;

    let value = Utils.removecurrencyFormat(e.target.value);

    // let rowData = this.state.tableData[rowIndex];
  
    if (value !== "") {
     
      let newTableData = [...this.state.tableData];

      newTableData[row.rowNo][cellType] = value;
      // newTableData = newTableData.map((d) => {
      //   if (d.rowNo === row.rowNo) {
      //     if (cellType === "cashTips") {
      //         return {
      //           ...d,
      //           cashTips:value
      //         };
      //     } else if (cellType === "chargedTips") {
      //         return {
      //           ...d,
      //           chargedTips: value,
      //         };
            
      //     } else if (cellType === "tipsPaidOut") {
      //         return {
      //           ...d,
      //           tipsPaidOut: value,
      //        };
            
      //     } else {
      //       return d;
      //     }
      //   } else {
      //     return d;
      //   }
      // });
      this.setState({
        tableData: newTableData,
      },
      () => {
        $("#" + inputId)
          .focus()
          .prop("selectionEnd", cursorIndex);
      }
      );
    } else {
      return false;
    }
  
  }
  headerFormatter = (column: any, colIndex: any, row: any) => {
    if (column.dataField === "cashTips") {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            <span>{this.state.tipColumns_cashTips}</span>
          </EllipsisWithTooltip>
        </div>
      );
    } else if (column.dataField === "chargedTips") {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            <span>{this.state.tipColumns_chargedTips}</span>
          </EllipsisWithTooltip>
        </div>
      );
    } else if (column.dataField === "tipsPaidOut") {
      return (
        <div>
          <EllipsisWithTooltip placement="bottom">
            <span>{this.state.tipColumns_tipsPaidOut}</span>
          </EllipsisWithTooltip>
        </div>
      );
    }
  };

  dayNameFormatter = (cell: any, row: any, rowIndex: any) => {
    let month: string = moment(row.date).format("MM");
    let date: string = moment(row.date).format("DD");

    let string = `${month} / ${date}`;
    return (
      <div className="dayName-formatter d-flex">
        <div className="dayName">{row.dayValue}</div>
        <div className="dateDay">{string}</div>
      </div>
    );
  };

  tipValueFormatter = (cell: any, row: any, rowIndex: any, cellType: any) => {
    let inputValue = row[cellType];
    return (
      <div className="inputvalue-forta">
        <Form.Group>
          <Form.Control
            id={`${rowIndex}-${cellType}`}
            placeholder="-"
            disabled={
              this.state.exportBanner ||
              !this.props.parentState.permissionManagePayroll
            }
            value={inputValue}
            onChange={(e: any) =>
              this.onTipsInputChange(e, row, rowIndex, cellType)
            }
            onBlur={(e: any) =>
              this.onTipsInputBlur(e, row, rowIndex, cellType)
            }
            onFocus={(e)=>this.onAmountFocus(e, row, rowIndex, cellType)}
            autoComplete="off"
          />
        </Form.Group>
      </div>
    );
  };

  render() {
    const userData = this.state.userData;

    const columns = [
      {
        dataField: "dayName",
        text: "",
        editable: false,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.dayNameFormatter(cell, row, rowIndex),
      },
      {
        dataField: "cashTips",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.tipValueFormatter(cell, row, rowIndex, "cashTips"),
        classes: (cell, row, rowIndex, colIndex) => {
          return row.action === "error" ? "danger-well" : "";
        },
      },
      {
        dataField: "chargedTips",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.tipValueFormatter(cell, row, rowIndex, "chargedTips"),
        classes: (cell, row, rowIndex, colIndex) => {
          return row.action === "error" ? "danger-well" : "";
        },
      },
      {
        dataField: "tipsPaidOut",
        text: "",
        headerFormatter: this.headerFormatter,
        formatter: (cell: any, row: any, rowIndex: any) =>
          this.tipValueFormatter(cell, row, rowIndex, "tipsPaidOut"),
        classes: (cell, row, rowIndex, colIndex) => {
          return row.action === "error" ? "danger-well" : "";
        },
      },
    ];

    $(function() {
      $('th').each(function() {
        $(this).attr('tabindex', -50);
      });
    
      });
    
    return (
      <div className="payroll-export-slideout pos-fxd index-1000">
        <div className="d-flex">
          <div
            className="backDrop back-drop"
            onClick={this.closeModalShow}
          ></div>
          <Container
            fluid
            className="body-sec performance-position timesheet-details justify-content-end"
          >
            {this.state.loaderTrue ? (
              <ReactPageLoader useas={"payrollExportSlideOutLoader"} />
            ) : (
              <>
                <div className="page-heading underline">
                  <Row>
                    <Col sm="10">
                      <div className="employee-name">
                        {userData[pageEmployeeNameCode]}
                      </div>
                    </Col>
                    <Col sm="2" className="text-right">
                      <button
                        type="button"
                        className="btn wht-bg cross"
                        onClick={this.closeModalShow}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"
                            fill="#84888C"
                          ></path>
                        </svg>
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <div className="position-name">
                        {userData[pagePositionCode]}
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="section-body">
                  
                  {this.state.payrollSectionData.length > 0 &&
                
                    this.state.payrollSectionData.map((data: any, idx: any) => (
                    
                      <Fragment key={idx}>
                        {idx === 0 &&
                          (data.showLayout === "two" ||
                            data.showLayout === "one") && (
                            <Row key={idx} className="section-headings">
                              <Col sm="4">
                                <div className="entity-heading"></div>
                              </Col>
                              <Col sm="4" className="week-heading text-center">
                                Week 1
                              </Col>
                              {data.showLayout === "two" && (
                                <Col
                                  sm="4"
                                  className="week-heading text-center"
                                >
                                  Week 2
                                </Col>
                              )}
                            </Row>
                          )}
                        <Row key={idx} id={data.columnName == "Notes" ? "notes-row" : ""}>
                          <Col sm="4">
                            <div className="section-body-heading">
                              {data.columnName}
                            </div>
                          </Col>
                          
                          {data.srNo !== 5000 && (
                          <Col
                            sm={
                              data.showLayout === "two"
                                ? data.editable
                                  ? "4"
                                  : "8"
                                : "4"
                            }
                          >
                            <div className="section-body-value d-flex">
                              <span className="currency-label">
                                {data.label}
                              </span>
                              <FormGroup>
                             
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="-"
                                  disabled={
                                    !data.editable ||
                                    this.state.exportBanner ||
                                    !this.props.parentState
                                      .permissionManagePayroll
                                  }
                                  value={data.weekOne}
                                  onFocus={(e: any)=>
                                    this.oneditableFocus(e, data, idx, "week1")
                                  }
                                  // onFocus={(e: any) => e.target.select()}
                                  onChange={(e: any) =>
                                    this.editableChange(e, data, idx, "week1")
                                  }
                                  onBlur={(e: any) =>
                                    this.editableBlur(e, data, idx, "week1")
                                  }
                                  pattern="[+-]?\d+(?:[.,]\d+)?"
                                  maxLength={20}
                                  autoComplete='off'
                                /> 
                              
                              </FormGroup>
                            </div>
                          </Col> 
                           )}
                           {data.srNo == 5000 && (
                          <Col
                            sm={
                              data.showLayout === "two"
                                ? data.editable
                                  ? "8"
                                  : "8"
                                : "8"
                            }
                          >
                            <div className="section-body-value d-flex notes-field">
                              <span className="currency-label">
                                {data.label}
                              </span>
                              <FormGroup>
                             
                                <textarea
                                  
                                  className="form-control"
                                  
                                  disabled={
                                    !data.editable ||
                                    this.state.exportBanner ||
                                    !this.props.parentState
                                      .permissionManagePayroll
                                  }
                                  value={data.notesValue}
                                  // onFocus={(e: any)=>
                                  //   this.oneditableFocus(e, data, idx, "week1")
                                  // }
                                  // onFocus={(e: any) => e.target.select()}
                                  onChange={(e: any) =>
                                    this.editableChangeString(e, data, idx, "week1")
                                  }
                                  // onBlur={(e: any) =>
                                  //   this.editableBlur(e, data, idx, "week1")
                                  // }
                                 // pattern="[+-]?\d+(?:[.,]\d+)?"
                                  maxLength={200}
                                  //autoComplete='off'
                                ></textarea>
                              
                              </FormGroup>
                            </div>
                          </Col> 
                           )}
                          
                          {data.showLayout === "two" && data.editable && (
                             
                            <Col sm="4">
                               {data.srNo !== 5000 && (
                              <div className="section-body-value d-flex">
                                <span className="currency-label">
                                  {data.label}
                                </span>
                                <FormGroup>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="-"
                                    disabled={
                                      !data.editable ||
                                      this.state.exportBanner ||
                                      !this.props.parentState
                                        .permissionManagePayroll
                                    }
                                    value={data.weekTwo}
                                    // onFocus={(e: any) => e.target.select()}
                                    onFocus={(e)=>this.oneditableFocus(e, data, idx, "week2")}
                                    onChange={(e: any) =>
                                      this.editableChange(e, data, idx, "week2")
                                    }
                                    onBlur={(e: any) =>
                                      this.editableBlur(e, data, idx, "week2")
                                    }
                                  />
                                </FormGroup>
                              </div>
                               )}
                            </Col> 
                          )}
                         
                        </Row>
                      </Fragment>
                    ))}
                </div>

                {this.state.showTipsTable && this.state.tableData.length > 0 && (
                  <>
                    <div className="horizontal-seperator"></div>
                    <div className="tips-table" id="payrollTipsTable">
                      <div className="heading">Tips</div>
                      <BootstrapTable
                        keyField="rowNo"
                        data={this.state.tableData}
                        columns={columns}
                      />
                    </div>
                  </>
                )}
                {this.state.changeValue && (
                  <div className="fixed-action">
                    <div className="d-flex align-content-center flex-wrap">
                      <div className="mr-auto message">
                        <span>You have unsaved changes</span>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary btn-discard"
                        onClick={this.handleDiscard}
                      >
                        Discard
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleSave}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
