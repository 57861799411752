import * as React from "react";
import { Redirect } from "react-router-dom";
import {
    Col,
    Container,
    Button,
    OverlayTrigger,
    Tooltip,
    Dropdown,
    Form,
    Tabs,
    Tab,
    Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import EllipsisWithTooltip from "react-ellipsis-with-tooltip";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FiChevronDown } from "react-icons/fi";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircle, faCircleH, faClose, faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import profile from "../../Common/Assets/Images/profile.jpg";
import { SingleSearchDropdownList } from "../../Common/Components/SingleSearchDropdownList";
import RouteLeavingGuard from "../../Common/Components/RouteLeavingGuard";
import DatePicker from "react-datepicker";
import { WorkOrder } from "../../Common/Services/WorkOrder";
import AddCommentBox from "./AddCommentBox";
import { resolve, reject } from "q";
import Dropzone from 'react-dropzone';
import { faPlus, faMinus, faPaperclip, faFile, faFileImage, faFileExcel, faFilePdf, faFileCsv, faFileWord } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import OutsideClickHandler from 'react-outside-click-handler';
import { AnyARecord } from "dns";
import { truncate } from "fs/promises";
import Joi from "joi-browser";
import AutosizeInput from "react-input-autosize";
import { Utils } from "../../Common/Utilis";



let routeLeavingGuard: any = null;

export default class WorkOrderSlideOut extends React.Component<any, any> {
    private child: any;
    private ChildComment: any;
    private slideoutRef: any;
    private parentScroll: any;
    private btnSave: any

    private btnOpen: any
    private btnInprogress: any
    private btnOnhold: any
    private btnComplet: any
    private input: any;



    constructor(props: any) {
        super(props);
        this.child = React.createRef();
        this.ChildComment = React.createRef();
        this.slideoutRef = React.createRef();
        this.parentScroll = React.createRef();
        this.btnSave = React.createRef();
        this.btnOpen = React.createRef();
        this.btnInprogress = React.createRef();
        this.btnOnhold = React.createRef();
        this.btnComplet = React.createRef();
        this.input = React.createRef();


        this.state = {
            isSpinner: false,
            pageLoader: false,
            showAddAssetModal: false,
            hidValue: "Select",
            hotelName: "Select",
            // tableData: [],
            // assetTypes: [],
            // assetTypesList: [],
            createEditPermission: true,
            name:this.props.parentRowData?.name || "",
            bdrValue:false,
            locationList: this.props.parentState.locationTypesList,
            // assetList: this.props.parentState.assetTypesList,
            assetList: this.props.parentState.assetTypesList.filter(asset => asset.locationID === this.props.parentRowData.locationID),
            searchText: "",
            assetTypeId: 0,
            sortingOrder: "asc",
            sortingField: "",
            parentRowData: this.props.parentRowData,
            assignToList: this.props.parentState.assignToListRightPanel,
            priorityList: [

                { id: "Low", name: "Low" },
                { id: "Medium", name: "Medium" },
                { id: "High", name: "High" },
                { id: "Urgent", name: "Urgent" },
            ],
            selectedDueDate: "",
            savedfiles: [],
            newSelectfiles: [],
            files: [],
            fileSize: 5242880,
            moreActionFileUpload1: [
                { name: "Delete", eventKey: "delete" },
                { name: "Download", eventKey: "download" }
            ],
            isStatusloder: false,
            changeValue: false,
            pathname: "",
            isRefresh: false,
            locationId: 0,
            assetId: 0,
            priorityId: "",
            assignedId: 0,
            description: "",
            errors: false,
            inputPosition: 0,
            htmlcomment: [], 
            descritionBox: "",
            tempLocationSlideOutStatus:this.props.parentRowData.status,
            deleteFile : "No"
        };

        this.onDrop = this.onDrop.bind(this);
    }

    static defaultProps ={
        locationDisable: false,
        assetDisable: false,
    }

    componentDidMount() {
   
        let storage = JSON.parse(localStorage.getItem("hotelSelected")!);
        let storagehotelid = storage === null ? -1 : (storage.hotelID as any);    
        if(this.props.parentState.workorderPermission==="No"){
            this.isStatusCheckedPermission();
        }
       
        this.setState({ selectedDueDate: this.state.parentRowData?.dueDate, 
            description: this.state.parentRowData?.description 
           
        });
        this.GetUploadFileList();
        this.disccardChanges(); 
        this.slideoutRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
        });

       
    }


    isStatusCheckedPermission = () => {
        if (this.btnOpen.current != null) {
            this.btnOpen.current.disabled = true;
        }
        if (this.btnInprogress.current != null) {
            this.btnInprogress.current.disabled = true;
        }
        if (this.btnOnhold.current != null) {
            this.btnOnhold.current.disabled = true;
        }
        if (this.btnComplet.current != null) {
            this.btnComplet.current.disabled = true;
        }
    }




    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload.bind(this));
    }

    beforeunload(e: { preventDefault: () => void; returnValue: boolean }) {
        if (
            this.state.changeValue &&
            routeLeavingGuard !== "routeLeavingGuardTrue"
        ) {
            e.preventDefault();
            e.returnValue = true;
        }
    }



    handleClick = () => {
        this.setState({ bdrValue: true });
    };
    
    handleBlurAutoSize = () => {
        this.setState({ bdrValue: false ,   name: this.state.name.trimRight(),});
    };

    onFieldChange = (event, inputPosition) => {
        let value = event.target.value;
        const fieldName = event.target.name;
      
        if (fieldName === "name") {
          value = value?.toString()?.trimLeft();
        }
      
        this.setState({ [fieldName]: value, changeValue: true });
      };
    
    closeSlideOut = () => {
        this.props.closeWorkSlideOut();
    }

    closeWorkorderSlideOut = () => {
        this.props.closeLocationSlideOut();
    }
    enableutton = () => {
        if(this.btnOpen.current)
        {
            this.btnOpen.current.disabled = false;
        }

        if(this.btnInprogress.current)
        {
            this.btnInprogress.current.disabled = false;
        }

        if(this.btnOnhold.current)
        {
            this.btnOnhold.current.disabled = false;
        }

        if(this.btnComplet.current)
        {
            this.btnComplet.current.disabled = false;
        }
      
    }

    handleSelectedItem = (controlID: any, id: any) => {

        this.setState({ changeValue: true }, () => {
            if(this.props.parentState.PageType==="workOrderDetails"){ 
            this.props.changesOfSlideOut(true);
            }
        });
        if (controlID === 1) {

            this.setState({ assignedId: id });
        }
        if (controlID === 2) {
            this.setState({ priorityId: id });
        }
        if (controlID === 3) {
            this.setState({ assetId: id });
        }
        if (controlID === 4) {
            debugger;
            this.setState({ locationId: id } , () => {
                this.changelocation(id);
            });
            
        }

    };

    changelocation = (id) => {
        this.setState({assetList : []} , () => {
            this.setState({assetList : this.props.parentState.assetTypesList.filter(asset => asset.locationID === id),});
        })
    
    }

    handleSelectDate = (event, dateType, eventType) => {
        this.setState({ selectedDueDate: event === null ? "" : event, changeValue: true }, () => {
            if(this.props.parentState.PageType==="workOrderDetails"){ 
            this.props.changesOfSlideOut(true);
            }
        });
    }

    updateWorkOrderStatus = (type: any) => {

        if (this.state.parentRowData.status === type && this.props.parentState.PageType==="workOrderDetails") {
            toast.success("Selected status already saved", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }
         if (this.state.tempLocationSlideOutStatus === type && this.props.parentState.PageType==="locationWorkOrder") {
            toast.success("Selected status already saved", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            return;
        }

        if (type === "Open") {
            if (this.btnOpen.current != null) {
                this.btnOpen.current.disabled = true;
            }
        }
        else if (type === "In Progress") {
            if (this.btnInprogress.current != null) {
                this.btnInprogress.current.disabled = true;
            }
        }
        else if (type === "On Hold") {
            if (this.btnOnhold.current != null) {
                this.btnOnhold.current.disabled = true;
            }

        }
        else if (type === "Completed") {

            if (this.btnComplet.current != null) {
                this.btnComplet.current.disabled = true;
            }
        }


        let data = this.state.selectedRows;
        let storage = JSON.parse(localStorage.getItem("storage")!);
        let tenantID = storage === null ? 0 : (storage.tenantID as any);
        let deleteWorkOrder: any[] = [];
        // for (let i = 0; i < data.length; i++) {
        let newItem: any = {};
        newItem.hotelID = Number(this.state.parentRowData?.hid);
        newItem.tenantID = tenantID;
        newItem.workOrderID = Number(this.state.parentRowData.workOrderID);
        newItem.statusType = type;
        deleteWorkOrder.push(newItem);
        // }

        WorkOrder.UpdateeWorkOrderStatus(deleteWorkOrder)
            .then(async (result: any | null) => {
                if (result != null && result.result != null) {

                    if (result.result.messageCode === "Success") {
                        toast.success("Work Order status changed  successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                    if(this.props.parentState.PageType==="workOrderDetails"){
                    this.props.workOrderStatusUpdate(type, this.state.parentRowData.rowID);
                    }
                    if(this.props.parentState.PageType==="locationWorkOrder"){
                        this.enableutton();
                     
                        this.setState({tempLocationSlideOutStatus:type});
                       
                        }


                }
                resolve();
            })
            .catch((err) => {
                Utils.toastError(`Server Error, ${err}`, {
                });
                if (type === "Open") {
                    if (this.btnOpen.current != null) {
                        this.btnOpen.current.disabled = false;
                    }
                }
                else if (type === "In Progress") {
                    if (this.btnInprogress.current != null) {
                        this.btnInprogress.current.disabled = false;
                    }
                }
                else if (type === "On Hold") {
                    if (this.btnOnhold.current != null) {
                        this.btnOnhold.current.disabled = false;
                    }
                }
                else if (type === "Completed") {
                    if (this.btnComplet.current != null) {
                        this.btnComplet.current.disabled = false;
                    }
                }

                reject();
            });
    }

    onDrop = (filelist: any, mode: any) => {

        let size = this.state.fileSize;//5242880//5MB
        let newfiles: any = [];
        let files: any = [];
        let isReturn = true;
        let filestext = "";
        let filesizeval = Number(Number(this.state.fileSize) / (1024 * 1024))
        filelist.forEach(element => {
            if (element.size > size) {
                filestext = filestext + element.name + ","
                isReturn = false;
            }
        })

        if (!isReturn) {
            let msg = "One or more files are greater than " + filesizeval + "MB.";
            Utils.toastError(msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "InvoiceSlideout",
            });
        }
        let isInvalidFile = false;
        filelist.forEach(element => {
            if ((element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pdf'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'doc'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'docx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xls'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'xlsx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'csv'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'txt'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'bmp'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'tif'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'ppt'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'pptx'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'rtf'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'jpeg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'png'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'msg'
                || element.name.substring(element?.name.lastIndexOf(".") + 1, element?.name.length).toLowerCase() === 'eml'
            ) && element.size <= size) {
                newfiles.push(element)
                files.push(element)
            } else {
                isInvalidFile = true;
            }
        });
        if (isInvalidFile && isReturn) {
            let msg = "";
            if (filelist.length === 1) {
                msg = "This file type is not supported."
            } else {
                msg = "Some files are not supported."
            }
            Utils.toastError(msg, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "InvoiceSlideout",
            });
        }

        let newfileData = [...this.state.newSelectfiles];
        for (let i = 0; i < newfiles.length; i++) {
            newfiles[i].etype = "newfile";
            newfileData.push(newfiles[i]);
        }


        this.setState({ files, newSelectfiles: newfileData}, () => {
            debugger;
            if(this.props.parentState.PageType==="workOrderDetails"){ 
           // this.props.changesOfSlideOut(true);
            }
            if (mode === 2) {
                //this.isHandleAction();
            } else {
                if (files.length > 0) {
                    this.handleSaveFiles();
                }
            }

        })
    }


    handleSaveFiles = () => {    
        this.setState({isSpinner:true});
        let request: any = {};
        request.hotelID = Number(this.state.parentRowData?.hid);
        request.workOrderID = Number(this.state.parentRowData?.workOrderID);
        request.locationId =  Number(this.state.parentRowData.locationID) ;
        request.assetId =  Number(this.state.parentRowData?.assetID) ;
        request.priorityId =  this.state.parentRowData?.priorityType ;
        request.assignedId =  Number(this.state.parentRowData?.user_UniqueID);
        request.description = this.state.parentRowData?.description;
        let  date=this.state.parentRowData?.dueDate==null ? "":this.state.parentRowData?.dueDate;
        if (date !=="") {
            let currentDate: any = new Date();

            let date1 = new Date(this.state.parentRowData?.dueDate);


            const dateFormatted = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }).format(date1);          
            request.dueDate = dateFormatted;
        } else {
            request.dueDate = "";
        }
        let newfileData = [...this.state.newSelectfiles];


        WorkOrder.SaveWorkOrderFile(request, newfileData)
            .then(async (result: any | null) => {
                if (result !== null) {
                   
                    if (result.saveStatus === "Success") {
                      
                        this.GetUploadFileList();
                        this.setState({ isSpinner:false });
                        toast.success("Files saved  successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "InvoiceSlideout",
                        });   
                       
                    }
                    else {
                        this.setState({ isSpinner:false });
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "InvoiceSlideout",
                        });   
                    }
                }

                resolve();
            })
            .catch((error) => {
                this.setState({ changeValue: false,isSpinner:false });
                reject();
            });

    }






    handleSave = () => {

        if (this.state.description === "") {
            Utils.toastError(`Please populate all required fields.`, {
                position: toast.POSITION.BOTTOM_RIGHT,
                containerId: "addGarnishment",
            });

            return;
        } else {
            this.setState({ errors: false });
        }

        if (!this.state.name) {
            let errorMessage = "Please enter Work Order Name";
            Utils.toastError(errorMessage, {
              position: toast.POSITION.BOTTOM_RIGHT,
              containerId: "InvoiceSlideout",
            });
            
            return
        }
        
        this.btnSave.current.disabled = true;
        let request: any = {};
        request.Title=this.state.name;
        request.hotelID = Number(this.state.parentRowData?.hid);
        request.workOrderID = Number(this.state.parentRowData?.workOrderID);
        request.locationId = this.state.locationId == 0 ? Number(this.state.parentRowData.locationID) : Number(this.state.locationId);
        request.assetId = this.state.assetId == 0 ? Number(this.state.parentRowData?.assetID) : Number(this.state.assetId);
        request.priorityId = this.state.priorityId == "" ? this.state.parentRowData?.priorityType : this.state.priorityId;
        request.assignedId = this.state.assignedId == 0 ? Number(this.state.parentRowData?.user_UniqueID) : Number(this.state.assignedId);
        request.description = this.state.description.trim();
        if (this.state.selectedDueDate !== "") {
            let currentDate: any = new Date();

            let date1 = new Date(this.state.selectedDueDate);


            const dateFormatted = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
            }).format(date1);


            // currentDate.setDate( new Date(this.state.selectedDueDate).getDate());
            // let currentdate = currentDate.getDate();
            // let currentMonth = currentDate.getMonth() + 1;
            // let currentYear = currentDate.getFullYear();
            // let tempDate = currentMonth + "/" + currentdate + "/" + currentYear;
            request.dueDate = dateFormatted;
        } else {
            request.dueDate = "";
        }

       // let newfileData = [...this.state.newSelectfiles];
       let newfileData:any=[];

        WorkOrder.SaveWorkOrderFile(request, newfileData)
            .then(async (result: any | null) => {
                if (result !== null) {
                    if (result.saveStatus === "Success") {
                        if(this.props.parentState.PageType === "workOrderDetails"){
                            toast.success("Work Order saved  successfully.", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                containerId: "InvoiceSlideout",
                            });  
                        }
                        else{
                        toast.success("Changes saved successfully.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "InvoiceSlideout",
                        });   
                    }                     
                        this.btnSave.current.disabled = false;
                        this.setState({ changeValue: false });

                        if(this.props.parentState.PageType==="workOrderDetails"){                          
                        this.props.changesOfSlideOut(false);
                        this.props.refeshTable(this.state.parentRowData.workOrderID);                        
                        }
                        if(this.props.parentState.PageType === "locationWorkOrder"){
                             this.props.refeshTable(this.state.parentRowData.workOrderID,"saved")
                           // this.setState({parentRowData:request});
                            
                        }
                       
                        this.GetUploadFileList();
                    }
                    else {
                        Utils.toastError(result.message, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            containerId: "InvoiceSlideout",
                        });
                        this.setState({ isDisabled: false })
                        this.btnSave.current.disabled = false;
                    }
                }

                resolve();
            })
            .catch((error) => {
                this.btnSave.current.disabled = false;
                reject();
            });

    }

    handleSelectFileUpload = (eventKey: any, file, index) => {
        if (eventKey === "delete") {
            this.setState({deleteFile : "Yes"});
            let request: any = {};
            // request = Object.assign({}, JSON.parse(JSON.stringify(this.state.formData)));
            let masg = "";
            masg = "Are you sure you want to delete this Attachment?"
            confirmAlert({
                title: "Delete Attachment",
                message: masg,
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => this.isDeleteWorkOrder(file),
                    },
                    {
                        label: "No",
                        onClick: () => reject(),
                    },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }
        else if (eventKey === "download") {
            this.DownloadWorkUploadFile(file);
        } else if (eventKey === "TempDelete") {
            let masg = "";
            masg = "Are you sure you want to delete this Attachment?"
            confirmAlert({
                title: "Delete Attachment",
                message: masg,
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => this.isTempDeleteWorkOrder(file),
                    },
                    {
                        label: "No",
                        onClick: () => reject(),
                    },
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
            });
        }
    }

    DownloadWorkUploadFile = (file: any) => {

        WorkOrder.DownloadWorkUploadFile(file)
            .then(async (result: any | null) => {
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };


    isDeleteWorkOrder = (file: any) => {
        let deleteRequest: any = {};
        deleteRequest.hotelID = Number(this.state.parentRowData?.hid);
        deleteRequest.workOrderID = Number(this.state.parentRowData.workOrderID);
        deleteRequest.filenameUniqno = file.fileUniqueNo;

        WorkOrder.DeleteWorkOrderFiles(deleteRequest)
            .then(async (result: any | null) => {
                if (result.saveStatus === "Success") {
                    toast.success(result.message, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                    });
                }
                this.GetUploadFileList();
                resolve();
            })
            .catch((error) => {
                reject();
            });
    }
    isTempDeleteWorkOrder = (file: any) => {
        let data = [...this.state.newSelectfiles]
        let index = data.findIndex((item) => item.etype === "newfile");
        data.splice(index, 1);
        this.setState({ newSelectfiles: data });
    }

    onFileChange = (event: any, mode) => {
        let newfiles: any = [];
        if (event.target.files.length > 0) {
            for (let i = 0; i < event.target.files.length; i++) {
                let fileRow = event.target.files[i];

                newfiles.push(fileRow)

            }
            this.onDrop(newfiles, mode);
            let fileList = document.getElementById('formControlsFile') as any
            fileList.value = "";
        }
    };


    GetUploadFileList = () => {

        let requestlist: any = {};
        requestlist.hotelID = Number(this.state.parentRowData?.hid);
        requestlist.workOrderID = Number(this.state.parentRowData.workOrderID);

        WorkOrder.GetUploadFileList(requestlist)
            .then(async (result: any | null) => {
                if (result !== null) {

                    for (let i = 0; i < result.length; i++) {
                        this["myRef" + i] = React.createRef();
                    }
                    this.setState({ savedfiles: [] }, () => {
                        debugger;
                        this.setState({ savedfiles: result, files: [], newSelectfiles: [],isSpinner:false });
                    });
                }
                resolve();
            })
            .catch((error) => {
                reject();
            });
    };


    // hideApprovalModal = (e: any) => {
    //     // if (e.target.className !== "disable-page-controls") {
    //     //     return;
    //     // }
    //     if (this.state.changeValue) {
    //         this.isValidateModelPoupTab();
    //     }
    // }

    hideApprovalModal = (value) => {

        if(this.props.parentState.PageType==="locationWorkOrder" && this.state.changeValue){
            if(value === "closebtnclick"){
                this.props.slideOutClosewithDiscardChanges(true);
                this.isValidateModelPoupTab(value);
            }
            if(value === "sideClick"){
            this.props.closeworkorderSlideout(true);
            this.isValidateModelPoupTab(value);
            }
            if(value === "sideClick" && this.state.deleteFile === "Yes"){
                this.props.slideOutClosewithDiscardChanges(false);
            }
           
    }
    if(this.props.parentState.PageType==="locationWorkOrder" && this.state.changeValue === false){
        if(value === "closebtnclick"){
            this.props.closeLocationSlideOut();
        }
        if(value === "sideClick"){
            if(this.state.deleteFile === "No"){
            this.props.closeworkorderSlideout(false);
            }
            if(this.state.deleteFile === "Yes"){
            this.props.slideOutClosewithDiscardChanges(true);
            this.setState({deleteFile : "No"});
            }
        }
    }
}

    routeLeavingGuardConfirm = (childState: any) => {
        if (childState === true) {
            routeLeavingGuard = "routeLeavingGuardTrue";
        } else {
            routeLeavingGuard = null;
        }
    };



    isValidateModelPoupTab(value) {
        confirmAlert({
            title: "Unsaved confirmation",
            message:
                "You have unsaved changes on this page. Do you want to leave this page and discard your changes?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => this.isChangeUnsaved(value),
                },
                {
                    label: "No",
                    onClick: () => reject(),
                },
            ],
            closeOnEscape: false,
            closeOnClickOutside: false,
        });
    }

    // isChangeUnsaved = () => {
    //     this.setState({ changeValue: false }, () => {          
    //         this.disccardChanges();
    //     });
    // }

    isChangeUnsaved = (value) => {
        if(value === "sideClick"){
        this.setState({ changeValue: false }, () => {  
            this.props.closeworkorderSlideout(false)        
            this.disccardChanges();
        });
    }
    else{
        this.setState({ changeValue: false }, () => {  
           this.props.closeLocationSlideOut();    
            this.disccardChanges();
        });
    }
    }
    
    disccardChanges = () => {
        if(this.props.parentState.PageType==="locationWorkOrder" && this.state.changeValue === true){
            // this.props.refeshTable(this.state.parentRowData.workOrderID,"saved")
            toast.success("Changes discarded successfully")
        }
        debugger;
        this.setState({
            selectedDueDate: this.state.parentRowData?.dueDate, isRefresh: true, changeValue: false,
            description: this.state.parentRowData?.description,
            assetId : this.state.parentRowData?.assetID,
            assetList : this.props.parentState.assetTypesList.filter(asset => asset.locationID === this.props.parentRowData.locationID),
        }, () => {
            this.setState({ isRefresh: false });
            // let data = [...this.state.newSelectfiles]
            // let filterData = data.filter((item) => item.etype === "newfile");
            // for (let i = 0; i < filterData.length; i++) {

            //     let index = data.indexOf(filterData[i]);
            //     data.splice(index, 1);
            // }
           

            // this.setState({ newSelectfiles: data });
            if(this.props.parentState.PageType==="workOrderDetails"){ 
            this.props.changesOfSlideOut(false);
            }
            this.validationOnClick(0);
            
        })
        // this.props.changesOfSlideOut(false);
        // this.setState({ changeValue: false },()=>{
        // this.props.changesOfSlideOut(false);
        //  });
    }

    descriptionChange = (e: any) => {

        let value = e.target.value;
        this.setState({ changeValue: true, description: value }, () => {
            if(this.props.parentState.PageType==="workOrderDetails"){ 
            this.props.changesOfSlideOut(true);
            }
        });

        // e.target.style.height = 'auto';
        // e.target.style.height = e.target.scrollHeight + 'px';
    }

    validateConfigurationFormSchema = (isAssetNameChanged = false) => {

        // const valid = Joi.validate(
        //   this.state.description,
        //   this.FormSchema,
        //   {
        //     abortEarly: false,
        //   }
        // );

        // const newErrorObject: any = {};
        // if (valid.error) {
        //   valid.error.details.forEach((err) => {
        //     newErrorObject[err.path.join(".")] = err.message;
        //   });
        // }

        // if (this.state.errors?.title === 'Asset Name already in use. Please provide a different name.' && isAssetNameChanged) {
        //     newErrorObject["title"] = 'Asset Name already in use. Please provide a different name.';
        // }


        // if (Object.keys(newErrorObject).length === 0) {
        //   this.setState({ isFormNotValid: false });
        // } else {
        //   this.setState({ isFormNotValid: true});
        // }
        // this.setState({ errors: newErrorObject });
        // return newErrorObject;
    };

    validationOnClick = (inputPosition) => {

        if (this.state.description === "") {
            this.setState({ errors: true });
        } else {
            this.setState({ errors: false });
        }

        // this.setState({ inputPosition }, () =>
        //   this.validateConfigurationFormSchema()
        // );

    };

    scrollToBottom = () => {
        // this.parentScroll.current.scrollTo({
        //     //top: this.parentScroll.current.clientHeight,
        //     top: this.parentScroll.current.scrollHeight,
        //     behavior: 'smooth',
        // });
    }

    updatedCommentItem = () => {
        let updatedItem: any = {};
        this.setState({ isSave: true });
        updatedItem.transcationDate = new Date();
        updatedItem.vendorName = "";
        updatedItem.amountTotal = 0;
        return updatedItem;
    }

    descriptionBox = () => {

    }

    render() {
        


        const files1 = this.state.savedfiles.map((file, index) => (
            <>

                <li key={file.filename}>
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="icon">
                            {(file?.type)?.split('/')[0] === 'image' && (
                                <FontAwesomeIcon icon={faFileImage} />
                            )}
                            {(file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) === 'pdf' && (
                                <FontAwesomeIcon icon={faFilePdf} />
                            )}
                            {(file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) === 'xlsx' && (
                                <FontAwesomeIcon icon={faFileExcel} />
                            )}
                            {(file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) === 'csv' && (
                                <FontAwesomeIcon icon={faFileCsv} />
                            )}
                            {(file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) === 'word' && (
                                <FontAwesomeIcon icon={faFileWord} />
                            )}
                            {(((file?.type)?.split('/')[0] !== 'image') &&
                                ((file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) !== 'pdf') &&
                                ((file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) !== 'xlsx')
                                && ((file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) !== 'csv')
                                && ((file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length)) !== 'word'))
                                && (
                                    <FontAwesomeIcon icon={faFile} />
                                )}
                        </div>
                        <div className="file-info d-flex align-items-center">
                            <div className="name-loader d-flex align-items-center">
                                <div className="name">
                                    <EllipsisWithTooltip placement="bottom">
                                        <div className="full-name" >{file.filename}</div>
                                    </EllipsisWithTooltip>
                                    {/* <div className="details">{file.createDate} - {file.pageno} Page</div> */}
                                </div>
                            </div>
                            <div className="formate-percentage">
                                <span className="formate d-flex justify-content-center align-items-center">{(file?.filename.substring(file?.filename.lastIndexOf(".") + 1, file?.filename.length))}</span>
                            </div>
                        </div>
                        <div className="action">

                            {/* {!this.state.bulkSelect && ( */}
                            <Dropdown className="more-action" alignRight onSelect={(event: any) => this.handleSelectFileUpload(event, file, index)}>
                                <Dropdown.Toggle className="btn-outline-primary btn btn-primary more" id="dropdown-more">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z" />
                                        <path d="M5.625 10C5.625 11.0352 4.78516 11.875 3.75 11.875C2.71484 11.875 1.875 11.0352 1.875 10C1.875 8.96484 2.71484 8.125 3.75 8.125C4.78516 8.125 5.625 8.96484 5.625 10Z" />
                                        <path d="M18.125 10C18.125 11.0352 17.2852 11.875 16.25 11.875C15.2148 11.875 14.375 11.0352 14.375 10C14.375 8.96484 15.2148 8.125 16.25 8.125C17.2852 8.125 18.125 8.96484 18.125 10Z" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {this.state.moreActionFileUpload1.map(
                                        (item: any, idx: any) => (
                                            <>

                                                <Dropdown.Item
                                                    // className={}

                                                    eventKey={this.state.moreActionFileUpload1[idx].eventKey}
                                                    key={idx}
                                                    disabled={this.state.moreActionFileUpload1[idx].name === "Delete" && this.props.parentState.workorderPermission === "No"}
                                                >
                                                    {this.state.moreActionFileUpload1[idx].name}
                                                </Dropdown.Item>


                                            </>

                                        )
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* )} */}


                        </div>
                    </div>
                </li>
            </>
        ));

        const files = this.state.newSelectfiles.map((file, index) => (


            <>
                <li key={file.name}>
                    <div className="d-flex align-items-center justify-content-start">
                        <div className="icon">
                            {(file?.type)?.split('/')[0] === 'image' && (
                                <FontAwesomeIcon icon={faFileImage} />
                            )}
                            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'pdf' && (
                                <FontAwesomeIcon icon={faFilePdf} />
                            )}
                            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'xlsx' && (
                                <FontAwesomeIcon icon={faFileExcel} />
                            )}
                            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'csv' && (
                                <FontAwesomeIcon icon={faFileCsv} />
                            )}
                            {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) === 'word' && (
                                <FontAwesomeIcon icon={faFileWord} />
                            )}
                            {(((file?.type)?.split('/')[0] !== 'image') &&
                                ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'pdf') &&
                                ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'xlsx')
                                && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'csv')
                                && ((file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length)) !== 'word'))
                                && (
                                    <FontAwesomeIcon icon={faFile} />
                                )}
                        </div>
                        <div className="file-info d-flex align-items-center">
                            <div className="name-loader d-flex align-items-center">
                                <div className="name">
                                    <EllipsisWithTooltip placement="bottom">
                                        {/* <div className="full-name" onClick={() => { this.handlePreview(file) }}>{file.name}</div> */}
                                        <div className="full-name">{file.name}</div>
                                    </EllipsisWithTooltip>
                                </div>
                            </div>
                            <div className="formate-percentage">
                                <span className="formate d-flex justify-content-center align-items-center">
                                    {(file?.name.substring(file?.name.lastIndexOf(".") + 1, file?.name.length))}
                                </span>
                            </div>
                        </div>
                        <div className="action">
                            {/* <button className="btn-outline-primary remove-pdf more btn btn-primary" onClick={() => { this.handleSelectFileUpload("TempDelete", file, 0) }} >
                                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.192 6.34399L11.949 10.586L7.70697 6.34399L6.29297 7.75799L10.535 12L6.29297 16.242L7.70697 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z"></path></svg>
                               
                            </button> */}
                            
                              {this.state.isSpinner &&(
                                 <button className="btn-outline-primary remove-pdf more btn btn-primary">
                                    <Spinner
                                    className="mr-2"
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  </button> 
                                )}
                               

                        </div>
                    </div>
                </li>
            </>
        ));

        const attachmentheader = (column: any, colIndex: any) => {
            return (
                <div className="paperClipBtn">
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M43.246 466.142c-58.43-60.289-57.341-157.511 1.386-217.581L254.392 34c44.316-45.332 116.351-45.336 160.671 0 43.89 44.894 43.943 117.329 0 162.276L232.214 383.128c-29.855 30.537-78.633 30.111-107.982-.998-28.275-29.97-27.368-77.473 1.452-106.953l143.743-146.835c6.182-6.314 16.312-6.422 22.626-.241l22.861 22.379c6.315 6.182 6.422 16.312.241 22.626L171.427 319.927c-4.932 5.045-5.236 13.428-.648 18.292 4.372 4.634 11.245 4.711 15.688.165l182.849-186.851c19.613-20.062 19.613-52.725-.011-72.798-19.189-19.627-49.957-19.637-69.154 0L90.39 293.295c-34.763 35.56-35.299 93.12-1.191 128.313 34.01 35.093 88.985 35.137 123.058.286l172.06-175.999c6.177-6.319 16.307-6.433 22.626-.256l22.877 22.364c6.319 6.177 6.434 16.307.256 22.626l-172.06 175.998c-59.576 60.938-155.943 60.216-214.77-.485z"></path></svg>
                </div>
            );
        }

        const {name}=this.state

        return (
            <>
                <RouteLeavingGuard
                    when={this.state.changeValue}
                    routeLeavingGuardConfirm={this.routeLeavingGuardConfirm}
                    shouldBlockNavigation={(location: { pathname: any }) => {

                        if (this.state.changeValue) {
                            if (location.pathname) {

                                return true;

                            }
                        }
                        return false;
                    }}
                />


                <OutsideClickHandler onOutsideClick={(e: any) => this.hideApprovalModal("sideClick")}
                >
                    <div className="slideout-UI">
                        <div className="wrapper">
                            <div className="slideout-header edit-input-style" ref={this.parentScroll}>
                                {this.props.parentState.PageType==="workOrderDetails" ? 
                                // <EllipsisWithTooltip><h3 className="heading">{this.state.parentRowData.name}</h3></EllipsisWithTooltip>
                                <div className="pos-ttl mr-auto">
                                    <OverlayTrigger
                                        placement="bottom"
                                        defaultShow={false}
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                        <Tooltip id={"Asset-Name"} className={name?.length <= 75 ? "tootltip-hide" : ""}>
                                            {name}
                                        </Tooltip>
                                        }
                                    >
                                        <AutosizeInput
                                        ref={this.input}
                                        className={`form-control removeBorder input-component ${this.state.bdrValue ? this.state.nameError ?  "bdr-green" :"bdr-green":""}`}
                                        style={{ fontSize: 24 }}
                                        injectStyles={false}
                                        type="input"
                                        value={name}
                                        tabIndex={1}
                                        onClick={() => this.handleClick()}
                                        onChange={(e: any) =>{
                                            this.onFieldChange(
                                                Utils.BuildCustomEventObject(
                                                "name",
                                                e.target.value
                                                ),
                                                1
                                            )
                                            }
                                        }
                                        onBlur={() => this.handleBlurAutoSize()}
                                        placeholder={"Enter Work Order Name"}
                                        maxLength={100}
                                        />
                                    </OverlayTrigger>
                         

                                 </div>
                                :
                                <div className="user-details align-items-center mr-auto">
                                <div className="location-title">{this.state.parentRowData.title}</div>
                                <div className="position-name">Work Order</div>
                            </div> 
                                }
                                
                                {this.props.parentState.PageType==="workOrderDetails" ? 
                                <Button onClick={this.closeSlideOut}><FontAwesomeIcon icon={faClose} /></Button> :
                                <Button onClick={() => this.hideApprovalModal("closebtnclick")}><FontAwesomeIcon icon={faClose} /></Button> }
                            </div>
                            <div className={`slideout-body ${this.state.changeValue && "bottom-spacer"} `} ref={this.slideoutRef}>
                                <h3 className="label">Status</h3>
                                <div className="buttons-wrapper">
                                    <div className="status-list">
                                        <button ref={this.btnOpen} onClick={() => this.updateWorkOrderStatus("Open")} className={this.props.parentState.PageType==="workOrderDetails" ? this.state.parentRowData.status === "Open" ? "active" : "" : this.state.tempLocationSlideOutStatus  === "Open" ? "active" :""} disabled={this.props.parentState.workorderPermission === "No"}>
                                            <div className="circle"></div>
                                            <span>Open</span>
                                        </button>
                                        <button ref={this.btnInprogress} onClick={() => this.updateWorkOrderStatus("In Progress")} className={this.props.parentState.PageType==="workOrderDetails" ? this.state.parentRowData.status === "In Progress" ? "active" : "":this.state.tempLocationSlideOutStatus === "In Progress" ? "active" : ""} disabled={this.props.parentState.workorderPermission === "No"}>
                                            <FontAwesomeIcon icon={faPlay} />
                                            <span>In Progress</span>
                                        </button>
                                        <button ref={this.btnOnhold} onClick={() => this.updateWorkOrderStatus("On Hold")} className={this.props.parentState.PageType==="workOrderDetails" ? this.state.parentRowData.status === "On Hold" ? "active" : "":this.state.tempLocationSlideOutStatus === "On Hold" ? "active":""} disabled={this.props.parentState.workorderPermission === "No"}>
                                            <FontAwesomeIcon icon={faPause} />
                                            <span>On Hold</span>
                                        </button>
                                        <button ref={this.btnComplet} onClick={() => this.updateWorkOrderStatus("Completed")} className={this.props.parentState.PageType==="workOrderDetails" ? this.state.parentRowData.status === "Completed" ? "active" : "" :this.state.tempLocationSlideOutStatus === "Completed" ? "active":""} disabled={this.props.parentState.workorderPermission === "No"}>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <span>Complete</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="order-info">
                                    <Form.Group controlId="location" className="location-dropdown">
                                        <Form.Label>Location</Form.Label>
                                        {this.state.locationList.length > 0 && (

                                            <SingleSearchDropdownList
                                               // // isdisable={this.props.locationDisable}
                                                isRefresh={this.state.isRefresh}
                                                itemList={this.state.locationList}
                                                handleSelectedItem={this.handleSelectedItem.bind(
                                                    this,
                                                    4
                                                )}
                                                defaultItem={this.state.locationList}
                                                defaultText={"Location"}
                                                defaultName={this.state.locationList.filter((item) => item.id === this.state.parentRowData.locationID)[0]?.name}
                                                controlID="4"
                                                isdisable={this.props.parentState.PageType==="locationWorkOrder" || this.props.parentState.workorderPermission === "No" ? true : false}
                                            />
                                        )}
                                    </Form.Group>
                                    <Form.Group controlId="asset">
                                        <Form.Label>Asset</Form.Label>

                                        {this.state.assetList.length > 0 && (

                                            <SingleSearchDropdownList
                                                isdisable={this.props.assetDisable ||this.props.parentState.workorderPermission === "No"}
                                                isRefresh={this.state.isRefresh}
                                                itemList={this.state.assetList}
                                                handleSelectedItem={this.handleSelectedItem.bind(
                                                    this,
                                                    3
                                                )}
                                                defaultItem={this.state.assetList}
                                                defaultText={"Search Asset"}
                                                defaultName={(this.state.assetList.filter((item) => item.id === this.state.parentRowData.assetID))[0]?.name === undefined ? "Select an asset" :
                                                    (this.state.assetList.filter((item) => item.id === this.state.parentRowData.assetID))[0]?.name}

                                                controlID="3"
                                            />
                                        )}


                                    </Form.Group>
                                    <Form.Group controlId="priority" className="priority-dropdown">
                                        <Form.Label>Priority</Form.Label>
                                        <div className="priority-input">
                                            {/* <Form.Control type="text" value="Medium" />
                                    <div className="priority Medium"></div>  */}

                                            {this.state.priorityList.length > 0 && (
                                                <SingleSearchDropdownList
                                                    id={"tabletypeddl"}
                                                    isRefresh={this.state.isRefresh}
                                                    itemList={this.state.priorityList}
                                                    handleSelectedItem={this.handleSelectedItem.bind(
                                                        this,
                                                        2
                                                    )}
                                                    allowSearch={false}
                                                    defaultItem={this.state.priorityList}
                                                    defaultText={"Search Status"}
                                                    defaultName={this.state.parentRowData.priorityType}
                                                    controlID="2" 
                                                    isShowPip={true}
                                                    isdisable = {this.props.parentState.workorderPermission === "No"}
                                                />
                                            )}


                                        </div>
                                    </Form.Group>
                                    <Form.Group controlId="dueDate">
                                        <Form.Label>Due Date</Form.Label>
                                        <DatePicker
                                            value={this.state.selectedDueDate ? new Date(this.state.selectedDueDate) : null}
                                            name="startDate"
                                            //className={`form-control scheduleInput ${row.isDuplicateEntry ? "alert-danger" : ''}`}
                                            id="shiftStartDate"
                                            selected={this.state.selectedDueDate ? new Date(this.state.selectedDueDate) : null}
                                            onSelect={(e: any) => this.handleSelectDate(e, "startDate", "")}
                                            onChange={(e: any) => this.handleSelectDate(e, "startDate", "onChange")}
                                            autoComplete="off"
                                            placeholderText="Set due date"
                                            disabled={this.props.parentState.workorderPermission === "No"}

                                        />
                                    </Form.Group>
                                    <Form.Group controlId="assignedTo">
                                        <Form.Label>Assigned To</Form.Label>

                                        {this.props.parentState.assignToList.length > 0 && (

                                            <SingleSearchDropdownList
                                                isRefresh={this.state.isRefresh}
                                                itemList={this.state.assignToList}
                                                handleSelectedItem={this.handleSelectedItem.bind(
                                                    this,
                                                    1
                                                )}
                                                defaultItem={this.state.assignToList}
                                                defaultText={"Assigned To"}
                                                // defaultName={this.state.assignToList.filter((item) => item.id === this.state.parentRowData.user_UniqueID)[0]?.name}
                                                defaultName={(this.state.assignToList.filter((item) => item.id === this.state.parentRowData.user_UniqueID))[0]?.name === undefined ? "Select a technician" :
                                                    (this.state.assignToList.filter((item) => item.id === this.state.parentRowData.user_UniqueID))[0]?.name}

                                                controlID="1"
                                                isdisable={this.props.parentState.workorderPermission === "No"}
                                            />
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="py-4">
                                    <div className="description-box">
                                        <h5 className="label">Description <b>*</b></h5>
                                        <Form.Group onBlur={() => this.validationOnClick(5)} controlId="description" className="d-flex">

                                            <Col
                                                sm={"12"}
                                                className={`mb-0 p-0 ${this.state.errors ? "validation-error" : ""
                                                    }`}
                                                onClick={() => this.validationOnClick(5)}
                                            >
                                                <Form.Control
                                                    as="textarea"
                                                    type="textarea"
                                                    className="form-control"
                                                    //maxLength={250}
                                                    id="description"
                                                    name="description"
                                                    autoComplete="description"
                                                    value={this.state.description}
                                                    placeholder="Give as much detail as possible about the problem, location and anything else relevant"
                                                    onChange={(e) => this.descriptionChange(e)}
                                                    disabled={this.props.parentState.workorderPermission === "No"}
                                                 
                                                //  onBlur={()=>{this.setState({description:{...this.state.description}})}}
                                                // onBlur={(e) => this.descriptionChange(e)}
                                                />
                                            </Col>
                                        </Form.Group>

                                    </div>
                                    <div className="mb-3">
                                        <h5 className="label mb-1">Submitter</h5>
                                        <h6 className="body-font">{this.state.parentRowData?.createdBy}</h6>
                                    </div>
                                </div>

                                <div className={`filter-sec upload-section ${this.props.parentState.workorderPermission === "No" ? "disabled" : ""}`}>


                                    <Form.Group controlId="exampleForm.ControlTextarea1" >
                                        <div className="d-flex">
                                            <Form.Label className="mr-auto label">Attachments</Form.Label>
                                            {this.state.savedfiles.length > 0 && (
                                                <div className="upload-link">
                                                    {/* <button type="button" className="btn wht-bg link-btn mr-auto" onClick={this.OpenViewer}>
                                                                {this.state.isOpenViewer ? 'Close Viewer' : 'Open Viewer'}
                                                            </button>  */}
                                                </div>
                                            )}
                                        </div>
                                        <div>

                                            <Dropzone
                                                // disabled={isBedgeProcessing}
                                                disabled={this.props.parentState.workorderPermission === "No"}
                                                onDrop={this.onDrop}
                                            // accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml"
                                            >
                                                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                                                    <>
                                                        <section className={isDragActive ? "container upload-area default drag-active " : "container upload-area default"}>

                                                            {!this.state.bulkSelect && (

                                                                <div {...getRootProps({
                                                                    className: 'dropzone d-flex align-items-center',
                                                                    onClick: (this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) ?
                                                                        event => event.stopPropagation() : event => event
                                                                })}>
                                                                    <input {...getInputProps()} />
                                                                    {(this.state.newSelectfiles.length < 1 && this.state.savedfiles.length < 1) && (
                                                                        <p>Drag and drop files or <span>browse files</span> to upload.</p>
                                                                    )}
                                                                    {(this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) && isDragActive && (
                                                                        <p>Drag and drop files here to upload.</p>
                                                                    )}
                                                                    {/* {!isDragActive && (<p>Drag and drop or <span>browse files</span> to upload</p>)} */}

                                                                </div>

                                                            )}

                                                            {/* {this.state.bulkSelect && ( 
                                                                        <div className="dropzone d-flex align-items-center">
                                                                            <p>Drag and drop or <span>browse files</span> to upload</p>
                                                                        </div>
                                                                   )}   */}
                                                            {(this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) && (
                                                                <aside className="d-flex flex-column">
                                                                    <ul className="p-0">{files1}</ul>
                                                                    <ul className="p-0">{files}</ul>
                                                                </aside>
                                                            )}
                                                        </section>
                                                        {(!this.state.bulkSelect && (this.state.newSelectfiles.length > 0 || this.state.savedfiles.length > 0) && this.props.parentState.workorderPermission === "Yes") && (
                                                            <div className="upload-link mrgn-top">
                                                                <button type="button" className="btn p-0 wht-bg link-btn mr-auto" >Add Attachment</button>
                                                                <Form.Control id="formControlsFile" type="file" multiple onChange={(event: any) => this.onFileChange(event, "1")} disabled={this.props.parentState.workorderPermission === "No"}
                                                                    accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,.bmp,.tif,.ppt,.pptx,.rtf,.jpg,.png,.msg,.eml" />

                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </Form.Group>


                                </div>


                                <div className="comments">
                                    <Tabs defaultActiveKey="Comments">
                                        <Tab eventKey="Comments" title="Comments">
                                            {/* <div className="add-comment">
                                                <img src={profile} className="user" alt="user" />
                                                <Form.Control type="text" placeholder="Write a comment..." />
                                            </div> */}
                                            {/* <div className={this.props.parentState.workorderPermission === "No" ? "comment-disabled" : ""}> */}
                                            <div>

                                                <AddCommentBox
                                                    ref={this.ChildComment}
                                                    hotelid={this.state.parentRowData?.hid}
                                                    workOrderId={Number(this.state.parentRowData?.workOrderID)}
                                                    entryType={this.props.pageType}
                                                    //  uniqueNo={this.state.formData.actionType === "copy" ? 0 : this.props.pageType === "Invoice" ? this.state.formData.uniqueno : this.state.formData.incompleteUniqueno}
                                                    precommentlist={this.state.htmlcomment}
                                                    parentScrollMethod={this.scrollToBottom}
                                                    updatedCommentItem={this.updatedCommentItem}
                                                    parentState={this.state}
                                                />

                                            </div>

                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>



                            {this.props.parentState.workorderPermission === "Yes" && (
                                <div>
                                    {
                                        this.state.changeValue && <div className="fixed-action px-3 pb-2 h-auto">
                                            <div className="d-flex align-items-center flex-wrap forecast-action-strip">

                                                <div className="mr-auto message">
                                                    <span>You have made changes to the current work order. <br /> Would you like to save and continue?</span>
                                                </div>

                                                <button
                                                    type="button"
                                                    className="btn btn-primary border-0 btn-discard btn-changes-discard"
                                                    onClick={this.disccardChanges}
                                                >
                                                    Discard
                                                </button>


                                                <button
                                                    ref={this.btnSave}
                                                    type="button"
                                                    className="save-btn btn btn-primary mr-0"
                                                    onClick={this.handleSave}
                                                >
                                                    Save & Continue
                                                </button>

                                            </div>
                                        </div>
                                    }
                                </div>
                            )}

                        </div>
                    </div>
                </OutsideClickHandler>
            </>
        );
    }

}


